import React, {useEffect, useState} from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import {useIsAuthenticated} from '@azure/msal-react';
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react"
import { InteractionType } from "@azure/msal-browser";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ClipLoader from "react-spinners/ClipLoader";


const Support = () => {
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [email, setEmail] = useState('')
    const [subject, setSubject] = useState('')
    const [category, setCategory] = useState('Sales')
    const [showMsg, setShowMsg] = useState(false)
    const [msg, setMsg] = useState('')
    

    useEffect(() => { 
        
        if(isAuthenticated){
            const activeAccount = instance.getActiveAccount();
            const names = activeAccount.name.split(' ');
            console.log(names)
            setFirstname(names[0])
            setLastname(names[1])
            setEmail(activeAccount.username)
        }

    }, [instance, isAuthenticated])

    
    const authRequest = {
        scopes: ["openid", "profile"]
    };

    const handleSubject = (event) => {
        setSubject(event.target.value)
        if (event.target.value.length < 5 ){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }
        else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
        }
    }

    const handleMessage = (event) => {
        setMessage(event.target.value)
        if (event.target.value.length < 10 ){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }
        else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        
        const subjectElem = document.querySelector('#subject');
        const msgElem = document.querySelector('#message');

        if(subjectElem.classList.contains('is-invalid') || subjectElem.value === ''){
            subjectElem.classList.remove('is-valid');
            subjectElem.classList.add('is-invalid');
            setLoading(false)
            return false;
        }

        if(msgElem.classList.contains('is-invalid') || msgElem.value === ''){
            msgElem.classList.remove('is-valid');
            msgElem.classList.add('is-invalid');
            setLoading(false)
            return false;
        }
        
        const payload = {
            "firstname" : firstname,
            "lastname" : lastname,
            "email" : email,
            "subject" : subject,
            "category" : category,
            "message" : message,
            "key_id" : process.env.REACT_APP_BASE_APIKEY
        };

        const endpoint = process.env.REACT_APP_BASE_URL + '/user/support/create';
        fetch(endpoint, {

            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            "method": "POST",
            "body": JSON.stringify(payload)
        })
        .then(response => response.json())
        .then(response => { 
            if(response.error === true){
                setLoading(false)
            }else{
               
                setShowMsg(true)
                setMsg(response.msg)
                setLoading(false)
                reset()
            }
           
        })
        .catch(err => {
            console.log(err);
            setLoading(false)
        });
        
    }

    const reset = () => {
        setFirstname('')
        setLastname('')
        setEmail('')
        setSubject('')
        setMessage('')
        setCategory('')
    }

  	return (
       
        <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={authRequest} 
        >
            
		    <Header />
            <div className="page-content container-fluid">
                <div className="row justify-content-center">
                   	<div className="spaceTopBottom max-width-720" style={{fontSize: '16px' }}>
						
                       <div className="col" style={{borderTopRightRadius:'50px', borderBottomRightRadius:'50px'}}>
                        <div style={{ padding: '40px 80px 80px'}}>
                            <div className="contact-title-form">
                                <div className="text-large text-light-blue2">
                                    CREATE SUPPORT TICKET 
                                </div>
                                <div className="title-large-2">
                                    <span className="font-weight-700">SEND</span> US A MESSAGE
                                </div>
                            </div>
                            <div>
                                <form>
                                {
                                    showMsg ? 
                                    <div className="alert alert-success" role="alert" style={{ fontSize:'13px' }}>{msg}</div>
                                    :''
                                }
                                    <div className="form-group mb-3">
                                        <select className="form-select form-control" onChange={(e) => setCategory(e.target.value)}>
                                            <option value="">Category</option>
                                            <option value="Sales" selected>Sales</option>
                                            <option value="Support">Support</option>
                                            <option value="General">General</option>
                                        </select>
                                    </div>
                                   
                                    <div className="mb-3 form-group">
                                        <label htmlFor="email" className="form-label text-small">Subject</label>
                                        <input type="text" className="form-control" id="subject" value={subject} onChange={(e) => handleSubject(e)}/>
                                        <div className="invalid-feedback">Please provide with a valid subject </div>
                                        <div className="valid-feedback"></div>
                                    </div>
                                   
                                    <div className="mb-3 form-group">
                                        <label htmlFor="message" className="form-label text-small">How can we help?</label>
                                        <textarea type="text" className="form-control" id="message" value={message} rows="5" onChange={(e) => handleMessage(e)}></textarea>
                                        <div className="invalid-feedback">Please provide with a valid message </div>
                                        <div className="valid-feedback"></div>
                                    </div>
                                                                       
                                    <button type="submit" className="contact-submit-btn" onClick={(e) => handleSubmit(e)}>
                                        <ClipLoader
                                        color="fff"
                                        loading={loading}
                                        size={10}
                                        aria-label="Loading Spinner"
                                        data-testid="loader"
						                />Send</button>
                                </form>
                            </div>
                        </div>
                	</div>
					</div>
                </div>
            </div> 
            <Footer />
        </MsalAuthenticationTemplate>
    );
}

export default Support;
