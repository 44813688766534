import React, {useEffect} from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header';
import Texts from '../components/Texts';

const Faq = () => {

    useEffect(() => {
        window.scrollTo({
            top:0,
            behavior: 'instant'
        });       
    },[]);

    const handleClick = (e) => {
		if(e.target.parentNode.parentNode.classList.contains('selected'))
			e.target.parentNode.parentNode.classList.remove('selected')
		else
			e.target.parentNode.parentNode.classList.add('selected')
	}
    
    return (
    <div className="App">
		<Header />
		<div className="bodyContainer">
        <section className="containerfull faqs" id="help">
				<div className="contentfull">
					<div className="text">
						<div className="headline">RESOURCES</div>
						<div className="title">
							Frequently<span className="font-weight-700">Asked</span> Questions
						</div>
					</div> 
					<div className="faqs">
						<div className="subtitle">PRODUCT</div>
						{
							Texts.faqs.product.map((item, i) => 
								<div key={i} className="faq">
									<div className="question">
										<div className="number" onClick={handleClick}>{i+1}</div>
										<div className="text" onClick={handleClick}>{item.question}</div>
										<div className="icon" onClick={handleClick}></div>
									</div>
									{
										typeof item.list !== "undefined" ? 
										<div className="answer">
											<ol>
												{
												item.list.map((elem, i) => <li>{parseInt(i)+1 + '.  ' + elem}</li>)
												}
											</ol>
										</div>
										:
										<div className="answer">{item.answer}</div>
									}
							</div>
							)
						}
					</div>
					<div className="faqs">
					<div className="subtitle">BILLING</div>
						{
							Texts.faqs.billing.map((item, i) => 
								<div key={i} className="faq">
									<div className="question">
										<div className="number">{i+1}</div>
										<div className="text" onClick={handleClick}>{item.question}</div>
										<div className="icon" onClick={handleClick}></div>
									</div>
									<div className="answer">{item.answer}</div>
								</div>
							)
						}
					</div>
				</div>
			</section>
        </div>
        <Footer/>
    </div>
  )
}

export default Faq;