import React, { createContext } from 'react'
import { useState } from 'react';

export const Context = createContext(null);


export const ContextProvider = (props) => {
    const [isLogged, setIsLogged] = useState(false)
    const [email, setEmail] = useState(null)
    const [user, setUser] = useState(null)
    const [uid, setUid] = useState(null)
    const [productId, setProductId] = useState(null)
    const [productName, setProductName] = useState(null)

    //Login popup
    const [loginSigninDialog, setLoginSigninDialog] = useState(false)
    const [loginDialog, setLoginDialog] = useState(false)
    const [signinDialog, setSigninDialog] = useState(false)
    const [forgotPasswordDialog, setForgotPasswordDialog] = useState(false)
    const [redirectAfterLogin, setRedirectAfterLogin] = useState(false)
    const [redirectAfterLoginLink, setRedirectAfterLoginLink] = useState('')
    
    const contextValue = {isLogged, setIsLogged, user, setUser, email, setEmail, uid, setUid, productId, setProductId, productName, setProductName, loginSigninDialog, setLoginSigninDialog, loginDialog, setLoginDialog, signinDialog, setSigninDialog, forgotPasswordDialog, setForgotPasswordDialog, redirectAfterLogin, setRedirectAfterLogin, redirectAfterLoginLink, setRedirectAfterLoginLink}

    return (
        <Context.Provider value={contextValue}>{props.children}</Context.Provider>
    )
}
