import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ChangePassword = () => {
    const { key } = useParams();
    const [password, setPassword] = useState('')
    const [retypePassword, setRetypePassword] = useState('')
    const [showForm, setShowForm] = useState(false)
  
    useEffect(() => {
        window.scrollTo(0, 0)
        const url = process.env.REACT_APP_BASE_URL + '/user/validate-password-link';
        fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method : "POST",
            body : JSON.stringify({link : key, key_id : process.env.REACT_APP_BASE_APIKEY})
        })
        .then(response => response.json())
        .then(response => {
            if(response.action === "failed"){
                window.location.replace("/invalid-password-link");
                return false;
            }else{
                setShowForm(true)
            }
        })
        .catch(err => {
            console.log(err);
        });
    }, [key])
    
    
    const handlePassword = (event) => {
        event.preventDefault();
        setPassword(event.target.value);
        if (event.target.value.length < 5 ){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }
        else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
        }
    }

    const handleRetypePassword = (event) => {
        setRetypePassword(event.target.value);
        if (event.target.value.length < 5 || event.target.value !== password){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }
        else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
        }
    }

    const reset = () => {
        setPassword('');
        setRetypePassword('');
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        
        const url = process.env.REACT_APP_BASE_URL + '/user/change-password';
        fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method : "POST",
            body : JSON.stringify({key : key, password : password, retypePassword : retypePassword, key_id : process.env.REACT_APP_BASE_APIKEY})
        })
        .then(response => response.json())
        .then(response => {
            if(response.action === "failed"){  
                toast.error(response.msg, {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored"
                });
                return false;
            }else{
                reset()
                toast.success(response.msg, {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored"
                });
                setTimeout(() => {
                    window.location.replace("/login");
                }, 4000);
            }
        })
        .catch(err => {
            console.log(err);
        });
    }

    return (
        
        <div>
            {showForm ?
            <>
            <ToastContainer />
            <Header />
            <div className="page-content container-fluid">
                <div className="row justify-content-center">
                    
                    <div className="spaceTopBottom max-width-720">
                        <h1 style={{fontSize : '25px'}}>Create your new password</h1>
                        <br/><br/>
                        <div className="form-floating mb-3" >
                            <input type="password" className="form-control border border-success" placeholder="Password" value={password} onChange={handlePassword}/>
                            <div className="invalid-feedback">Please provide with a valid password (Min 5 characters)</div>
                            <div className="valid-feedback"></div>
                            <label>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-lock feather-sm text-success fill-white me-2">
                                    <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
                                    <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                                </svg>
                                <span className="border-start border-success ps-3">New Password</span>
                            </label>
                            
                        </div>
                        <div className="form-floating mb-3">
                            <input type="password" className="form-control border border-success" placeholder="CPassword" value={retypePassword} onChange={handleRetypePassword}/>
                            <div className="invalid-feedback">Passwords did not match</div>
                            <div className="valid-feedback"></div>
                            <label>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-lock feather-sm text-success fill-white me-2">
                                    <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
                                    <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                                </svg>
                                <span className="border-start border-success ps-3">Confirm Password</span>
                            </label>
                        </div>
                        <div className="d-md-flex align-items-center">
                            <div className="mt-3 mt-md-0 ms-auto">
                                <button type="submit" onClick={handleSubmit} className="
                                    btn btn-success
                                    font-weight-medium
                                    rounded-pill
                                    px-4
                                ">
                                <div className="d-flex align-items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-send feather-sm fill-white me-2"><line x1="22" y1="2" x2="11" y2="13"></line><polygon points="22 2 15 22 11 13 2 9 22 2"></polygon></svg>
                                    Submit
                                </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
            <Footer />
            </>
            : ''
            }
            </div>
        
    );
}

export default ChangePassword;
