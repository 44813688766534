import React  from 'react';
import { MsalProvider } from '@azure/msal-react';
//import { loginRequest } from './authConfig';
import Home from './Pages/Home';
//import Login from './Pages/Login';
//import Myaccount from './Pages/Myaccount';
import Signup from './Pages/Signup';
import ForgotPassword from './Pages/ForgotPassword';
import ChangePassword from './Pages/ChangePassword';
import InvalidPasswordLink from './Pages/InvalidPasswordLink'
import ProtectedRoutes from './components/ProtectedRoutes';
import MyaccountEditAddress from './Pages/MyaccountEditAddress';
import MyaccountEditPassword from './Pages/MyaccountEditPassword'
import ActivateAccount from './Pages/ActivateAccount';
import AddNewUser from './Pages/AddNewUser'
import ChoosePlan from './Pages/ChoosePlan';
import Success from './Pages/Success';
import Cancel from './Pages/Cancel';
import Contact from './Pages/Contact';
import Privacy from './Pages/Privacy';
import Terms from './Pages/Terms';
import Tutorials from './Pages/Tutorials';
import FAQ from './Pages/Faq';
//import Industries from './Pages/Industries'
import MyAccountProfile from './Pages/MyaccountProfile';
import MyAccountTeam from './Pages/MyaccountTeam';
import MyAccountPlans from './Pages/MyaccountPlans';
import MyAccountSubscription from './Pages/myaccountSubscription';
import MyAccountHistory from './Pages/MyaccountHistory';
import MyAccountSupport from './Pages/MyaccountSupport';
import Support from './Pages/Support'
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import { ContextProvider } from './components/Context';
import AcceptInvitation from './Pages/AcceptInvitation';

function App({instance}) {
	
    // const handleRedirect = () => {
    //     instance
    //         .loginRedirect({
    //             ...loginRequest,
    //             prompt: 'create',
    //         })
    //         .catch((error) => console.log(error));
    // };
	return (
		<MsalProvider instance={instance}>
			<ContextProvider>
			<Router>
				<Routes>
					<Route path = "/" element={<Home/>} /> 
					{/* <Route path = "/login" element={<Login/>} /> */}
					<Route path = "/signup" element={<Signup/>} />
					<Route path = "/forgot-password" element={<ForgotPassword/>} />
					<Route path = "/invalid-password-link" element={<InvalidPasswordLink/>} />
					<Route path = "/change-password/:key" element={<ChangePassword/>} />
					<Route path = "/activate-account/:activation_code" element={<ActivateAccount/>} />
					<Route path = "/contact" element={<Contact/>} />
					<Route path = "/privacy" element={<Privacy/>} />
					<Route path = "/terms" element={<Terms/>} />
					<Route path = "/tutorials" element={<Tutorials />} />
					<Route path = "/faq" element={<FAQ />} />
					<Route path = "/myaccount/profile" element={<MyAccountProfile/>} />
					<Route path = "/myaccount/team" element={<MyAccountTeam/>} />
					<Route path = "/myaccount/plans" element={<MyAccountPlans/>} />
					<Route path = "/myaccount/support" element={<MyAccountSupport/>} />
					<Route path = "/support" element={<Support/>} />
					<Route path = "/myaccount/subscription/:id" element={<MyAccountSubscription/>} />
					<Route path = "/accept-invitation/:uid/:product/:reference" element={<AcceptInvitation/>} />
					<Route path = "/payment/success/:accountId" element={<Success/>} />
					<Route element={<ProtectedRoutes/>}>
						<Route path = "/myaccount/history" element={<MyAccountHistory/>} />
						<Route path = "/myaccount/address/edit" element={<MyaccountEditAddress/>} />
						<Route path = "/myaccount/password/edit" element={<MyaccountEditPassword/>} />
						<Route path = "/myaccount/manage/user/add" element={<AddNewUser/>} />
						<Route path = "/plan/choose" element={<ChoosePlan/>} />
						<Route path = "/success" element={<Success/>} />
						<Route path = "/cancel" element={<Cancel/>} />
					</Route>
				</Routes>
			</Router>
			</ContextProvider>
		</MsalProvider>
	);
}

export default App;
