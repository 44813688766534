import React,{useEffect, useState} from 'react';
import {HashLink} from "react-router-hash-link"
import { useMsal } from "@azure/msal-react"

const Aside = ({selected}) => {
    const { instance } = useMsal();
    const [role, setRole] = useState('user')

    useEffect(() => {

        const activeAccount = instance.getActiveAccount();
        if(activeAccount !== null){
            const endpoint = process.env.REACT_APP_BASE_URL + '/customer/role';
            fetch(endpoint, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                "method": "POST",
                "body": JSON.stringify({email : activeAccount.username, key_id : process.env.REACT_APP_BASE_APIKEY})
            })
            .then(response => response.json())
            .then(response => setRole(response.role))
            .catch(err => {
                console.log(err);
            });
        }
    }, [role, instance])
    


    return (

    <div className="menu-aside">
        <div className="logo-aside"></div>
        {/* <div className="title">My Account</div> */}
        <div className="menus">
            {
            selected === 'profile' ? 

            <HashLink className="no-underline" to="/myaccount/profile" smooth>
            <div className="menu highlight" style={{ position:'relative' }}>
                <div style={{ position:'absolute', height : '100%', width:'2px', background:'#fff', left:'0' }}></div>
                <div style={{ position:'absolute', height : '100%', width:'2px', background:'#00659c', left:'2px' }}></div>
                <div className="icon user"></div>
                <div className="label">Profile</div>
            </div>
            </HashLink>
            :
            <HashLink className="no-underline" to="/myaccount/profile" smooth>
            <div className="menu">
                <div className="icon user"></div>
                <div className="label">Profile</div>
            </div>
            </HashLink>
            }
            <div className="menu-separator"></div>
           
            {
            role === 'admin' ?
                selected === 'team' ? 
                <>
                    <HashLink className="no-underline" to="/myaccount/team" smooth>
                    <div className="menu highlight" style={{ position:'relative' }}>
                        <div style={{ position:'absolute', height : '100%', width:'2px', background:'#fff', left:'0' }}></div>
                        <div style={{ position:'absolute', height : '100%', width:'2px', background:'#00659c', left:'2px' }}></div>
                        <div className="icon team"></div>
                        <div className="label">Team</div>
                    </div>
                    </HashLink>
                    <div className="menu-separator"></div>
                </>
                :
                <>
                    <HashLink className="no-underline" to="/myaccount/team" smooth>
                    <div className="menu">
                        <div className="icon team"></div>
                        <div className="label">Team</div>
                    </div>
                    </HashLink>
                    <div className="menu-separator"></div> 
                </>   
            : ''
            }
                
            {
            selected === 'plans' ? 
            <HashLink className="no-underline" to="/myaccount/plans" smooth>
            <div className="menu highlight" style={{ position:'relative' }}>
                <div style={{ position:'absolute', height : '100%', width:'2px', background:'#fff', left:'0' }}></div>
                <div style={{ position:'absolute', height : '100%', width:'2px', background:'#00659c', left:'2px' }}></div>
                <div className="icon plans"></div>
                <div className="label">Plans</div>
            </div>
            </HashLink>
            :
            <HashLink className="no-underline" to="/myaccount/plans" smooth>
            <div className="menu">
                <div className="icon plans"></div>
                <div className="label">Plans</div>
            </div>
            </HashLink>
            }
            <div className="menu-separator"></div>
            {
            selected === 'support' ? 
                <HashLink className="no-underline" to="/myaccount/support" smooth>
                <div className="menu highlight" style={{ position:'relative' }}>
                    <div style={{ position:'absolute', height : '100%', width:'2px', background:'#fff', left:'0' }}></div>
                    <div style={{ position:'absolute', height : '100%', width:'2px', background:'#00659c', left:'2px' }}></div>
                    <div className="icon support"></div>
                    <div className="label">Support</div>
                </div>
                </HashLink>
                :
                <HashLink className="no-underline" to="/myaccount/support" smooth>
                <div className="menu">
                    <div className="icon support"></div>
                    <div className="label">Support</div>
                </div>
                </HashLink>
            }
            <div className="menu-separator"></div>
        </div>
    </div>
  )
}

export default Aside