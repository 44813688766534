import React, {useEffect, useState} from 'react';
import { useIsAuthenticated } from "@azure/msal-react"
import 'react-toastify/dist/ReactToastify.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useParams } from 'react-router-dom';
import {useMsal } from "@azure/msal-react";
import ClipLoader from "react-spinners/ClipLoader";
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';

const Success = () => {
	const { accountId } = useParams();
	const { instance } = useMsal();
	const isAuthenticated = useIsAuthenticated();
	const [email, setEmail] = useState('');
	const [sessionId, setSessionId] = useState('');
	
	const [show, setShow] = useState(false);
	const [loading, setLoading] = useState(false);

    useEffect(() => {
		if(isAuthenticated){
			const activeAccount = instance.getActiveAccount();	
			setEmail(activeAccount.username)	
			const userInfo = localStorage.getItem('vxs_user_session');
			if(userInfo && userInfo !== "undefined"){
				const u = JSON.parse(userInfo)
				const sessionId = u.homeAccountId
				setSessionId(sessionId)
				setShow(true)							
			}else{
				console.log("No payment found")
			}
		}

	}, [accountId, isAuthenticated, instance])
    
	const activate = (e) => {
		e.preventDefault()
		if(sessionId === accountId){
			setLoading(true)
			setTimeout(() => {
				const endpoint = process.env.REACT_APP_BASE_URL + '/subscription/change/useremail';
				const data = {
					email:email,
					external_customer_reference : accountId,
					key_id : process.env.REACT_APP_BASE_APIKEY
				}
				fetch(endpoint, {
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					"method": "POST",
					"body": JSON.stringify(data)
				})
				.then(response => response.json())
				.then(data => { 
					setLoading(false) 
					if(data.error === false){
						localStorage.removeItem('vxs_user_session')
						console.log("Remove session")
						setShow(false)
						toast.success("Your account is successfully activated", {
							position: toast.POSITION.TOP_CENTER,
							theme: "colored"
						});
					}else{
						toast.error("We're experiencing technical difficulties. Please try again later or contact support for assistance.", {
							position: toast.POSITION.TOP_CENTER,
							theme: "colored"
						});
					}
				})
				.catch(err => {
					console.log(err);
					setLoading(false) 
				});	
			}, 5000);
			
		}
	}


  	return (
	
    <div>
		 <ToastContainer />
		<Header />
		<div className="page-content container-fluid">
			<div className="row justify-content-center">
				<div className="spaceTopBottom max-width-720" style={{fontSize: '16px' }}>
					<div>
						<div className="title-large-1 font-weight-600" style={{ "margin" : "40px 0"}}>Thank you for subscribing to VoxelSpace!</div>
						<div className="text-large" style={{ margin:'20px 0' }}>We are glad to have you here and want you to make the most out of your experience with VoxelSpace.</div>
						<div className="text-large">As a token of our appreciation, we will be providing you with exclusive access to special features, content that are reserved just for our subscribers. We want to make sure you get the most out of your subscription and feel the value of being a part of our VoxelSpace family.</div>
						<div style={{margin:'30px 0'}}>
							Click the button below to activate your account
						</div>
						{show ? 
							<div style={{background :'#00659c'}} className="btn btn-sm text-medium btn-border-radius-square">
								<a href="/#" style={{textDecoration:'none', color:'#fff'}} onClick={(e) => activate(e)}>
									<ClipLoader
                                        color="fff"
                                        loading={loading}
                                        size={10}
                                        aria-label="Loading Spinner"
                                        data-testid="loader"
                                    />&nbsp;Activate</a>
							</div>
							:''
						}
					</div>
				</div>
			</div>
		</div> 
		<Footer />
	</div>
  );
}

export default Success;
