import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useParams } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react"
import { InteractionType } from "@azure/msal-browser";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


const AcceptInvitation = () => {
    const { uid } = useParams();
    const { product } = useParams();
    const { reference } = useParams();
    const { instance } = useMsal();

	        
    const activate = (e) => {
        e.preventDefault()
        instance.loginPopup()
            .then(function (loginResponse) {
                const infos = loginResponse.idTokenClaims;
                const names = infos.name.split(' ');
                const payload = {
                    "firstname" : names[0],
                    "lastname" : names[1],
                    "email" : infos.preferred_username,
                    "password" : "",
                    "uid" : uid,
                    "product" : product,
                    "subscriptionReference" : reference,
                    "key_id" : process.env.REACT_APP_BASE_APIKEY
                };
               
                const endpoint = process.env.REACT_APP_BASE_URL + '/user/add';
                fetch(endpoint, {

                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    "method": "POST",
                    "body": JSON.stringify(payload)
                })
                .then(response => response.json())
                .then(response => { 
                    
                    if(response.error === false){
                        
                    }else{
                        
                    }
                })
                .catch(err => {
                    console.log(err);
                });
            })
            .catch(function (error) {
                //login failure
                console.log(error);
            });
    } 

    const authRequest = {
        scopes: ["openid", "profile"]
    };
  	return (
        <>
        <AuthenticatedTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={authRequest} 
        >
		    <Header />
            <div className="page-content container-fluid">
                <div className="row justify-content-center">
                   	<div className="spaceTopBottom max-width-720" style={{fontSize: '16px' }}>
						
						<div style={{ textAlign:'center' }}>
							<h5>Your account is successfully created</h5>
						</div>
						<div style={{ textAlign:'center', margin:'20px 0' }}>
                            <div style={{background :'#00659c'}} className="btn btn-sm text-medium btn-border-radius-square">
                                <a href="/myaccount/profile" style={{textDecoration:'none', color:'#fff'}}>My Account</a>
                            </div>
						</div>
					</div>
                </div>
            </div> 
            <Footer />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
        <Header />
            <div className="page-content container-fluid">
                <div className="row justify-content-center">
                   	<div className="spaceTopBottom max-width-720" style={{fontSize: '16px' }}>
                       <div style={{ textAlign:'center' }}>
							<h5>Click on the button below to accept your invitation.</h5>
						</div>
						<div style={{ textAlign:'center', margin:'20px 0' }}>
                            <div style={{background :'#00659c'}} className="btn btn-sm text-medium btn-border-radius-square">
                                <a href="/#" style={{textDecoration:'none', color:'#fff'}} onClick={(e) => activate(e)}>Activate</a>
                            </div>
						</div>
						
					</div>
                </div>
            </div> 
            <Footer />
       
        </UnauthenticatedTemplate>
        </>
        
    );
}

export default AcceptInvitation;
