import React, {useEffect, useState} from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import {HashLink} from "react-router-hash-link"

const ChoosePlan = () => {

    const [productId, setProductId] = useState('');
    const [productName, setProductName] = useState('Starter');
    const [price, setPrice] = useState(0)
    const [isBasicPlan, setIsBasicPlan] = useState(false)
    const [totalPrice, setTotalPrice] = useState(0)
    const [token, setToken] = useState('');

    useEffect(() => {
       
        window.scrollTo(0, 0)
        const userInfo = localStorage.getItem('vxs_user');
		if(userInfo && userInfo !== "undefined"){
            const u = JSON.parse(userInfo)
            if(u.productId !== null && u.productId !== '' && typeof u.productId !== "undefined") {
                setProductId(u.productId)
                setToken(u.token)
                setProductName(u.productName)
                const url = process.env.REACT_APP_BASE_URL + '/plan/prices';
                fetch(url, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    method : "POST",
                    body : JSON.stringify({id : u.productId, token : u.token})
                })
                .then(response => response.json())
                .then(response => {
                    if(response.action === "failed"){
                        return false;
                    }else{
                        setPrice(response.price)
                        if(response.name === 'Starter'){
                            setIsBasicPlan(true)
                            setTotalPrice(response.price)
                        }
                    }
                })
                .catch(err => {
                    console.log(err);
                });
            }
        }
    },[productId, token])

    const handleUsers = (e) =>{
        const nbUsers = e.target.value;
        if(!isBasicPlan){
            setTotalPrice(price*nbUsers*12)
        }
    }

  return (
    <div className='app'>
        <Header/>
		<div className="page-content container-fluid">
            <div className="row justify-content-center">
                <div className="col">
                    <div style={{ marginTop : '120px', padding: '20px'}}>
                        <div className="title" style={{background:'#00415E', padding:'10px',  maxWidth:'200px', borderRadius:'10px'}}>
                            <HashLink to="/#pricing" style={{textDecoration:'none', color: '#fff',}}>Select a new package</HashLink>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="" style={{ padding: '20px', borderRadius: '10px'}}>
                        <div className="title">
                            Package <h3 className="box-title mb-3">{productName}</h3>
                        </div>
                        <div className="title">
                            Price <h3 className="box-title mb-3">{totalPrice} {isBasicPlan ? ' $ ONE MONTH + ONE MONTH FREE' : ' $ PER YEAR'}</h3>
                        </div>
                        {!isBasicPlan ? 
                        <>
                        <div className="form-group mb-4">
                            <label htmlFor="inputHorizontalDnger" className="col-sm-2 col-form-label">Users</label>
                            <div className="col-sm-12">
                                <select className="form-select" aria-label="Default select example" aria-describedby="emailHelp" onChange={handleUsers}>
                                    <option value="0">Select number of users</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>
                                <div id="emailHelp" className="form-text">6 and More...Please contact us.</div>
                            </div>
                        </div>
                        </>
                        : ''
                        }
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div style={{ padding: '20px', borderRadius: '10px'}}>
                        <div id="buynow">buy now</div>
                    </div>
                </div>
            </div>
            
        </div> 
        <Footer />
    </div>
  );
}

export default ChoosePlan;
