import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import {useContext} from 'react'
import { Context } from '../components/Context'

const InvalidPasswordLink = () => {

	const {setLoginSigninDialog, setLoginDialog, setSigninDialog, setForgotPasswordDialog} = useContext(Context);

	const handleLoginClick = (e) => {
		e.preventDefault();
		setLoginSigninDialog(true)
		setLoginDialog(false)
		setSigninDialog(false)
		setForgotPasswordDialog(true)
	}
    return (
        <div>
            <Header />
            <div className="page-content container-fluid">
                <div className="row justify-content-center">
                   	<div className="spaceTopBottom max-width-720" style={{fontSize: '16px' }}>
						<div>
							<h3>Try resetting your password again</h3>
						</div>
						<br/>
						<div>
							<h5>Your request to reset your password has expired or the link has already been used</h5>
						</div>
						<br/>
						<div>
							<Link to="/#" onClick={(e) => handleLoginClick(e)}>Reset your password again</Link>
						</div>
					</div>
                </div>
            </div> 
            <Footer />
        </div>
    );
}

export default InvalidPasswordLink;