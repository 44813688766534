import React, {useEffect} from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header';

const Terms = () => {

    useEffect(() => {
        window.scrollTo({
            top:0,
            behavior: 'instant'
           });
    },[]);
    
    return (
    <div className="App">
		<Header />
		<div className="bodyContainer">
            <section className="containerfull privacy">
                <div className="content">
                    <div className="text">
                        <div className="headline">VOXEL SPACE</div>
                        <div className="title ">
                            TERMS<span className="font-weight-700"> & </span>CONDITIONS                        
                        </div>
                    </div> 
                    <p>
                    <b className="font-weight-600 title-medium-2">Website Terms of Use</b><br/>
                    </p>
                    <div className="text-medium">
                    <p>
                    The website and services available at www.voxelspace.com (the Website) are governed by these Terms of Use. The Website is operated by Voxel Farm Inc., a company registered in Quebec, Canada (Voxel Farm). In these Terms of Use, 'us,' 'we,' and 'our' refer to Voxel Farm Inc. and any related bodies corporate.
                    </p>
                    <p>
                    <b className="font-weight-600 title-medium-2">Acceptance of Terms of Use</b><br/>
                    </p>
<p>
By registering for or using the Website, you agree to be bound by these Website Terms of Use as they exist at that time. If you do not agree with these Website Terms of Use (including any amendments to these terms), you must immediately cease using the Website.
</p>
<p>
We may amend these Website Terms of Use from time to time. Please check this page periodically for changes. Your continued access to and use of the Website constitutes your acceptance of any revisions or changes to these Website Terms of Use.  
</p>
<p>
<b className="font-weight-600 title-medium-2">Access to Website</b><br/>
</p>
<p>
To access some parts of the Website and certain features, you need to register as a member and create a profile. When you register as a member and create your profile, you will provide us with personal information, such as your name and email address, necessary for activating your membership. Upon registration, we will provide you with a username and password. Use your username and password to log in and access specific features of the Website.
</p>
<p>
Maintain the confidentiality of your password, and you are responsible for all activities conducted through your account, whether or not you have authorized such use.
</p>
<p>
Your account and your right to use the Website are personal, and you must not authorize others to use your account. It is a condition of using the Website that you ensure your contact details are kept up-to-date and accurate. You can change or correct your information by logging in to your profile. Request disabling your account by contacting us at contact@voxelfarm.com.
</p>
<p>
Contact us immediately if you believe someone has gained unauthorized access to your account. 
</p>
<p>
<b className="font-weight-600 title-medium-2">User Content Rules</b><br/>
</p>
<p>
You may submit content to us for inclusion on the Website, and we may publish such content at our sole discretion. Avoid including personal information or health information, such as your name, if you do not want it to appear on the Website. If you include personal information and/or health information in content submitted to the Website, you consent to its publication on the Website. We may edit content to remove or limit personal information and/or health information published, or choose not to publish the content on the Website.
</p>
<p>
We may (but have no obligation to you to) monitor, moderate, or exercise editorial control over any content you submit to the Website. We may (at our sole discretion and without liability to you) edit, remove from the Website, or choose not to publish content on the Website if, in our opinion, that content is or may potentially be:
</p>
<p>
<ul style={{marginLeft:"20px",listStyleType:'disc'}}>
    <li>Incompatible with any of our policies.</li>
    <li>Inaccurate, misleading, false, deceptive, or otherwise contrary to law or regulation.</li>
    <li>Defamatory, discriminatory, abusive, inflammatory, threatening, offensive, obscene, or racially vilifying.</li>
    <li>Inappropriate, off-topic, or vexatious.</li>
    <li>Spam, promoting a product or commercial service, or soliciting business.</li>
    <li>Breaching confidentiality, privacy, or third-party rights.</li>
</ul>
</p>

<p>
<b className="font-weight-600 title-medium-2">Use of Website</b><br/>
</p>
<p>
Agree to use the Website only in accordance with these Website Terms of Use and applicable laws. You may be personally liable for any unlawful use of the Website.   
</p>
<p>
Failure to comply with these Website Terms of Use may result in the suspension or termination of your right to access the restricted sections of the Website, without notice.    
</p>
<p>
If you believe any content or material on the Website is inappropriate, inaccurate, out of date, unlawful, or infringes your rights, please contact us at contact@voxelfarm.com.  
</p>
<p>
You are solely responsible for providing (at your cost) all equipment, software, and internet access necessary to use the Website.  
</p>

<p>
<b className="font-weight-600 title-medium-2">Intellectual Property Rights</b><br/>
</p>
<p>
Voxel Farm Inc. owns or licenses all intellectual property rights in the materials, information, and content on this Website, including, without limitation, the text, graphics, logos, icons, images, video and audio clips, designs, interfaces, and the layout of the Website. This content is protected by copyright laws in Canada and overseas.
</p>
<p>
Access, download, and print content from this Website for your personal or internal business purposes only. Do not publish, adapt, communicate to the public, distribute to third parties, amend, or make any other copy of any part of the content on this Website (other than user content you uploaded to the Website) without our prior written consent.
</p>
<p>
If you upload or provide information to us via the Website, warrant that you are the owner of the materials you upload (or that you are otherwise authorized by the owner to upload those materials).
</p>
<p>
By submitting content to the Website, grant us a worldwide, royalty-free, perpetual license to use, reproduce, edit, modify, and exploit your content (and to sublicense others to do so), in any form or on any medium and for any purpose, including but not limited to:
</p>
<p>
    <ul style={{marginLeft:"20px",listStyleType:'disc'}}>
        <li>Posting your content on our social media pages.</li>
        <li>Using your content in promotions and marketing.</li>
    </ul>

</p>
<p>
Consent to us (and our successors, assigns, and licensees) doing or omitting to do anything with content you upload to the Website that, but for this consent, would constitute an infringement of your moral rights (as defined in the Canadian Copyright Act and equivalent legislation in other jurisdictions). This includes reproducing, publishing, performing, transmitting, exhibiting, adapting, altering, or in any way changing or using the content in any way that we see fit.
</p>
<p>
Reserve the right to review, edit, move, or delete any content or material displayed on or through the Website at any time without notice and without liability. 
</p>
<p>
The Website includes trademarks protected by law. You may not use our trademarks without our prior written consent, except to legitimately identify our products or services. 
</p>
<p>
<b className="font-weight-600 title-medium-2">Security</b><br/>
</p>
<p>
The transmission of information over the Internet is not completely secure or error-free. In particular, emails or other communications to or from us and information submitted to or accessed via this Website may not be secure, and you should use discretion in deciding what information you send to us via these means.
</p>

<p>
Emails or other communications to or from us may undergo email filtering and virus scanning, including by third-party contractors. Do not warrant that such filters and scans will be effective in removing viruses or other potentially harmful code.
</p>
<p>
<b className="font-weight-600 title-medium-2">Liability</b><br/>
</p>
<p>
To the maximum extent permitted by law, Voxel Farm Inc. and each of its directors, officers, employees, and agents exclude all liability for any loss or damage (including, without limitation, direct, indirect, special, or consequential loss, loss of profits, loss of data, or loss of opportunity) which you suffer whether arising under contract, tort (including negligence), equity, statute, or any other cause of action, or otherwise as a result of using the Website or any content, information, or service available on or from the Website.
</p>
<p>
Do not provide any warranty in relation to your use of the Website or as to the currency, completeness, or accuracy of the content displayed on the Website or the availability of the Website. Your use of the Website is at your own risk, and the Website is provided to you "as is" and “as available”. 
</p>
<p>
Nothing in these Website Terms of Use restricts, excludes, or modifies any rights that cannot be excluded under any applicable law, including the consumer guarantees set out in the Competition Act (Canada). If we are liable for a breach of a consumer guarantee (or any other term implied by law) and that liability cannot, by law, be excluded but can be limited, our liability is, to the fullest extent permitted by law, limited to any one or more of the following as we determine in our absolute discretion:  
</p>
<p>
•	In relation to goods, replacing or repairing the goods or paying the costs of having the goods replaced or repaired.
•	In relation to services, resupplying the services or paying the costs of having the services resupplied.
</p>
<p>
By submitting content to the Website, you indemnify and shall keep indemnified those indemnified from and against all liability those indemnified may suffer or incur arising from any claims against us by third parties in connection with the content you submit to us (including that any such content breaches privacy, confidentiality, intellectual property rights, or any law). In this section, “those indemnified” means us and each of our directors, officers, employees, and agents.
</p>
<p>
<b className="font-weight-600 title-medium-2">Linking and Framing</b><br/>
</p>
<p>
The Website may contain links to other sites and may display content or information from other sites within frames on this Website. Not responsible or liable for third-party content or information, and do not warrant the accuracy, currency, or suitability of that information for any purpose.
</p>
<p>
May link to our Website provided you do so in a way that accurately indicates that the link is to a Voxel Farm page and is not misleading.
</p>
<p>
<b className="font-weight-600 title-medium-2">Use of Electronic Addresses</b><br/>
</p>
<p>
May publish electronic addresses of our employees, officers, and partners on this Website for business purposes only. The publication of those electronic addresses should not be taken as consent by such employees, officers, and partners to receiving unsolicited commercial electronic messages not directly related to Voxel Farm.
</p>
<p>
<b className="font-weight-600 title-medium-2">Termination or Suspension of Website</b><br/>
</p>
<p>
Have the right to terminate or suspend the operation of the Website (including in part) for any reason without notice and without liability. May (without limitation) suspend the Website to conduct maintenance or implement updates.
</p>
<p>
<b className="font-weight-600 title-medium-2">Privacy</b><br/>
</p>
<p>
Any personal information collected about you via this Website or otherwise will be dealt with in accordance with our Privacy Policy, www.voxelspace.com/privacy. May use cookies to identify your computer on our server and track your use of this Website. In some instances, cookies may collect and store personal information about you. Such personal information will only be used by us in accordance with our Privacy Policy.
</p>
<p>
<b className="font-weight-600 title-medium-2">Consumer Protection Laws in Canada</b><br/>
</p>
<p>
Consumer protection laws in Canada may vary by province and territory. You are responsible for ensuring compliance with local laws in your jurisdiction.
</p>
<p>
<b className="font-weight-600 title-medium-2">General</b><br/>
</p>
<p>
These Website Terms of Use are governed by the laws of Quebec, Canada. In the event of a legal dispute relating to this Website or these Website Terms of Use, the courts of Quebec will have exclusive jurisdiction.
</p>
<p>
If any provision of these Website Terms of Use is found to be invalid or unenforceable, the provision will be deemed severed from the Website Terms of Use, and the remainder of the terms will continue in full force and effect.
</p>
<p>
Although Canadian websites may be accessed outside of Canada, make no representation that the content of the Website complies with the laws of any other country. If you access the Website from outside Canada, you are solely responsible for ensuring compliance with your local laws and for any reliance on Website content.
</p>


                    </div>           
                </div>
			</section>
        </div>
        <Footer/>
    </div>
  )
}

export default Terms