import React, {useEffect} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Cancel = () => {

    useEffect(() => {
        toast.error("Payment is not successfull completed!", {
            position: toast.POSITION.TOP_CENTER,
            theme: "colored"
        });
    }, [])
    
  return (
    <ToastContainer autoClose={false}/>
  );
}

export default Cancel;
