import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {HashLink} from "react-router-hash-link"


const Footer = ({small}) => {

    const[copyrightDate, setCopyrightDate] = useState()

    const hideFooter = {
        display : small ? 'none' : 'flex'
    }

    useEffect(() => {
        const d = new Date();
        let year = d.getFullYear();
        setCopyrightDate(year)
    },[copyrightDate])
    
    return (
    <> 
    <div className="footer">
        <div className="containerfull">
           
            <div className="content" style={hideFooter}>
                <div className="footer-left">
                    <div className="footer-logo"></div> 
                    <div className="socials">
                        {/* <div className="social facebook"></div> */}
                        <div className="social twitter"></div>
                    </div> 
                </div> 
                <div className='links-container'>
                    <div className="links">
                        <div className="lists">
                            <div className="title-large font-weight-700 uppercase">Quick Links</div>
                            <div className="divider"></div>
                            <div className="items">
                                <div className="item text-extra-large"><HashLink to="/#design" smooth>Home</HashLink></div>
                                <div className="item text-extra-large"><HashLink to="/#features" smooth>Features</HashLink></div>
                                <div className="item text-extra-large"><HashLink to="/#industry" smooth>Industries</HashLink></div>
                                <div className="item text-extra-large"><HashLink to="/#pricing" smooth>Pricing</HashLink></div>
                                {/* <div className="item text-extra-large"><HashLink to="/#design" smooth>About</HashLink></div> */}
                                <div className="item text-extra-large"><HashLink to="/contact" smooth>Contact Us</HashLink></div>
                                <div className="item text-extra-large"><HashLink to="/faq" smooth>FAQ</HashLink></div>
                                <div className="item text-extra-large"><HashLink to="/tutorials" smooth>Tutorials</HashLink></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
            <div className="trademark">
                <div className="copyright text-large">&copy; {copyrightDate} Voxel Farm Inc. All Right Reserved. Voxel Space® is a trademark of Voxel Farm Inc.</div>
                <div className="copyright text-large"><Link to="/privacy" smooth>Privacy Policy</Link> &nbsp;|&nbsp; <Link to="/terms" smooth>Terms & Conditions</Link></div>
                
            </div>  
        </div> 
    </div>
    </>
  )
}

export default Footer