import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
// import Header from '../components/Header';
import Footer from '../components/Footer';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ClipLoader from "react-spinners/ClipLoader";
import Aside from '../components/Aside';
import { ToastContainer } from 'react-toastify';
//import { toast } from 'react-toastify';
// import 'jquery/dist/jquery.min.js'
// import "datatables.net-dt/js/dataTables.dataTables"
// import "datatables.net-dt/css/jquery.dataTables.min.css"
// import $ from 'jquery'; 
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react"
import { InteractionType } from "@azure/msal-browser";
import HeaderPlatform from '../components/HeaderPlatform';
//import PropagateLoader from "react-spinners/PropagateLoader";


const MyAccountPlans = () => {

    const { instance } = useMsal();
    //const [nbPackages, setNbPackages] = useState(1);
    //const [licence, setLicence] = useState('');
 	const [email, setEmail] = useState('');
   	const [subscriptions, setSubscriptions] = useState([]);
    const [role, setRole] = useState('');

	const [loading, setLoading] = useState(false);
    //const [loadingHistory, setLoadingHistory] = useState(false);
    //const [showUpgrade, setShowUpgrade] = useState(false);
    const [currentRef, setCurrentRef] = useState('')
    //const [histories, setHistories] = useState([]);

	useEffect(() => {      
        const activeAccount = instance.getActiveAccount();
        if(activeAccount !== null){
            setEmail(activeAccount.username)
            populate(activeAccount.username)
        }
    }, [instance])
  

    const populate = (email) => {

        const endpoint = process.env.REACT_APP_BASE_URL + '/subscription/email';
        
        fetch(endpoint, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            "method": "POST",
            "body": JSON.stringify({email : email, key_id : process.env.REACT_APP_BASE_APIKEY})
        })
        .then(response => response.json())
        .then(response => {
            setSubscriptions(response.data)
            setRole(response.role)              
        })
        .then(() => {
            setLoading(false)
        })
        .catch(err => {
            console.log(err);
        });
    }

    /*
    const getProductQuantity = (code) => {

        const endpoint = process.env.REACT_APP_BASE_URL + '/product/retrieve';
        fetch(endpoint, {

            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            "method": "POST",
            "body": JSON.stringify({product : code, key_id : process.env.REACT_APP_BASE_APIKEY})
        })
        .then(response => response.json())
        .then(response => { 
            
            if(response !== false){
                const qtity = response.info.PricingConfigurations.Prices.Regular.MinQuantity;
                setNbPackages(qtity)
            }
        })
        .catch(err => {
            console.log(err);
            setLoadingHistory(false)
        });
    }
    */
    
    const updateStatus = (e, subscription_reference, action) => {
        e.preventDefault()
		setCurrentRef(subscription_reference)

		const endpoint = (action === 'cancel') ? process.env.REACT_APP_BASE_URL + '/subscription/y99d5wwzft/disable' : process.env.REACT_APP_BASE_URL + '/subscription/y99d5wwzft/enable';

		fetch(endpoint, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            "method": "POST",
            "body": JSON.stringify({subscription_reference : subscription_reference, key_id : process.env.REACT_APP_BASE_APIKEY})
        })
        .then(response => response.json())
        .then(response => {     
            console.log('Action : ' + response.action)
            if(response.action === false){
                // toast.error((action === 'cancel') ? "We are not able to cancel your subscription. Please contact us!" : "We are not able to reactivate your subscription. Please contact us!", {
                //     position: toast.POSITION.TOP_CENTER,
                //     theme: "colored"
                // });
				setCurrentRef('')
            }else{
				// toast.success((action === 'cancel') ? "Your subscription is successfully canceled!" : "Your subscription is successfully reactivated!", {
                //     position: toast.POSITION.TOP_CENTER,
                //     theme: "colored"
                // });             
                setTimeout(() => {
                    setCurrentRef('')
                    populate(email) ;
                }, 6000);
            }
        })
        .catch(err => {
            console.log(err);
        });
    }

    // const openUpgrade = async (e, license) => {
    //     e.preventDefault();
    //     // setLicence(license)
    //     // setShowUpgrade(true)
    //     const url = 'https://secure.2checkout.com/order/upgrade.php?LICENSE=' + license + '&QTY=20';
    //     setTimeout(() => {
    //         window.open(url, '_blank');
    //     }, 1000);
    // }
    


    // const handleClosePopup = () => {
	// 	setShowUpgrade(false)
	// }

    // const updateNb = (e) => {
    //     setNbPackages(e.target.value)
    // }

    /*
    const handleSubmit = (e) => {
        e.preventDefault();
        setShowUpgrade(false)
        const url = 'https://secure.2checkout.com/order/upgrade.php?LICENSE=' + licence + '&QTY=' + nbPackages;

        setTimeout(() => {
            window.open(url, '_blank');
        }, 1000);
    }
    */
    /*
    const showHistory = (e, subscription_reference) => {
        e.preventDefault();
        setLoadingHistory(true)
            
        const endpoint = process.env.REACT_APP_BASE_URL + '/subscription/y99d5wwzft/history';
            fetch(endpoint, {

                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                "method": "POST",
                "body": JSON.stringify({subscription_reference : subscription_reference, key_id : process.env.REACT_APP_BASE_APIKEY})
            })
            .then(response => response.json())
            .then(response => { 
				
				if(response === false){
					setLoadingHistory(false)
				}else{
                    setLoadingHistory(false)
                    setHistories(response.action)
				}
			})
            .catch(err => {
                console.log(err);
                setLoadingHistory(false)
            });
            
    }
    */
    const authRequest = {
        scopes: ["openid", "profile"]
    };

    
  	return (
        <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={authRequest} 
        >
		<div>
            <ToastContainer />
            {/* {
                showUpgrade 
                ? 
                    <>
                    <div className="login-overlay overlay"></div>
                    <div id="loginformplan" style={{ minHeight:'unset' }}>
                        <div className="login-close" onClick={handleClosePopup} style={{ top:'20px',right:'20px' }}></div>
                        <div className="title">
                            <h4 className="box-title mb-3">Upgrade</h4>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <form>
                                    <div className="mb-3 form-group required">
                                        <label htmlFor="firstname" className="form-label">Number of packages</label>
                                        <select className="form-select text-small" name="nb" onChange={(e) => updateNb(e)}>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                        </select>
                                    </div>
                                    
                                    <button type="submit" className="btn btn-sm text-medium btn-border-radius-square" onClick={(e) => handleSubmit(e)}>Upgrade</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    </>
                : ''
            } */}

            <div className="page-profile">
                <Aside selected="plans"/>
                <div className="container-profile">
                <HeaderPlatform />
                
				    <div className="text">
                    <div className="breadcrumbs"><Link to="/myaccount/profile">My Account </Link>&nbsp;<i className="fa-solid fa-angle-right"></i>&nbsp;<span>Plans</span></div>
                        <div className="title" style={{ position:'relative' }}>
                            <span className="font-weight-700 title-large-2"> Plans</span>
                        </div>
                        <section>
                      
                            <div className="form text-large" style={{padding:'40px'}}>
                            {
                            loading ? '' :  
                            subscriptions.length > 0 ?
                                <table id="file_export" className="standardTable">
                                    <thead>
                                        <tr>
                                            <th className="no-sort">Email</th>
                                            <th className="no-sort">Purchase Email</th>
                                            <th className="no-sort">Licence</th>
                                            <th className="no-sort">Product</th>
                                            {/* <th className="no-sort" style={{fontWeight:'600', padding : '8px', color:'#fff'}}>Billing</th> */}
                                            {/* <th className="no-sort" style={{fontWeight:'600', padding : '8px', color:'#fff'}}>Qty</th> */}
                                            <th className="no-sort">Start Date</th>
                                            <th className="no-sort">Expiration Date</th>
                                            <th className="no-sort">Status</th>
                                           
                                            {
                                            role === 'admin' ?
                                                <>
                                                <th className="no-sort">Active users</th>
                                                {/* <th className="no-sort"></th> */}
                                                <th className="no-sort"></th>
                                                </>
                                            : ''
                                            }
                                        </tr>
                                    </thead>  
                                    <tbody>      
						            {
                                        subscriptions.map((item, i) => (
                                            <tr key={i} style={{color:'#282828' , borderBottom: (i < subscriptions.length-1) ? '1px solid #ccc' : 'none'}}>
                                                <td>{item.email}</td>
                                                <td>{item.purchase_email}</td>
                                                <td>
                                                    {
                                                    role === 'admin' ?
                                                        <a href={"/myaccount/subscription/" + item.subscription_reference} style={{textDecoration:'none' }}>{item.subscription_reference}</a>
                                                    :
                                                    item.subscription_reference
                                                    }    
                                                </td>
                                                <td>{item.ipn_name}</td>
                                                {/* <td style={{padding:'20px 5px'}}>{item.billing_cycle}</td> */}
                                                {/* <td style={{padding:'20px 5px'}}>{item.ipn_qty}</td> */}
                                                <td>{item.startDate}</td>
                                                <td>{item.expirationDate}</td>
                                                <td>{item.subscription_status.toLowerCase()}</td>	
                                                {
                                                role === 'admin' ?
                                                    
                                                    item.subscription_status === 'ACTIVE' ?
                                                    <>
                                                        <td style={{textAlign:'center',minWidth:'100px', padding:'20px 5px'}}>{item.active_users + '/' + item.ipn_qty}</td>
                                                        <td>							
                                                            <button type="button" style={{width:'80px'}} className="btn btn-danger btn-sm text-medium" rel={item.subscription_reference} onClick={(e) => updateStatus(e, item.subscription_reference, 'cancel')}>
                                                                <ClipLoader
                                                                    color="fff"
                                                                    loading={currentRef === item.subscription_reference}
                                                                    size={10}
                                                                    aria-label="Loading Spinner"
                                                                    data-testid="loader"
                                                                />
                                                                Cancel
                                                            </button>
                                                        </td>
                                                        {/* <td>                                                              
                                                            <a href="/#" onClick={(e) => openUpgrade(e, item.subscription_reference)}><div style={{background :'#508cfa'}} className="btn btn-sm text-medium btn-border-radius-square">Upgrade</div> </a>
                                                        </td> */}
                                                    </>
                                                    :
                                                    <>
                                                        <td style={{textAlign:'center', minWidth:'100px', padding:'20px 5px'}}>{item.active_users + '/' + item.ipn_qty}</td>
                                                        <td>						
                                                            <button type="button" style={{width:'80px'}} className="btn btn-success btn-sm text-medium" rel={item.subscription_reference} onClick={(e) => updateStatus(e, item.subscription_reference, 'reactivate')}>
                                                                <ClipLoader
                                                                    color="fff"
                                                                    loading={currentRef === item.subscription_reference}
                                                                    size={10}
                                                                    aria-label="Loading Spinner"
                                                                    data-testid="loader"
                                                                />
                                                            Reactivate
                                                            </button>
                                                        </td>
                                                        {/* <td>                                                              
                                                            <a href="/#" onClick={(e) => openUpgrade(e, item.subscription_reference)}><div style={{background :'#508cfa'}} className="btn btn-sm text-medium btn-border-radius-square">Upgrade</div> </a>
                                                        </td> */}
                                                    </>                                 
                                                : ''
                                                }
                                            </tr>              
                                        ))    
                                    }
                                    </tbody>
                                   
                                </table>
                                : 
                                <div style={{ maxWidth:'300px', fontWeight:'bold', margin:'20px auto', textAlign:'center' }}>
                                    <div>No subscription found</div>
                                    {/* <br/>
                                    
                                    <div style={{background :'#00659c'}} className="btn btn-sm text-medium btn-border-radius-square">
                                        <Link to="/#pricing" style={{textDecoration:'none', color:'#fff'}}>Buy Now</Link>
                                    </div> */}
                                </div>
                            }
                            </div>     
                        </section>
                        {
                       
                            // <section>
                               
                               
                            //     {
                            //     loadingHistory 
                            //     ? 
                            //         <div style={{ width:'120px', margin:'10px auto' }}><PropagateLoader color="#00659c" /></div>
                            //     :
                            //         histories.length > 0 
                            //         ?
                            //         <div className="form text-large">
                            //             <div className="title-medium-2 font-weight-600" style={{ margin:'10px 0' }}>History</div>
                            //             <div className="text-large">
                            //                 <div className="info" style={{ background:'rgb(167 162 162 / 10%)' }}>
                            //                     <div className="val fullwidth">Reference</div>
                            //                     <div className="val fullwidth font-weight-600">Type</div>
                            //                     <div className="val fullwidth">Licence</div>
                            //                     <div className="val fullwidth">Start Date</div>
                            //                     <div className="val fullwidth">Expiration Date</div>
                            //                 </div> 
                            //                 {
                            //                     histories.map((item, i) => 
                            //                     <div key={i}>
                            //                         <div className="info" style={{ background:'#fff' }}>
                            //                             <div className="val fullwidth">{item.ReferenceNo}</div>
                            //                             <div className="val fullwidth font-weight-600">{item.Type}</div>
                            //                             <div className="val fullwidth">{item.SubscriptionReference}</div>
                            //                             <div className="val fullwidth">{item.StartDate}</div>
                            //                             <div className="val fullwidth">{item.ExpirationDate}</div>
                            //                         </div> 
                            //                         {i < histories.length-1 ? <div className="separator"></div> : ''}
                            //                     </div>
                            //                     )
                            //                 }
                            //             </div>
                            //         </div>
                            //         : 
                            //         '' 
                            //     }
                                
                            // </section>
                      
                        }
                    </div> 
                </div>
            </div>
            <Footer small="true"/>
        </div>
       </MsalAuthenticationTemplate>
    );
}

export default MyAccountPlans;
