import React, {useEffect, useState, useRef} from 'react';
import { Link } from 'react-router-dom';
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react"
import { InteractionType } from "@azure/msal-browser";
import { useIsAuthenticated } from "@azure/msal-react"
import { confirmAlert } from 'react-confirm-alert'; // Import
//import { ToastContainer, toast } from 'react-toastify';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Aside from '../components/Aside';
import HeaderPlatform from '../components/HeaderPlatform';
import Footer from '../components/Footer';
//import { Client } from '@microsoft/microsoft-graph-client';


const MyAccountTeam = () => {

    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const newRef = useRef(null);
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [email, setEmail] = useState('')
    const [confirmEmail, setConfirmEmail] = useState('')
    const [parentEmail, setParentEmail] = useState('')
    const [parentName, setParentName] = useState('')
   
    // const [customer, setCustomer] = useState('')
    // const [token, setToken] = useState('');
    const [users, setUsers] = useState('');
    const [showEditPopup, setShowEditPopup] = useState(false)
    const [showAddUser, setShowAddUser] = useState(false)
    const [products, setProducts] = useState([])

    const [productSelected, setProductSelected] = useState('')
    const [subscriptionReference, setSubscriptionReference] = useState('')

    const [uid, setUid] = useState('');
    const [userId, setUserId] = useState('');
    const [showTxt, setShowTxt] = useState(false);

    const [showNoLicence, setShowNoLicence] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [showMsg, setShowMsg] = useState(false);

    //const [token, setToken] = useState('');
     
	useEffect(() => {
       
        if(isAuthenticated){
          
			const activeAccount = instance.getActiveAccount();
            console.log(activeAccount)
          	const username = activeAccount.username;
            setParentEmail(username)
            setParentName(activeAccount.name)
            populate(username)

            //GET UID OF THE ADMIN
            const endpoint = process.env.REACT_APP_BASE_URL + '/user/retrieve/uid';
            fetch(endpoint, {

                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                "method": "POST",
                "body": JSON.stringify({email : activeAccount.username, key_id : process.env.REACT_APP_BASE_APIKEY})
            })
            .then(response => response.json())
            .then(response => { 
				
				if(response.error === false){
					setUid(response.uid)
				}else{
					
				}
			})
            .catch(err => {
                console.log(err);
            });

        }
        document.addEventListener("click", handleOutsideClick, true)

    }, [instance, isAuthenticated, parentEmail])

    const handleOutsideClick = (e) => {
		if (newRef.current && !newRef.current.contains(e.target)) {
			setShowEditPopup(false)
            setShowAddUser(false)
		}
	};

    const handleFirstname = (event) => {
        setFirstname(event.target.value)
        if (event.target.value.length < 2 ){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }
        else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
        }
    }

	const handleLastname = (event) => {
        setLastname(event.target.value)
        if (event.target.value.length < 1 ){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }
        else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
        }
    }

	const handleEmail = (event) => {
        setEmail(event.target.value)
        if (event.target.value.length < 5  || 
            event.target.value.split("").filter(x => x === "@").length !== 1 || event.target.value.indexOf(".") === -1) {
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
        }
    }
    
    const handleConfirmEmail = (event) => {
        setConfirmEmail(event.target.value)
        if (event.target.value.length < 5  || 
            event.target.value.split("").filter(x => x === "@").length !== 1 || event.target.value.indexOf(".") === -1) {
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else if(event.target.value !== email){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
        }
    }

    const populate = (email) => {

        const endpoint = process.env.REACT_APP_BASE_URL + '/v2/customer/myusers';
            fetch(endpoint, {

                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                "method": "POST",
                "body": JSON.stringify({email : email, key_id : process.env.REACT_APP_BASE_APIKEY})
            })
            .then(response => response.json())
            .then(response => { 
				
				if(response.error === false){
					setUsers(response.data)
                    setShowTxt(true)
					return false;
				}else{
                    setShowTxt(true)
					return true;
				}
			})
            .catch(err => {
                console.log(err);
            });
    }


    const handleSubmit = (e) => {
        e.preventDefault();
       
        const firstnameElem = document.querySelector('#firstname');
        const lastnameElem = document.querySelector('#lastname');
        const emailElem = document.querySelector('#email');
        const productElem = document.querySelector('#product');
       
        if(productSelected.length === 0){
            productElem.classList.remove('is-valid');
            productElem.classList.add('is-invalid');
            return false;
        }

        if(firstnameElem.classList.contains('is-invalid') || firstname.length === 0){
            firstnameElem.classList.remove('is-valid');
            firstnameElem.classList.add('is-invalid');
            return false;
        }
        if(lastnameElem.classList.contains('is-invalid') || lastname.length === 0){
            lastnameElem.classList.remove('is-valid');
            lastnameElem.classList.add('is-invalid');
            return false;
        }
        if(emailElem.classList.contains('is-invalid') || email.length === 0){
            emailElem.classList.remove('is-valid');
            emailElem.classList.add('is-invalid');
            return false;
        }
     
        const data = {
            email : email,
            firstname : firstname,
            lastname : lastname,
            userid: userId,
            uid : uid,
            product : productSelected,
            subscriptionReference : subscriptionReference,
            key_id : process.env.REACT_APP_BASE_APIKEY
        };
        const endpoint = process.env.REACT_APP_BASE_URL + '/user/manage/account/update';

        fetch(endpoint, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            "method": "POST",
            "body": JSON.stringify(data)
        })
        .then(response => response.json())
        .then(response => {     
                     
            if(response.action === "failed"){  
              
                return false;
            }else{
                populate(parentEmail)
                setShowEditPopup(false)
            }
        })
        .catch(err => {
            console.log(err);
        });
    }
   
    const handleChange = (e, val, status) => {
        e.preventDefault()
        const list = val.split('_');
        const uid = list[0];
        const id = list[1];

        const endpoint = process.env.REACT_APP_BASE_URL + '/user/update/status';
        fetch(endpoint, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            "method": "POST",
            "body": JSON.stringify({uid : uid, id : id, status : status, key_id : process.env.REACT_APP_BASE_APIKEY})
        })
        .then(response => response.json())
        .then(response => {     
            
            if(response.action === "failed"){
                return false;
            }else{
                populate(parentEmail)
            }
        })
        .catch(err => {
            console.log(err);
        });
    };
    

    const editUser = (e, uid, id) => {
        e.preventDefault()
        setUid(uid)
        setUserId(id)
        setShowEditPopup(true)

        if(uid !== null){

            const url = process.env.REACT_APP_BASE_URL + '/v2/subscription/products';
            fetch(url, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                "method": "POST",
                "body": JSON.stringify({email : parentEmail, key_id : process.env.REACT_APP_BASE_APIKEY})
            })
            .then(response => response.json())
            .then(response => {     
                setProducts(response.data)
                setShowForm(true)
                if(parseInt(response.data.length) < 1)
                    setShowNoLicence(true)
                else
                    setShowNoLicence(false)
            })
            .catch(err => {
                console.log(err);
            });

            const endpoint = process.env.REACT_APP_BASE_URL + '/user/manage/info';
            fetch(endpoint, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                "method": "POST",
                "body": JSON.stringify({uid : uid, key_id : process.env.REACT_APP_BASE_APIKEY})
            })
            .then(response => response.json())
            .then(response => {     
            
                if(response.action === "failed"){
                    //setErrorMessage(response.msg)
                }else{
                    setFirstname(response.firstname)
                    setLastname(response.lastname)
                    setEmail(response.email)
                    
                }
            })
            .catch(err => {
                console.log(err);
            });
        }
    }


    const createUser = (e) => {
       
        e.preventDefault();
        const emailElem = document.querySelector('#email');     
        if(emailElem.classList.contains('is-invalid') || email.length === 0){
            emailElem.classList.remove('is-valid');
            emailElem.classList.add('is-invalid');
            return false;
        }

        const emailConfirmElem = document.querySelector('#confirmEmail');     
        if(emailConfirmElem.classList.contains('is-invalid') || confirmEmail.length === 0){
            emailConfirmElem.classList.remove('is-valid');
            emailConfirmElem.classList.add('is-invalid');
            return false;
        }

        const data = {
            uid : uid,
            email : email,
            parentName : parentName,
            product : productSelected,
            subscription_reference : subscriptionReference,
            key_id : process.env.REACT_APP_BASE_APIKEY
        };
        const endpoint = process.env.REACT_APP_BASE_URL + '/user/invite';

        fetch(endpoint, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            "method": "POST",
            "body": JSON.stringify(data)
        })
        .then(response => response.json())
        .then(response => {     
            console.log(response)
            setShowForm(false)
            setShowMsg(true)
            setShowAddUser(true)
            reset()
        })
        .catch(err => {
            console.log(err);
        });
    }
  
  

    const reset = () => {
        setEmail('')
        setConfirmEmail('')
        setFirstname('')
        setLastname('')
    }


    const addUser = (e) => {
        e.preventDefault()
        setShowMsg(false)
        reset()
        setShowAddUser(true)

        const endpoint = process.env.REACT_APP_BASE_URL + '/v2/subscription/products';
        fetch(endpoint, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            "method": "POST",
            "body": JSON.stringify({email : parentEmail, key_id : process.env.REACT_APP_BASE_APIKEY})
        })
        .then(response => response.json())
        .then(response => {     
            setProducts(response.data)
            setShowForm(true)
            if(parseInt(response.data.length) < 1){
                setShowNoLicence(true)
            }else{
                setProductSelected(response.data[0].prdt_code)
                setSubscriptionReference(response.data[0].subscription_reference)
                setShowNoLicence(false)
            }
        })
        .catch(err => {
            console.log(err);
        });
    }


    const handleClosePopup = () => {
		setShowEditPopup(false)
        setShowAddUser(false)
	}

    const updateProduct = (e) => {
        e.preventDefault()
        const infos = e.target.value.split('_');
        setProductSelected(infos[0])
        setSubscriptionReference(infos[1])
        if (e.target.value.length < 1){   
            e.target.classList.remove('is-valid');
            e.target.classList.add('is-invalid');
        }else{
            e.target.classList.remove('is-invalid'); 
            e.target.classList.add('is-valid'); 
        }
    }

    const deleteCustomer  = (id) =>{
       
        const endpoint = process.env.REACT_APP_BASE_URL + '/v2/user/delete';
        fetch(endpoint, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            "method": "POST",
            "body": JSON.stringify({id : id, key_id : process.env.REACT_APP_BASE_APIKEY})
        })
        .then(response => response.json())
        .then(response => {     
        
            if(response.action === "failed"){
                return false;
            }else{
              populate(parentEmail);
            }
        })
        .catch(err => {
            console.log(err);
        });
    }

    const deleteUser = (e, id) => {
        e.preventDefault()
     
        setUid(id)
        
        setTimeout(() => {
            //confirmAlert(options);
            confirmAlert({
                customUI: ({ onClose }) => {
                  return (
                    <div className="dialog-overlay">
                        <div className="dialog">
                            <header> 
                                <h3> Delete customer </h3> 
                                <i className="fa fa-close" onClick={onClose}></i>
                            </header>
                            <div className="dialog-msg"> 
                                <p> Are you sure you want to delete this customer </p> 
                            </div>
                            <footer>
                                <div className="controls"> 
                                    <button type="button" className="text-medium btn-border-radius-square" onClick={() => {
                                        deleteCustomer(id); onClose();
                                    }}>
                                        Yes
                                    </button>  
                                    <button className="button button-default" onClick={onClose}>Cancel</button> 
                                </div>
                            </footer>
                        </div>
                    </div>
                  );
                }
              });
        }, 100);
    }

    const authRequest = {
        scopes: ["openid", "profile"]
    };
  	return (
        <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={authRequest} 
        >
		<div>
            {/* <ToastContainer /> */}
            <div className="page-profile">
                <Aside selected="team"/>
                <div className="container-profile">
                    <HeaderPlatform />
				    <div className="text">
                        <div className="breadcrumbs"><Link to="/myaccount/profile">My Account </Link>&nbsp;<i className="fa-solid fa-angle-right"></i>&nbsp;<span>Teams</span></div>
                        <div className="title" style={{ position:'relative' }}>
                            <span className="font-weight-700 title-large-2"> Team</span>
                            <div style={{textAlign:'right'}}>
                                <button type="button" className="btn btn-light" onClick={(e) => addUser(e)}><i className="fa fa-add"></i>&nbsp; Invite user</button>
                            </div>
                        </div>
                        <section>
                           
                           
                            <div className="form text-large" style={{padding: '40px'}}>
                                {
                                showTxt ?                               
                                    users.length > 0 ? 
                                    <table id="file_export" className="standardTable">
                                         <thead>
                                            <tr>
                                                <th className="no-sort" style={{fontWeight:'600', padding : '8px', color:'#fff'}}>Plan</th>
                                                <th className="no-sort" style={{fontWeight:'600', padding : '8px', color:'#fff'}}>First Name</th>
                                                <th className="no-sort" style={{fontWeight:'600', padding : '8px', color:'#fff'}}>Last Name</th>
                                                <th className="no-sort" style={{fontWeight:'600', padding : '8px', color:'#fff'}}>Email</th>
                                                <th className="no-sort" style={{fontWeight:'600', padding : '8px', color:'#fff'}}></th>
                                                <th className="no-sort" style={{fontWeight:'600', padding : '8px', color:'#fff'}}></th>
                                                <th className="no-sort" style={{fontWeight:'600', padding : '8px', color:'#fff'}}></th>
                                            </tr>
                                        </thead>  
                                        <tbody> 
                                        {
                                        users.map((item, i) => 
                                            <tr key={i} style={{color:'#282828' , borderBottom: (i < users.length-1) ? '1px solid #ccc' : 'none'}}>
                                                <td style={{padding:'20px 5px'}}>{item.ipn_name}</td>
                                                <td style={{padding:'20px 5px', fontWeight:'600'}}>{item.firstname}</td>
                                                <td style={{padding:'20px 5px'}}>{item.lastname}</td>
                                                <td style={{padding:'20px 5px'}}>{item.email}</td>
                                                <td style={{padding:'20px 5px'}}>
                                                    <div style={{ textAlign:'center' }} className="val fullwidth" onClick={(e) => editUser(e, item.uid, item.id)}><i className="fa fa-edit" style={{ cursor:'pointer' }}></i></div>
                                                </td>
                                                <td style={{padding:'20px 5px'}}>
                                                    <div style={{ textAlign:'center' }} className="val fullwidth" onClick={(e) => deleteUser(e, item.id)}><i className="fa fa-trash-can" style={{ cursor:'pointer' }}></i></div>
                                                </td>
                                                <td style={{padding:'20px 5px'}}>
                                                    <div>
                                                        {item.status === 'inactive' ?
                                                            <button type="button" className="text-medium btn-border-radius-square disabled" onClick={(e) => handleChange(e, item.uid + '_' + item.id, 'active')}>Enable</button>
                                                        :
                                                            <button type="button" className="text-medium btn-border-radius-square" onClick={(e) => handleChange(e, item.uid + '_' + item.id, 'inactive')}>Disable</button>
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                        }
                                        </tbody>
                                    </table>
                                    : <div>No user found</div>
                                : ''
                                }
                            </div>
                        </section>
                    </div> 
                </div>
            </div>
			
	      
            {showEditPopup ? 
            <>
            <div className="login-overlay overlay"></div>
            <div id="loginformplan" ref={newRef}>
                <div className="content">
                    <div className="login-close" onClick={handleClosePopup}></div>
                    <div className="title">
                        <h4 className="box-title mb-3">Edit User Info</h4>
                    </div>
                    <div className="row">
                        <div className="col-12">
                        <form className="mt-3 form-horizontal" style={{ marginBottom:'170px' }}>
                            
                            <div className="form-group mb-4">
                                <label htmlFor="inputHorizontalDnger" className="col-sm-2 col-form-label">First Name</label>
                                <div className="col-sm-12">
                                    <input type="text" className="form-control" id="firstname" value={firstname} onChange={handleFirstname}/>
                                    <div className="invalid-feedback">Please provide with a valid First Name </div>
                                    <div className="valid-feedback"></div>
                                </div>
                            </div>
                            <div className="form-group mb-4">
                                <label htmlFor="inputHorizontalDnger" className="col-sm-2 col-form-label">Last Name</label>
                                <div className="col-sm-12">
                                    <input type="text" className="form-control" id="lastname" value={lastname} onChange={handleLastname}/>
                                    <div className="invalid-feedback">Please provide with a valid Last Name </div>
                                    <div className="valid-feedback"></div>
                                </div>
                            </div>

                            <div className="form-group mb-4">
                                <label htmlFor="inputHorizontalDnger" className="col-sm-2 col-form-label">Email</label>
                                <div className="col-sm-12">
                                    <input type="email" className="form-control" id="email" value={email} onChange={handleEmail}/>
                                    <div className="invalid-feedback">Please provide with a valid Email </div>
                                    <div className="valid-feedback"></div>
                                </div>
                            </div>
                            <div className="mb-3 form-group" style={{ display:'none' }}>
                                {
                                products.length === 0 ? 
                                    <div>No product avalaible</div> : 
                                    <>
                                    <label htmlFor="product" className="form-label">Product</label>
                                  
                                    <select className="form-select text-small" name="product"  id="product"  onChange={(e) => updateProduct(e)}>
                                        {/* <option value=''>Select a product</option>
                                        {
                                            products.map((item, i) => 
                                            <option value={item.prdt_code+ '_' + item.subscription_reference} key={i} selected>{item.prdt_name}</option>)
                                        } */}
                                        <option value={products[0].prdt_code+ '_' + products[0].subscription_reference}>{products[0].prdt_name}</option>
                                    </select>
                                    </>
                                }
                                <div className="invalid-feedback">Assign a product to the user </div>
                                <div className="valid-feedback"></div>    
                            </div> 

                            <div className="form-group mb-4">
                            <button className="btn-info" type="submit" onClick={handleSubmit}>Submit form</button>
                            </div>
                        </form>
                        </div>
                    </div>
                </div>
			</div>
            </>
        : ''
        }
        {showAddUser ? 
            <>
            <div className="login-overlay overlay"></div>
            <div id="loginformplan" style={{ minHeight:'unset' }} ref={newRef}>
				<div className="content">
                    <div className="login-close" onClick={handleClosePopup}></div>
                    <div className="title">
                        <h4 className="box-title mb-3">Invite New User</h4>
                    </div>
                    {
                        showMsg ? 
                        <div className="alert alert-success" role="alert">Your invitation is successfully sent. Sometimes, invitation emails may end up in your spam or junk folder. If you don't see the email in your inbox, please check your spam folder and mark it as "Not Spam" to ensure you receive future communications from us.</div>
                        :''
                    }
                    {showForm ? 
                    <div className="row">
                        <div className="col-12">
                            {
                            showNoLicence ?
                                <div className="alert alert-danger" role="alert">
                                    You don't have enough subscription to invite a new user. <br/>
                                </div>
                            :
                            <form>

                                {/* <div className="form-group mb-4">
                                    <label htmlFor="inputHorizontalDnger" className="col-sm-2 col-form-label">First Name</label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="firstname" value={firstname} onChange={handleFirstname}/>
                                        <div className="invalid-feedback">Please provide with a valid First Name </div>
                                        <div className="valid-feedback"></div>
                                    </div>
                                </div>
                                <div className="form-group mb-4">
                                    <label htmlFor="inputHorizontalDnger" className="col-sm-2 col-form-label">Last Name</label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="lastname" value={lastname} onChange={handleLastname}/>
                                        <div className="invalid-feedback">Please provide with a valid Last Name </div>
                                        <div className="valid-feedback"></div>
                                    </div>
                                </div> */}
                               
                                <div className="mb-3 form-group">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input type="text" className="form-control" id="email" aria-describedby="emailHelp" value={email} onChange={handleEmail}/>
                                    <div className="invalid-feedback">Please provide with a valid Email </div>
                                    <div className="valid-feedback"></div>
                                </div>
                                <div className="mb-3 form-group">
                                    <label htmlFor="email" className="form-label">Confirm Email</label>
                                    <input type="text" className="form-control" id="confirmEmail" aria-describedby="emailHelp" value={confirmEmail} onChange={handleConfirmEmail}/>
                                    <div className="invalid-feedback">The two emails don't match</div>
                                    <div className="valid-feedback"></div>
                                </div>
                                
                                                        
                                <button type="submit" className="btn btn-primary text-medium btn-border-radius" onClick={(e) => createUser(e)}>Send</button>
                            </form>
                            }
                        </div>
				    </div>
                :''
                }
                </div>
			</div>
            </>
        : ''
        }
        <Footer small="true"/>
	    </div>
        </MsalAuthenticationTemplate>
    );
}

export default MyAccountTeam;
