import React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';

const ActivateAccount = () => {
    const { activation_code } = useParams();
    const [confirmed, setConfirmed] = useState()
    const [show, setShow] = useState(false)
    const [msg, setMsg] = useState('')

    useEffect(() => {
        window.scrollTo(0, 0)

			const endpoint = process.env.REACT_APP_BASE_URL + '/activate/account';
			fetch(endpoint, {
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				"method": "POST",
				"body": JSON.stringify({activation_code : activation_code, key_id : process.env.REACT_APP_BASE_APIKEY})
			})
			.then(response => response.json())
			.then(response => {   
				if(response.action === 'success'){
                    setShow(true)
                    setConfirmed(true)
                    if(response.msg === 'already_activated')
                        setMsg('Your account has been already activated!');
                    else
                        setMsg('You account is successfully activated!');
                }else{
                    setShow(true)
                    setConfirmed(false)
                }
			});
    },[activation_code]);

  return (
    <div>
         <Header/>
         <div className="page-content container-fluid">
            <div className="row justify-content-center">
                <div className="spaceTopBottom max-width-720" style={{"textAlign" : 'center'}}>
                    <h1 style={{fontSize : '25px'}}>Activate your account</h1>
                    
                    <br/><br/>
                    {
                        show ? 
                            confirmed ? 
                            
                            <div>
                                <div>{msg}</div>
                            </div>
                            :
                            <div>The link is already expired, please signup again.</div>  
                        : ''
                        }
                </div>
            </div>
        </div>
        <Footer />
    </div>
  );
}

export default ActivateAccount;
