import React from 'react';
import {useIsAuthenticated} from '@azure/msal-react';
import { Outlet, Navigate } from "react-router-dom";

const useAuth = () => {
    const isAuthenticated = useIsAuthenticated();
    if (!isAuthenticated) {
        return false;
    }else{
        return true;
    }
}

const ProtectedRoutes = () => {
    const isAuth = useAuth()
    return isAuth ? <Outlet /> : <Navigate to="/login" />;
}


export default ProtectedRoutes;