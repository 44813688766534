import React, {useEffect, useState} from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Aside from '../components/Aside';
import HeaderPlatform from '../components/HeaderPlatform';
import PropagateLoader from "react-spinners/PropagateLoader";

const MyAccountHistory = () => {

    
    const [customer, setCustomer] = useState('')
    const [token, setToken] = useState('');
    const [users, setUsers] = useState('');
    const [showTxt, setShowTxt] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showHistorySection, setShowHistorySection] = useState(false);

    const [histories, setHistories] = useState([]);

	useEffect(() => {
        window.scrollTo(0, 0)

        const userInfo = localStorage.getItem('vxs_user');
        if(userInfo && userInfo !== "undefined"){
            const u = JSON.parse(userInfo)
            setCustomer(u.id)
            setToken(u.token)
            populate(u.id, u.token)
           
        }
    }, [customer, token])


    const populate = (id, token) => {

        const endpoint = process.env.REACT_APP_BASE_URL + '/customer/users';
        fetch(endpoint, {

            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            "method": "POST",
            "body": JSON.stringify({customer : id, token : token, key_id : process.env.REACT_APP_BASE_APIKEY})
        })
        .then(response => response.json())
        .then(response => { 
            
            if(response.error === false){
                setUsers(response.data)
                setShowTxt(true)
                return false;
            }else{
                setShowTxt(true)
                return true;
            }
        })
        .catch(err => {
            console.log(err);
        });
    }

    const showHistory = (e, subscription_reference) => {
        e.preventDefault();
        setLoading(true)
        setShowHistorySection(true)
        const endpoint = process.env.REACT_APP_BASE_URL + '/subscription/y99d5wwzft/history';
            fetch(endpoint, {

                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                "method": "POST",
                "body": JSON.stringify({subscription_reference : subscription_reference, token : token, key_id : process.env.REACT_APP_BASE_APIKEY})
            })
            .then(response => response.json())
            .then(response => { 
				
				if(response === false){
					setLoading(false)
				}else{
                    setLoading(false)
                    setHistories(response.action)
				}
			})
            .catch(err => {
                console.log(err);
                setLoading(false)
            });
    }
 
  	return (
		<div>
            <div className="page-profile">
                <Aside selected="history"/>
                <div className="container-profile">
                    <HeaderPlatform />
				    <div className="text">
                        <div className="title" style={{ position:'relative' }}>
                            <span className="font-weight-700 title-large-3"> HISTORY</span>
                        </div>
                        <section>
                            <div className="blue-subtitle">USER DETAILS</div>
                            <div className="form text-large">
                                {
                                showTxt ?                               
                                    users.length > 0 ? 
                                        users.map((item, i) => 
                                            <div key={i}>
                                            <div className="info">
                                                <div className="val fullwidth font-weight-600">{item.ipn_name}</div>
                                                <div className="val fullwidth font-weight-600">{item.subscription_reference}</div>
                                                <div className="val fullwidth">{item.firstname}</div>
                                                <div className="val fullwidth">{item.lastname}</div>
                                                <div className="val fullwidth">{item.email}</div>
                                                <div className="val" style={{ cursor:'pointer' }} onClick={(e) => showHistory(e, item.subscription_reference)}><i className="fa fa-sharp fa-light fa-eye"></i></div>
                                            </div> 
                                            {i < users.length-1 ? <div className="separator"></div> : ''}
                                            </div>
                                        )
                                    : <div>No history found</div>
                                : ''
                                } 
                            </div>     
                        </section>
                        {
                        showHistorySection ? 
                            <section>
                                <div className="blue-subtitle">History</div>
                                {
                                loading 
                                ? 
                                    <div style={{ width:'120px', margin:'10px auto' }}><PropagateLoader color="#00659c" /></div>
                                :
                                    histories.length > 0 
                                    ?
                                    <div className="form text-large">
                                        <div className="info" style={{ background:'rgb(167 162 162 / 10%)' }}>
                                            <div className="val fullwidth">Reference</div>
                                            <div className="val fullwidth font-weight-600">Type</div>
                                            <div className="val fullwidth">Licence</div>
                                            <div className="val fullwidth">Start Date</div>
                                            <div className="val fullwidth">Expiration Date</div>
                                        </div> 
                                        {
                                            histories.map((item, i) => 
                                            <div key={i}>
                                                <div className="info" style={{ background:'#fff' }}>
                                                    <div className="val fullwidth">{item.ReferenceNo}</div>
                                                    <div className="val fullwidth font-weight-600">{item.Type}</div>
                                                    <div className="val fullwidth">{item.SubscriptionReference}</div>
                                                    <div className="val fullwidth">{item.StartDate}</div>
                                                    <div className="val fullwidth">{item.ExpirationDate}</div>
                                                </div> 
                                                {i < histories.length-1 ? <div className="separator"></div> : ''}
                                            </div>
                                            )
                                        }
                                    </div>
                                    : 
                                    'No history found' 
                                }
                            </section>
                        : ''
                        }
                    </div> 
                </div>
            </div>
	    </div>
    );
}

export default MyAccountHistory;
