import React, {useState, useEffect} from 'react'
import { useNavigate } from "react-router-dom";
import Header from '../components/Header';
import Footer from '../components/Footer';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

const Signup = () => {
    const navigate = useNavigate()
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [email, setEmail] = useState('')
    //const [address, setAddress] = useState('')
    const [company, setCompany] = useState('')
    const [acceptNewletter, setAcceptNewletter] = useState(false)
    // const [city, setCity] = useState('')
    // const [postalCode, setPostalCode] = useState('')
    const [phone, setPhone] = useState('')
    const [password, setPassword] = useState('')
    const [retypepassword, setRetypePassword] = useState('')
    const [country, setCountry] = useState('')
    const [region, setRegion] = useState('')
    
   
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

	const handleFirstname = (event) => {
        setFirstname(event.target.value)
        if (event.target.value.length < 2 ){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }
        else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
        }
    }

	const handleLastname = (event) => {
        setLastname(event.target.value)
        if (event.target.value.length < 1 ){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }
        else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
        }
    }

	const handleEmail = (event) => {
        setEmail(event.target.value)
        if (event.target.value.length < 5  || 
            event.target.value.split("").filter(x => x === "@").length !== 1 || event.target.value.indexOf(".") === -1) {
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
        }
    }

    const handlePassword = (event) => {
        setPassword(event.target.value)
        if (event.target.value.length < 5){   
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
        }
    }

    const handleRetypePassword = (event) => {
        setRetypePassword(event.target.value)
        if (event.target.value.length < 5 || event.target.value !== password) {
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
        }
    }

    // const handleAddress = (event) => {
    //     setAddress(event.target.value)
    //     if (event.target.value.length < 8 ){
    //         event.target.classList.remove('is-valid');
    //         event.target.classList.add('is-invalid');
    //     }
    //     else{
    //         event.target.classList.remove('is-invalid'); 
    //         event.target.classList.add('is-valid'); 
    //     }
    // }

    // const handleCity = (event) => {
    //     setCity(event.target.value)
    //     if (event.target.value.length < 3 ){
    //         event.target.classList.remove('is-valid');
    //         event.target.classList.add('is-invalid');
    //     }
    //     else{
    //         event.target.classList.remove('is-invalid'); 
    //         event.target.classList.add('is-valid'); 
    //     }
    // }

    // const handlePostalCode = (event) => {
    //     setPostalCode(event.target.value.toUpperCase())
    //     if (event.target.value.length < 5 ){
    //         event.target.classList.remove('is-valid');
    //         event.target.classList.add('is-invalid');
    //     }
    //     else{
    //         event.target.classList.remove('is-invalid'); 
    //         event.target.classList.add('is-valid'); 
    //     }
    // }

     
    const reset = () => {
        setFirstname('')
        setLastname('')
        setCompany('')
        setEmail('')
        setPassword('')
        setRetypePassword('')
        setCountry('')
        // setAddress('')
        //setCity('')
        //setPostalCode('')
        setPhone('')
        document.querySelector('#flexCheckDefault').checked = false;
    }

    const UpdateNewletter = (e) => {
        if(e.target.checked === false)
            setAcceptNewletter("false")
        else
            setAcceptNewletter("true")
    }

    const handleSumit = (e) => {
        console.log("Submit")
        e.preventDefault();
      
        const firstnameElem = document.querySelector('#firstname');
        const lastnameElem = document.querySelector('#lastname');
        const emailElem = document.querySelector('#email');
        const passwordElem = document.querySelector('#password');
        const retypepasswordElem = document.querySelector('#retypepassword');
        //const addressElem = document.querySelector('#address');
        //const cityElem = document.querySelector('#city');
        //const postalCodeElem = document.querySelector('#postalCode');
        const phoneElem = document.querySelector('#phone');

       
        if(firstnameElem.classList.contains('is-invalid') || firstname.length === 0){
            firstnameElem.classList.remove('is-valid');
            firstnameElem.classList.add('is-invalid');
            return false;
        }
        if(lastnameElem.classList.contains('is-invalid') || lastname.length === 0){
            lastnameElem.classList.remove('is-valid');
            lastnameElem.classList.add('is-invalid');
            return false;
        }
        if(emailElem.classList.contains('is-invalid') || email.length === 0){
            emailElem.classList.remove('is-valid');
            emailElem.classList.add('is-invalid');
            return false;
        }
        if(passwordElem.classList.contains('is-invalid') || password.length === 0){
            passwordElem.classList.remove('is-valid');
            passwordElem.classList.add('is-invalid');
            return false;
        }
        if(retypepasswordElem.classList.contains('is-invalid') || retypepassword.length === 0){
            retypepasswordElem.classList.remove('is-valid');
            retypepasswordElem.classList.add('is-invalid');
            return false;
        }
        // if(addressElem.classList.contains('is-invalid') || address.length === 0){
        //     addressElem.classList.remove('is-valid');
        //     addressElem.classList.add('is-invalid');
        //     return false;
        // }
        // if(cityElem.classList.contains('is-invalid') || city.length === 0){
        //     cityElem.classList.remove('is-valid');
        //     cityElem.classList.add('is-invalid');
        //     return false;
        // }
        // if(postalCodeElem.classList.contains('is-invalid') || postalCode.length < 5){
        //     postalCodeElem.classList.remove('is-valid');
        //     postalCodeElem.classList.add('is-invalid');
        //     return false;
        // }

        if(phone.length < 7){
            phoneElem.classList.remove('is-valid');
            phoneElem.classList.add('is-invalid');
            return false;
        }
        
        const data = {
            firstname : firstname,
            lastname : lastname,
            company : company,
            email : email,
            password : password,
            retypepassword : retypepassword,
            // address : address,
            newsletter : acceptNewletter,
            //city : city,
            country : country,
            region : region,
            //postalCode : postalCode,
            phone : phone,
            key_id : process.env.REACT_APP_BASE_APIKEY
        };

        const endpoint = process.env.REACT_APP_BASE_URL + '/user/signup';

        fetch(endpoint, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            "method": "POST",
            "body": JSON.stringify(data)
        })
        .then(response => response.json())
        .then(response => {     
            
            if(response.action === "failed"){
                toast.error(response.msg, {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored"
                });
                return false;
            }else{
                toast.success(response.msg, {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored"
                });
                reset()

                setTimeout(() => {
                    navigate('/login')
                }, 10000);
            }
        })
        .catch(err => {
            console.log(err);
        });
    }
	
  return (
    <div className='app'>
        <ToastContainer />
        <Header/>
		<div className="page-content container-fluid">
            <div className="row justify-content-center">
                <div className="col">
                    <div className="spaceTopBottom shadow_around max-width-840" style={{ padding: '20px', borderRadius: '10px'}}>
                        <div className="title">
                            <h3 className="box-title mb-3">Create Your Account</h3>
                        </div>
                        <div>
                            <form>
                               
                                <div className="mb-3 form-group">
                                    <label htmlFor="firstname" className="form-label">Company name</label>
                                    <input type="text" className="form-control" id="company" value={company} onChange={(e) => setCompany(e.target.value)}/>
                                    <div className="invalid-feedback">Please provide with a valid Company Name </div>
                                    <div className="valid-feedback"></div>
                                </div>
                              
                                <div className="mb-3 form-group required">
                                    <label htmlFor="firstname" className="form-label">First Name</label>
                                    <input type="text" className="form-control" id="firstname" value={firstname} onChange={handleFirstname}/>
                                    <div className="invalid-feedback">Please provide with a valid First Name </div>
                                    <div className="valid-feedback"></div>
                                </div>
                                <div className="mb-3 form-group required">
                                    <label htmlFor="lastname" className="form-label">Last Name</label>
                                    <input type="text" className="form-control" id="lastname" aria-describedby="emailHelp" value={lastname} onChange={handleLastname}/>
                                    <div className="invalid-feedback">Please provide with a valid Last Name </div>
                                    <div className="valid-feedback"></div>
                                </div>
                               
                                <div className="mb-3 form-group required">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input type="text" className="form-control" id="email" placeholder="Email" aria-describedby="emailHelp" value={email} onChange={handleEmail}/>
                                    <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
                                    <div className="invalid-feedback">Please provide with a valid Email </div>
                                    <div className="valid-feedback"></div>
                                </div>
                                <div className="mb-3 form-group required">
                                    <label htmlFor="password" className="form-label">Password</label>
                                    <input type="password" className="form-control" id="password" autoComplete='on' value={password} onChange={handlePassword}/>
                                    <div className="invalid-feedback">Please provide with a valid Password (min 5 characters) </div>
                                    <div className="valid-feedback"></div>
                                </div>
                                <div className="mb-3 form-group required">
                                    <label htmlFor="retypepassword" className="form-label">Retype Password</label>
                                    <input type="password" className="form-control" id="retypepassword" autoComplete='on' value={retypepassword} onChange={handleRetypePassword}/>
                                    <div className="invalid-feedback">The passwords should match </div>
                                    <div className="valid-feedback"></div>
                                </div>
                                {/* <div className="mb-3 form-group required">
                                    <label htmlFor="address" className="form-label">Address</label>
                                    <input type="text" className="form-control" id="address" value={address} onChange={handleAddress} />
                                    <div className="invalid-feedback">Please provide with a valid Password (min 8 characters) </div>
                                    <div className="valid-feedback"></div>
                                </div> */}

                                <div className="mb-3 form-group required">
                                    <label htmlFor="Country / Region" className="form-label">Country / Region</label>
                                    <br/>
                                    <CountryDropdown
                                    value={country}
                                    onChange={(val) => setCountry(val)} />

                                    <RegionDropdown
                                    country={country}
                                    value={region}
                                    onChange={(val) => setRegion(val)} />
                                </div>

                                {/* <div className="mb-3 form-group required">
                                    <label htmlFor="city" className="form-label">City</label>
                                    <input type="text" className="form-control" id="city" value={city} onChange={handleCity}/>
                                    <div className="invalid-feedback">Please provide with a valid Password (min 3 characters) </div>
                                    <div className="valid-feedback"></div>
                                </div>

                                <div className="mb-3 form-group required">
                                    <label htmlFor="postalcode" className="form-label">Postal Code</label>
                                    <input type="text" className="form-control" id="postalCode" value={postalCode} onChange={handlePostalCode}/>
                                    <div className="invalid-feedback">Please provide with a valid Postal Code (min 5 characters) </div>
                                    <div className="valid-feedback"></div>
                                </div> */}
                                <div className="mb-3 form-group required">
                                    <label htmlFor="phone" className="form-label">Phone</label>
                                    <PhoneInput
                                        className="form-control"
                                        placeholder="Enter phone number"
                                        value={phone}
                                        id="phone"
                                        onChange={setPhone}/>
                                    <div className="invalid-feedback">Please provide with with a valid Phone Number </div>
                                    <div className="valid-feedback"></div>
                                </div>
                                <div className="mb-3 form-group">
                                    <input className="form-check-input" type="checkbox" value={acceptNewletter} id="flexCheckDefault" onChange={(e) => UpdateNewletter(e)}/>
                                    <label className="form-check-label" htmlFor="flexCheckDefault">&nbsp;Subscribe to the newsletter</label>
                                </div>
                              
                                <button type="submit" className="btn btn-primary" onClick={handleSumit}>Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        <Footer />
    </div>
  );
}

export default Signup;
