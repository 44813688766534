import { useState, useEffect} from "react"
import {AuthenticatedTemplate, useMsal, UnauthenticatedTemplate } from '@azure/msal-react';
import { useNavigate } from "react-router-dom";
import {HashLink} from "react-router-hash-link"
import React from 'react'
import {useContext} from 'react'
import { Context } from './Context';
import 'react-phone-number-input/style.css'
//import PhoneInput from 'react-phone-number-input'
// import PasswordStrengthBar from 'react-password-strength-bar';
//import {  RegionDropdown } from 'react-country-region-selector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
//import ClipLoader from "react-spinners/ClipLoader";


const HeaderPlatform = () => {
	const { instance } = useMsal();
	const [showPanel, setShowPanel] = useState(false)
	const {isLogged, user, setUser, email, setEmail, setUid} = useContext(Context);
	
	if(isLogged)
		document.querySelector('body').classList.add('logged')
	else
		document.querySelector('body').classList.remove('logged')

	const navigate = useNavigate()
	
 
	useEffect(() => {
	
		const activeAccount = instance.getActiveAccount();
		
		if(activeAccount === null){
			document.querySelector('body').classList.remove('logged')
			handleLoginRedirect(instance)
			return;
		}else{
		
			const names = typeof activeAccount.name !== "undefined" ? activeAccount.name.split(' ') : [];
			setUser(names[0])
			setEmail(activeAccount.username)
			document.querySelector('body').classList.add('logged')
		}
    });

	const showMyAccountPanel = () => {
		setShowPanel(true)
	}

	const hideMyAccountPanel = () => {
		setShowPanel(false)
	}
	

	const handleLogoutRedirect = (e, instance) => {
		e.preventDefault();
		instance.logoutPopup()
		.then(function (logoutResponse) {
			console.log(logoutResponse)
			setUser(null)
			setUid(null)
			document.querySelector('body').classList.remove('logged')
			localStorage.removeItem('vxs_user')
			navigate('/');
		})
		.catch((error) => console.log(error));
	};

	const handleLoginRedirect = (instance) => {
		const account = instance.getActiveAccount();
				
		if(!account){
			instance.loginPopup()
			.then(function (loginResponse) {
			
				const infos = loginResponse.idTokenClaims;
				const names = typeof infos.name !== "undefined" ? infos.name.split(' ') : [];
				const session = {
					"firstname" : names[0],
					"lastname" : names[1],
					"email" : infos.preferred_username,
					"token" : loginResponse.idToken,
					"homeAccountId" : loginResponse.homeAccountId
				};
				
				localStorage.setItem("vxs_user", JSON.stringify(session));
				//setIsLogged(true)
				setUser(loginResponse.names[0])

				// Display signed-in user content, call API, etc.
			})
			.catch(function (error) {
				//login failure
				console.log(error);
			});
		}
    };

  	return (
		
	  	<div className="navnew">
			<div className="menu">						
                <div className="menu_item text-medium">
					<div className="menu-right-platform platform" >
					<AuthenticatedTemplate>
						<div className="myaccount-container" onMouseEnter={showMyAccountPanel} onMouseLeave={hideMyAccountPanel}>
							<div className="myaccount-title text-medium">
								<div className="logged-container">
									<div className="icon bottom-shadow">
										<i className="fa fa-user"></i>
									</div>
									<div className="content">
										<div className="firstname">Hello, {user}</div>
										<div className="email">{email}</div>
									</div>
									<div>
										<FontAwesomeIcon icon={faChevronDown} color="#00659D" />
									</div>
								</div>
							</div>
							{showPanel ? 
								<div className="myaccount-panel platform">
									{/* <div className="myaccount-item"><a href="/myaccount/profile">My Account</a></div> */}
									<div className="myaccount-item text-large"><a href="/#" onClick={(e) => handleLogoutRedirect(e, instance)}>Sign Out</a></div>
								</div>
							: ''
							}
						</div>
					</AuthenticatedTemplate>
					<UnauthenticatedTemplate>
						<HashLink to="/login" onClick={(e) => handleLoginRedirect(instance)}><div id="user-login"><div className="user-logo"></div></div></HashLink>
					</UnauthenticatedTemplate>
						
					</div>
				</div>
			</div>		
		</div>		
  	)
}

export default HeaderPlatform;