import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Aside from '../components/Aside';
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react"
import { InteractionType } from "@azure/msal-browser";
import HeaderPlatform from '../components/HeaderPlatform';

const MyAccountSupport = () => {

    const { instance } = useMsal();
 	const [list, setList] = useState('');
    const [showAddComment, setShowAddComment] = useState(false);
    const [email, setEmail] = useState('')
    const [comment, setComment] = useState('');
    const [id, setId] = useState('');
   
	useEffect(() => {      
        const activeAccount = instance.getActiveAccount();
        if(activeAccount !== null){
            setEmail(activeAccount.username)
            populate(activeAccount.username)
        }
    }, [instance])
  

    const populate = (email) => {
        const endpoint = process.env.REACT_APP_BASE_URL + '/user/support/lists/details';
            fetch(endpoint, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                "method": "POST",
                "body": JSON.stringify({key_id : process.env.REACT_APP_BASE_APIKEY})
            })
            .then(response => response.json())
            .then(response => {
                setList(response.data.filter((item) => item.email === email))           
            })
            .then(() => {
               // console.log(list)
            })
            .catch(err => {
                console.log(err);
            });
    }



    const handleClosePopup = () => {
		setShowAddComment(false)
	}
       
    const handleComment = (e) => {
        setComment(e.target.value)
    }

    const saveComment = (e) => {
        e.preventDefault()
        const endpoint = process.env.REACT_APP_BASE_URL + '/user/support/reply';
        fetch(endpoint, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            "method": "POST",
            "body": JSON.stringify({key_id : process.env.REACT_APP_BASE_APIKEY, id : id, comment : comment})
        })
        .then(response => response.json())
        .then(response => {
            console.log(response) 
            populate(email) 
            setShowAddComment(false)        
        })
        .catch(err => {
            console.log(err);
        });
    }

    const showReplyPopup = (e, id) => {
        e.preventDefault()
        setId(id)
        setShowAddComment(true)
    }
    const authRequest = {
        scopes: ["openid", "profile"]
    };

    
  	return (
        <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={authRequest} 
        >
		<div>           
            <div className="page-profile">
                <Aside selected="support"/>
                <div className="container-profile">
                {
                showAddComment ? 
                <>
                    <div className="login-overlay overlay"></div>
                    <div id="loginformplan" style={{ minHeight:'unset' }}>
                        <div className="content">
                            <div className="login-close" onClick={handleClosePopup}></div>
                            {/* <div className="title">
                                <h4 className="box-title mb-3">Add Comment</h4>
                            </div>                           */}
                            <div className="row">
                                <div className="col-12">
                                    <form>
                                        <div className="mb-3 form-group">
                                            <label htmlFor="email" className="form-label">Comment</label>
                                            <textarea type="text" className="form-control" rows="6" id="comment" aria-describedby="emailHelp" value={comment} onChange={handleComment}></textarea>
                                            <div className="invalid-feedback">Please provide with a valid comment </div>
                                            <div className="valid-feedback"></div>
                                        </div>
                                        <button type="submit" className="btn btn-primary text-medium btn-border-radius" onClick={(e) => saveComment(e)}>Save</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    </>
                : ''
                }
                <HeaderPlatform />
				    <div className="text">
                    <div className="breadcrumbs"><Link to="/myaccount/profile">My Account </Link>&nbsp;<i className="fa-solid fa-angle-right"></i>&nbsp;<span>Support</span></div>
                        <div className="title" style={{ position:'relative' }}>
                            <span className="font-weight-700 title-large-2"> Support</span>
                        </div>
                        <section>
                        {
                            list.length > 0 ? 
                                list.map(item =>
                                    <> 
                                        <div className="form text-large" style={{color: '#868ba1', padding:'20px'}} >
                                            <div style={{ display:'flex', justifyContent:'space-between' }}>
                                                <div style={{ flex:1}}>
                                                    <div className="font-weight-700 text-extra-large">{item.subject}</div>
                                                    <div style={{ color:'#00659c' }}>{item.message}</div>
                                                    <div style={{ fontSize:'13px', fontStyle: 'italic'}}>{item.answer}</div>
                                                    {
                                                    typeof item.replies !== "undefined" && item.replies.length > 0 ?
                                                        item.replies.map(elem => 
                                                        <>
                                                            <div style={{ margin:'10px 0', width:'90%', border:'1px solid #ccc', borderStyle: 'dotted' }}></div>
                                                            <div style={{ color:'#00659c' }}>{elem.message}</div>
                                                            <div style={{fontSize:'13px', fontStyle: 'italic'}}>
                                                                <div>{elem.answer}</div>
                                                                <div style={{ color:'#ccc', fontSize:'12px' }}>{elem.creation_date}</div>
                                                            </div>
                                                        </>
                                                        )
                                                    :
                                                    ''
                                                    }
                                                </div>
                                                <div style={{ color:'#ccc', fontSize:'13px' }}>{item.creation_date}</div>
                                            </div>
                                            <div style={{ margin:'10px 0 0' }}>
                                                <button className="btn-info" onClick={(e) => showReplyPopup(e, item.id)}><i className="fa-solid fa-reply"></i> reply</button>
                                            </div>
                                        </div>
                                       
                                    </>
                                )
                            : ''
                        }
                        </section>
                       
                    </div> 
                </div>
            </div>
            <Footer small="true"/>
        </div>
       </MsalAuthenticationTemplate>
    );
}

export default MyAccountSupport;
