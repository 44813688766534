import React, {useState} from 'react'
import {useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useIsAuthenticated } from "@azure/msal-react"
//import { UnauthenticatedTemplate } from "@azure/msal-react";
//import { useNavigate } from "react-router-dom";
import {HashLink} from "react-router-hash-link"
import Header from '../components/Header';
import Footer from '../components/Footer';
import Texts from '../components/Texts';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useContext} from 'react'
import { Context } from '../components/Context';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
// import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
//import DialogTitle from '@mui/material/DialogTitle';
import ClipLoader from "react-spinners/ClipLoader";
import Slide from '@mui/material/Slide';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
//import UsersOptions from '../components/UsersOptions';
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';
import {useMsal } from "@azure/msal-react";
import AOS from 'aos';
import 'aos/dist/aos.css';


import '../App.css';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
	  padding: theme.spacing(0),
	  borderRadius : '30px'
	},
	'& .MuiDialogActions-root': {
	  padding: theme.spacing(0),
	  borderRadius : '30px'
	},
	'& .MuiTypography-root' : {
		marginBottom : '0px',
		borderRadius : '30px'
	},
	'& .MuiDialog-paperFullWidth' : {
		borderRadius : '30px',
		maxWidth: '900px'
	},
	'& .MuiBackdrop-root' : {
		background : 'rgba(0, 0, 0, 0.6) !important'
	},
}));


const Home = () => {
	//const navigate = useNavigate()
	const {setProductName, setUser, setRedirectAfterLoginLink} = useContext(Context);
	const { instance } = useMsal();
	const isAuthenticated = useIsAuthenticated();
	
	const MAX_DESC = 125;
	const [currentRef, setCurrentRef] = useState('')
	const [email, setEmail] = useState('')
	const [thankYouUrl, setThankYouUrl] = useState('')
	const [homeAccountIdHash, setHomeAccountIdHash] = useState('')
	const [showFeatures1, setShowFeatures1] = useState(false)
	const [showFeatures2, setShowFeatures2] = useState(false)
	const [showFeatures3, setShowFeatures3] = useState(false)
	const [showFeatures4, setShowFeatures4] = useState(false)
	//const starterPrice = 300;
	const basicPrice = 550;
	const standardPrice = 2750;
	const advancedPrice = 8550;
	//const premiumPrice = 18000;
	//const [totalStarterPrice, setTotalStarterPrice] = useState(starterPrice)
	// const [totalBasicPrice, setTotalBasicPrice] = useState(basicPrice)
	// const [totalStandardPrice, setTotalStandardPrice] = useState(standardPrice)
	// const [totalAdvancedPrice, setTotalAdvancedPrice] = useState(advancedPrice)

	const totalBasicPrice = basicPrice
	const totalStandardPrice = standardPrice
	const totalAdvancedPrice = advancedPrice

	//const [totalPremiumPrice, setTotalPremiumPrice] = useState(premiumPrice)
	// const [nbBasicPrice, setNbBasicPrice] = useState(5)
	// const [nbStandardPrice, setNbStandardPrice] = useState(10)
	// const [nbAdvancedPrice, setNbAdvancedPrice] = useState(15)
	// const [nbPremiumPrice, setNbPremiumPrice] = useState(20)

	const nbBasicPrice = 5
	const nbStandardPrice = 10
	const nbAdvancedPrice = 15
	//const nbPremiumPrice = 20



	const [checkoutPayload, setCheckoutPayload] = useState([])
	// const [showContactUsBasic, setShowContactUsBasic] = useState(false)
	// const [showContactUsStandard, setShowContactUsStandard] = useState(false)
	// const [showContactUsAdvanced, setShowContactUsAdvanced] = useState(false)
	//const [showContactUsPremium, setShowContactUsPremium] = useState(false)
	// const [hideBasicPrice, setHideBasicPrice] = useState(false)
	// const [hideStandardPrice, setHideStandardPrice] = useState(false)
	// const [hideAdvancedPrice, setHideAdvancedPrice] = useState(false)
	//const [hidePremiumPrice, setHidePremiumPrice] = useState(false)
	
	
	const [featuredItem, setFeaturedItem] = React.useState(Texts.featured[0]);
	const [openFeatured, setOpenFeatured] = React.useState(false);



	const SlickButtonFix = ({currentSlide, slideCount, children, ...props}) => (
		<span {...props}>{children}</span>
	);

	const settings = {
		dots: false,
		infinite: true,
		speed: 1000,
		slidesToShow: 5,
		slidesToScroll: 4,
		autoplay: true,
		autoplaySpeed: 5000,
		prevArrow: (
			<SlickButtonFix>
				<div>
					<div className="prev-slick-arrow"> <i className="fa fa-solid fa-chevron-left"></i> </div>
				</div>
			</SlickButtonFix>
		),
		nextArrow: (
			<SlickButtonFix>
				<div>
					<div className="next-slick-arrow"> <i className="fa fa-solid fa-chevron-right"></i> </div>
				</div>
			</SlickButtonFix>
		),
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true
				}
			},
			{
			  breakpoint: 850,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
				initialSlide: 2
			  }
			},
			{
			  breakpoint: 600,
			  settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			  }
			}
		  ]
	};

	useEffect(() => {
		
		AOS.init({
			duration: 1200, // duration of animations
			once: true,     // whether animation should happen only once - while scrolling down
			easing: 'ease-in-out', // custom easing function
			offset: 100,    // custom offset from trigger point
		});
		 
		if(isAuthenticated){
			const activeAccount = instance.getActiveAccount();
			const data = {
				id : activeAccount.homeAccountId,
				key_id : process.env.REACT_APP_BASE_APIKEY
			};
			const endpoint = process.env.REACT_APP_BASE_URL + '/getHomeAccountIdToken';
	
			fetch(endpoint, {
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				"method": "POST",
				"body": JSON.stringify(data)
			})
			.then(response => response.json())
			.then(data => {  
				const thankurl = process.env.REACT_APP_BASE_HOME + "/payment/success/" + data.hash;
				setThankYouUrl(encodeURIComponent(thankurl));
				setHomeAccountIdHash(data.hash)
			})	
		}
	
	}, [instance, isAuthenticated]);


	// useEffect(() => {
	// 	if(isAuthenticated){
	// 		console.log("Is authenticated !")
	// 		navigate("/myaccount/profile")
	// 	}
	
	// }, [isAuthenticated, navigate]);
	

	const buynow = (e, plan, url) => {
		e.preventDefault()
		//Create session for thankyou page
		setCurrentRef(plan)
		setTimeout(() => {
			setCurrentRef('')
		}, 2000);

		const session = {
			homeAccountId : homeAccountIdHash
		}
		localStorage.setItem("vxs_user_session", JSON.stringify(session));

		setTimeout(() => {
			window.open(url, '_blank');
		}, 2000);
	}


	const handleLoginRedirect = (e, instance, url) => {
		e.preventDefault()
        // instance
        //     .loginRedirect({
        //         ...loginRequest,
        //         prompt: 'create',
        //     })
        //     .catch((error) => console.log(error));
		instance.loginPopup()
		.then(function (loginResponse) {
			const infos = loginResponse.idTokenClaims;
			const names = infos.name.split(' ');
			const session = {
				"firstname" : names[0],
				"lastname" : names[1],
				"email" : infos.preferred_username,
				"token" : loginResponse.idToken,
				"homeAccountId" : loginResponse.account.homeAccountId
			};
			
			localStorage.setItem("vxs_user", JSON.stringify(session));
			setUser(names[0])
			
			const data = {
				id : loginResponse.account.homeAccountId,
				key_id : process.env.REACT_APP_BASE_APIKEY
			};

			const endpoint = process.env.REACT_APP_BASE_URL + '/getHomeAccountIdToken';
	
			fetch(endpoint, {
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				"method": "POST",
				"body": JSON.stringify(data)
			})
			.then(response => response.json())
			.then(data => { 
				
				const session = {
					homeAccountId : data.hash
				}
				localStorage.setItem("vxs_user_session", JSON.stringify(session));

				const redirectURL = url + '&BACK_REF=' + encodeURIComponent(process.env.REACT_APP_BASE_HOME + "/payment/success/" + data.hash) + '&CUSTOMERID=' + data.hash
				// setTimeout(() => {
				// 	window.location.replace(redirectURL);
				// let newTab = window.open();
				// newTab.location.href = redirectURL;
				//window.open(redirectURL, '_blank');
				// }, 1000);

				if( url !== null) {
					setTimeout(() => {
						window.location.replace(redirectURL);
					}, 1000);
				}else{
					setTimeout(() => {
						window.location.replace('https://betatest.vmn.net/voxelspace');
					}, 1000);
				}
			})	
			
		})
		.catch(function (error) {
			console.log(error);
		});
    };

	const debounce = (fn, ms = 0) => {
		let timeoutId;
		return function wrapper(...args) {
		  clearTimeout(timeoutId);
		  timeoutId = setTimeout(() => fn.apply(this, args), ms);
		};
	};

	const handleSync = debounce((ps) => {
		ps.update();
		console.log('debounce sync ps container in 1000ms');
	}, 1000)

	/*
	const updatePlanPrice = (e, plan) => {
		e.preventDefault();

		// if(plan === 'Starter')
		// 	setTotalStarterPrice(starterPrice*e.target.value)

		if(plan === 'Basic'){
			const val = e.target.value.split('_');
			const user = val[0];
			const price = val[1];
			setNbBasicPrice(user)
			setTotalBasicPrice(price*1)
			if(e.target.value === "other"){
				setHideBasicPrice(true)
				setShowContactUsBasic(true)
			}else{
				setHideBasicPrice(false)
				setShowContactUsBasic(false)
			}
		}

		if(plan === 'Standard'){
			const val = e.target.value.split('_');
			const user = val[0];
			const price = val[1];
			setNbStandardPrice(user)
			setTotalStandardPrice(price*1)
			if(e.target.value === "other"){
				setHideStandardPrice(true)
				setShowContactUsStandard(true)
			}else{
				setHideStandardPrice(false)
				setShowContactUsStandard(false)
			}
		}

		if(plan === 'Advanced'){
			const val = e.target.value.split('_');
			const user = val[0];
			const price = val[1];
			setNbAdvancedPrice(user)
			setTotalAdvancedPrice(price*1)
			if(e.target.value === "other"){
				setShowContactUsAdvanced(true)
				setHideAdvancedPrice(true)
			}else{
				setShowContactUsAdvanced(false)
				setHideAdvancedPrice(false)
			}
		}
		
		if(plan === 'Premium'){
			const val = e.target.value.split('_');
			const user = val[0];
			//const price = val[1];
			setNbPremiumPrice(user)
			//setTotalPremiumPrice(price*1)
			if(e.target.value === "other"){
				//setShowContactUsPremium(true)
				//setHidePremiumPrice(true)
			}else{
				//setShowContactUsPremium(false)
				//setHidePremiumPrice(false)
			}
		}
	}

	*/
    const handleEmail = (event) => {
        setEmail(event.target.value)

		if(event.key === 'Enter'){
            
			toast.success("Thank you for your registration", {
				position: toast.POSITION.TOP_CENTER,
				theme: "colored"
			});
        }
		if (event.target.value < 5  || 
            event.target.value.split("").filter(x => x === "@").length !== 1 || event.target.value.indexOf(".") === -1) {
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
        }       
    }

    const handleKeyPress = (e) => {
        if(e.key === 'Enter'){
            sendEmail()
        }
    }

	
	const sendEmail = () => {

		const elem = document.querySelector('#email-newsletter');

		if(elem.value.length === 0){
			elem.classList.remove('is-valid');
			elem.classList.add('is-invalid');
			return;
		}

		if(elem.classList.contains('is-invalid')){
			return;
		}else{
		
			toast.success("Thank you for your registration", {
				position: toast.POSITION.TOP_CENTER,
				theme: "colored"
			});
			setEmail('')
		}
	}

	const addHighlight = (e) => {
		Array.from(document.querySelectorAll('.items > .item')).map((item) => {
			return item.classList.remove('highlight')
		});
		e.target.classList.add('highlight')
	}

	const removeHighlight = (e) => {
		e.target.classList.remove('highlight')
	}
	/*
	const featuredOn = (e) => {
		if(e.target.parentNode.classList.contains('option'))
			e.target.parentNode.classList.add('highlight')
		else if(e.target.parentNode.parentNode.classList.contains('option'))
			e.target.parentNode.parentNode.classList.add('highlight')
		else
			e.target.classList.add('highlight')
	}

	const featuresOut = (e) => {
	    if(e.target.parentNode.classList.contains('option'))
			e.target.parentNode.classList.remove('highlight')
		else if(e.target.parentNode.parentNode.classList.contains('option'))
			e.target.parentNode.parentNode.classList.remove('highlight')
		else
			e.target.classList.remove('highlight')
	}
	*/
	
	const selectPlan = (e, productName, productCode, link) => {
		e.preventDefault()
		console.log(checkoutPayload)
		
		let data = {}
		
		if(productName === 'Starter'){
			// data = {
			// 	quantity : 1,
			// 	product : productName,
			// 	poductCode : productCode,
			// 	link : link.replace('__QTITY__', 1)
			// };
			//setCheckoutPayload(data)
			//setRedirectAfterLoginLink(data.link)

		}else if(productName === 'Basic'){
			data = {
				quantity : nbBasicPrice,
				product : productName,
				poductCode : productCode,
				link : link.replace('__QTITY__', nbBasicPrice)
			};	
			setCheckoutPayload(data)
			setRedirectAfterLoginLink(data.link)	

		}else if(productName === 'Standard'){
			data = {
				quantity : nbStandardPrice,
				product : productName,
				poductCode : productCode,
				link : link.replace('__QTITY__', nbStandardPrice)
			};	
			setCheckoutPayload(data)
			setRedirectAfterLoginLink(data.link)	

		}else if(productName === 'Advanced'){
			data = {
				quantity : nbAdvancedPrice,
				product : productName,
				poductCode : productCode,
				link : link.replace('__QTITY__', nbAdvancedPrice)
			};
			setCheckoutPayload(data)
			setRedirectAfterLoginLink(data.link)

		}
		
		// else if(productName === 'Premium'){
		// 	data = {
		// 		quantity : nbPremiumPrice,
		// 		product : productName,
		// 		poductCode : productCode,
		// 		link : link.replace('__QTITY__', nbPremiumPrice)
		// 	};
		// 	setCheckoutPayload(data)
		// 	setRedirectAfterLoginLink(data.link)
		// }
	
		if(!isAuthenticated){
			handleLoginRedirect(e, instance, link)
	
		}else{
			const userInfo = localStorage.getItem('vxs_user');
			if(userInfo && userInfo !== "undefined"){
				const u = JSON.parse(userInfo)
				u['productName'] = productName
				u['productCode'] = productCode
				localStorage.setItem("vxs_user", JSON.stringify(u));
			}
			setProductName(productName);
		}
	}

	const handleClickOpenFeatured = (index, section) => {
		if(section === 'features') {
			setFeaturedItem(Texts.featured[index])
			setOpenFeatured(true);
		}else{
			setFeaturedItem(Texts.industries[index])
			setOpenFeatured(true);
		}
	};

	const handleCloseFeaturedPopup = () => {
		setOpenFeatured(false)
	}
	
	return(
		// <UnauthenticatedTemplate>
		<div className="App">
			{
			openFeatured ? 
			<>
				<div className="industry"></div>
				<div className="industry-popup">
					<div className="industry-close" onClick={handleCloseFeaturedPopup}><span className="fa fa-close fa-lg"></span></div>
					<div className="title">
						<h4 className="box-title mb-3">Please sign in to authenticate to the Voxel Space.</h4>
					</div>
					<div className="content">
						<div className='col'>Content</div>
					</div>
				</div>
				</>
			:''
			}
		<Header />
		<div className="bodyContainer" id="home">
			<ToastContainer />
			<section className="containerfull design" id="design">
				
				<div className="content">
					
						<div className="text" data-aos="fade-up">
						
								<div className="headline">{Texts.design_to_process_title}</div>
							
							
								<div className="title"><span className="font-weight-700 text-white">process</span>, store, & visualize <span className="font-weight-700 text-white">vast</span> spatial <span className="font-weight-700 text-white">datasets</span></div>
							
							<div className="description text-extra-medium">{Texts.design_to_process_text}</div>
							<div className="btn-startnow bg-white font-weight-600" style={{width:'150px'}}><HashLink className="text-blue no-underline" to="#pricing" smooth>start now</HashLink></div>
						</div>
					
					<div className="image" data-aos="slide-left"><img src={process.env.PUBLIC_URL + "/assets/hero-img-right.png"} alt="Design to process" width="100%"/></div>
				</div>
			</section>
			<section className="containerfull banner" id="banner">
				<div className="content">
					<div className="banners">
						<img src={process.env.PUBLIC_URL + "/assets/clients-colors.jpg"} alt="banners"/>
					</div>
				</div>
			</section>
			
			<section className="containerfull industry" id="industry">
				<div className="content" data-aos="fade-up">
					<div className="banner-title font-weight-200"><span className="font-weight-600">Target</span> Industries</div>
					<div className="subtitle text-extra-medium">Voxel Space is primarily directed at organizations and professionals handling significant volumes of spatial data. These might include:</div>
					<div className="sectors">
						<Slider {...settings}>
						{Texts.industries.map((item, index) => 
						<Link to="/#" key={index} onClick={() => handleClickOpenFeatured(index, "industries")}>
							<div className="sector">
								<div className={item.class}>
									<img src={process.env.PUBLIC_URL + "/assets/" +item.image} alt={item.title}/>
								</div>
								<div className="industry-title font-weight-500 title-medium">{item.title}</div>
								<div className="industry-description text-small" lg={item.description.length}>{item.description.length > MAX_DESC ? item.description.substring(0, MAX_DESC) + '...' : item.description}</div>
								<div className="moreinfos">
									<i className="fa fa-light fa-chevron-right"></i>
								</div>
							</div>
						</Link>
						)}
						</Slider>
					</div>
				</div>
			</section>
			
			<section className="containerfull features" id="features" data-aos="fade-up">
				<div className='content'>
					<div className="title">Our features</div>
					<div className="headline">
						<span className="font-weight-700">becoming</span>
						<span>data centric</span>
					</div>
					<div className="options">
						<>
							<div className="option" onMouseEnter={() => setShowFeatures1(true)} onMouseLeave={() => setShowFeatures1(false)}>
								<HashLink to="/#pricing" smooth>
								<div className="field field1">
									<div className="container-image">
										<div className="image"></div>
									</div>
									<div className="text">
										<div className="title">Large-Scale Data Handling</div>
										<div className="description text-medium">Voxel Space has the capability to handle substantial spatial datasets, spanning millions of square kilometers at high resolution. Whether it's capturing the details of the real world or creating expansive virtual worlds, Voxel Space can store it.</div>
										{
										showFeatures1 ?
										<div className="btn-startnowwhite text-medium">START NOW</div>
										:
										<div className="btn-startnowwhite hide text-medium"></div>
										}
									</div>
								</div>
								</HashLink>
							</div>
							<div className="option" onMouseEnter={() => setShowFeatures2(true)} onMouseLeave={() => setShowFeatures2(false)}>
								<HashLink to="/#pricing" smooth>
								<div className="field field1">
									<div className="container-image">
										<div className="image"></div>
									</div>
									<div className="text">
										<div className="title">Detailed Data Storage</div>
										<div className="description text-medium">The platform can preserve an impressive level of detail, enabling the storage of spatial data at the sub-micrometer scale. For example, it could potentially store a scan of a human body down to the level of individual cells.</div>
										{
										showFeatures2 ?
										<div className="btn-startnowwhite text-medium">START NOW</div>
										:
										<div className="btn-startnowwhite hide text-medium"></div>
										
										}
									</div>
								</div>
								</HashLink>
							</div>
							<div className="option" onMouseEnter={() => setShowFeatures3(true)} onMouseLeave={() => setShowFeatures3(false)}>
								<HashLink to="/#pricing" smooth>
								<div className="field field1">
									<div className="container-image">
										<div className="image"></div>
									</div>
									<div className="text">
										<div className="title">Temporal Data Tracking</div>
										<div className="description text-medium">Recognizing the deep connection between time and space, Voxel Space offers tools to monitor and comprehend how spatial data alters over time.</div>
										{
										showFeatures3 ?
										<div className="btn-startnowwhite text-medium">START NOW</div>
										:
										<div className="btn-startnowwhite hide text-medium"></div>
										}
									</div>
								</div>
								</HashLink>
							</div>
							<div className="option" onMouseEnter={() => setShowFeatures4(true)} onMouseLeave={() => setShowFeatures4(false)}>
								<HashLink to="/#pricing" smooth>
								<div className="field field1">
									<div className="container-image">
										<div className="image"></div>
									</div>
									<div className="text">
										<div className="title">Cloud-Based Service</div>
										<div className="description text-medium">Voxel Space functions as a spatial platform as a service, building on the advantages of cloud providers like Microsoft Azure or Amazon Web Services. It handles server setup and formulates scaling strategies for expanding storage and processing needs.</div>
										{
										showFeatures4 ?
										<div className="btn-startnowwhite text-medium">START NOW</div>
										:
										<div className="btn-startnowwhite hide text-medium"></div>
										}
									</div>
								</div>
								</HashLink>
							</div>
						</>
					</div>
				</div>
			</section>

			<section className="containerfull data" id="learn" data-aos="fade-up">
				<div className="content">
					<div className="text">
						<div className="headline font-weight-700" style={{fontSize:'15px'}}>VOXEL SPACE</div>
						<div className="title"><span className="font-weight-700">Multi-platform </span><br/>visualization</div>
						<div className="subtitle text-extra-medium">{Texts.visualize_subtitle}</div>
						<div className="subtitle text-extra-medium">{Texts.visualize_descriptions}</div>
						<div className="btn-learnmore"><HashLink className="text-white no-underline" to="#pricing" smooth>start now</HashLink></div>
					</div>
					<div className="image"><img src={process.env.PUBLIC_URL + "/assets/section01-img.png"} alt="Design to process" width="90%"/></div>
				</div>
			</section>

			<section className="containerfull parallel" data-aos="fade-up">
				<div className="content">
					<div className="text">
						<div className="headline font-weight-700" style={{fontSize:'15px'}}>VOXEL SPACE</div>
						<div className="title"><span className="font-weight-700">Parallel </span><br/>Processing</div>
							<div className="subtitle text-extra-medium">{Texts.storage_subtitle}</div>
							<div className="subtitle text-extra-medium">{Texts.storage_descriptions}</div>
						
							<div className="btn-learnmore"><HashLink className="text-white no-underline" to="#pricing" smooth>start now</HashLink></div>
					</div>
					<div className="image"><img src={process.env.PUBLIC_URL + "/assets/section02-img.png"} alt="Design to process" width="90%"/></div>
				</div>
			</section>

			<section className="containerfull pricing" id="pricing" data-aos="fade-up">
				<div className="contentfull">
					<div className="text">
						<div className="title"><span className="font-weight-700">pricing </span>plans</div>
					</div>
					<div className="items">

						<div className="item" onMouseEnter={addHighlight} onMouseLeave={removeHighlight}>
							<div className="title font-weight-600">{Texts.pricing_title.free}</div>
							<div className="price"><span className="currency">$</span><span className="price_amount">0</span></div>
							<div className="menu_users">
								1 user
							</div>
							{isAuthenticated ? 
							<div className="btn-learnmore center small"><HashLink to="/myaccount/profile">Start now</HashLink></div>
							:
							<HashLink to="/#" onClick={(e) => handleLoginRedirect(e, instance, null)}><div className="btn-learnmore center small bg-light-blue">Start now</div></HashLink>
							}
							
							<div className="options">
								{Texts.pricing_list.free.map((item,i) => <div key={i} className="option text-extra-medium"><span>{item}</span></div>)}
							</div>
							
						</div>
						
						<div className="item" onMouseEnter={addHighlight} onMouseLeave={removeHighlight}>
							<div className="title font-weight-600">{Texts.pricing_title.basic}</div>
							<div className="price">
								<span className="currency">$</span><span className="price_amount">{totalBasicPrice}</span><span className="price_frequency"> /Month</span>
							</div>
							<div className="menu_users">
								5 users
							</div>
												
							{
							isAuthenticated ? 
								<div className="btn-learnmore center small" onClick={(e) => buynow(e, 'Basic', 'https://secure.2checkout.com/order/checkout.php?PRODS=43276015&QTY=' + nbBasicPrice + '&CART=1&CARD=1&ORDERSTYLE=nLWsm5XfhHU=&DOTEST=1&BACK_REF=' + thankYouUrl + '&CUSTOMERID=' + homeAccountIdHash) }>
									<ClipLoader
										color="fff"
										loading={currentRef === 'Basic'}
										size={10}
										aria-label="Loading Spinner"
										data-testid="loader"
									/>&nbsp;Choose plan</div>
								:
								<div className="btn-learnmore center small" onClick={(e) => selectPlan(e, 'Basic', 'DX054YPJ2J', 'https://secure.2checkout.com/order/checkout.php?PRODS=43276015&QTY=' + nbBasicPrice + '&CART=1&CARD=1&ORDERSTYLE=nLWsm5XfhHU=&DOTEST=1')}>
									<ClipLoader
										color="fff"
										loading={currentRef === 'Basic'}
										size={10}
										aria-label="Loading Spinner"
										data-testid="loader"
									/>&nbsp;Choose plan</div>
							}

							<div className="options">
							{Texts.pricing_list.basic.map((item,i) => <div key={i} className="option text-extra-medium"><span>{item}</span></div>)}
							</div>
						</div>

						<div className="item" onMouseEnter={addHighlight} onMouseLeave={removeHighlight}>
							<div className="title font-weight-600">{Texts.pricing_title.standard}</div>
							<div className="price">
								<span className="currency">$</span><span className="price_amount">{totalStandardPrice}</span><span className="price_frequency"> /Month</span>
							</div>
							<div className="menu_users">
								10 users
							</div>
							{						
							isAuthenticated ? 
								<div className="btn-learnmore center small" onClick={(e) => buynow(e, 'Standard', 'https://secure.2checkout.com/order/checkout.php?PRODS=42313520&QTY=' + nbStandardPrice + '&CART=1&CARD=1&ORDERSTYLE=nLWsm5XfhHU=&DOTEST=1&BACK_REF=' + thankYouUrl + '&CUSTOMERID=' + homeAccountIdHash) }>
									<ClipLoader
										color="fff"
										loading={currentRef === 'Standard'}
										size={10}
										aria-label="Loading Spinner"
										data-testid="loader"
									/>
									&nbsp;Choose plan</div>
								:
								<div className="btn-learnmore center small" onClick={(e) => selectPlan(e, 'Standard', '3NFG061UN6', 'https://secure.2checkout.com/order/checkout.php?PRODS=42313520&QTY=' + nbStandardPrice + '&CART=1&CARD=1&ORDERSTYLE=nLWsm5XfhHU=&DOTEST=1')}>
									<ClipLoader
										color="fff"
										loading={currentRef === 'Standard'}
										size={10}
										aria-label="Loading Spinner"
										data-testid="loader"
									/>
									&nbsp;Choose plan</div>
							}

							<div className="options">
							{Texts.pricing_list.standard.map((item,i) => <div key={i} className="option text-extra-medium"><span>{item}</span></div>)}
							</div>
							
						</div>

						<div className="item popular highlight" onMouseEnter={addHighlight} onMouseLeave={removeHighlight}>
							<div className="ribbon ribbon-top-right"><span>Most Popular</span></div>
							<div className="title font-weight-600">{Texts.pricing_title.advanced}</div>
							<div className="price">
								<span className="currency">$</span><span className="price_amount">{totalAdvancedPrice}</span><span className="price_frequency"> /Month</span>
							</div>
							<div className="menu_users">
								15 users
							</div>
							{
								isAuthenticated ? 
								<div className="btn-learnmore center small" onClick={(e) => buynow(e, 'Advanced', 'https://secure.2checkout.com/order/checkout.php?PRODS=42313550&QTY=' + nbAdvancedPrice + '&CART=1&CARD=1&ORDERSTYLE=nLWsm5XfhHU=&DOTEST=1&BACK_REF=' + thankYouUrl + '&CUSTOMERID=' + homeAccountIdHash)}>
									<ClipLoader
										color="fff"
										loading={currentRef === 'Advanced'}
										size={10}
										aria-label="Loading Spinner"
										data-testid="loader"
									/>
									&nbsp;Choose plan</div>
								:
								<div className="btn-learnmore center small" onClick={(e) => selectPlan(e, 'Advanced', 'DQNG4MNDNZ', 'https://secure.2checkout.com/order/checkout.php?PRODS=42313550&QTY=' + nbAdvancedPrice + '&CART=1&CARD=1&ORDERSTYLE=nLWsm5XfhHU=&DOTEST=1')}>
									<ClipLoader
										color="fff"
										loading={currentRef === 'Advanced'}
										size={10}
										aria-label="Loading Spinner"
										data-testid="loader"
									/>
									&nbsp;Choose plan</div>
							}

							<div className="options">
							{Texts.pricing_list.advanced.map((item,i) => <div key={i} className="option text-extra-medium"><span>{item}</span></div>)}
							</div>
							
						</div>

						<div className="item popular highlight" onMouseEnter={addHighlight} onMouseLeave={removeHighlight}>
							<div className="title font-weight-600">{Texts.pricing_title.premium}</div>
							<div className="price"></div>			
							<div className="menu_users">
								More users
							</div>
							<HashLink to="/contact"><div className="btn-learnmore center small bg-light-blue" style={{ cursor:'pointer' }}>Contact Us</div></HashLink>
							<div className="options">
							<div className="option text-extra-medium"><span>More users</span></div>
							{Texts.pricing_list.premium.map((item,i) => <div key={i} className="option text-extra-medium"><span>{item}</span></div>)}
							</div>
							
						</div>
					</div>
				</div>
			</section>	

			<section className="containerfull newsletter" id="newsletter" data-aos="fade-up">
				<div className="contentfull">
					<div className="text title-large">Join the mailing list and receive updates</div>
					<div className="field">
						<div className="form-row align-items-center">
							<div className="col-auto">
								<label className="sr-only" htmlFor="inlineFormInputGroup">Email</label>
								<div className="input-group mb-2">
									<input type="text" className="form-control" id="email-newsletter" value={email} placeholder="Email Address" onChange={handleEmail} onKeyUp={handleKeyPress}/>
									<div className="input-group-prepend">
										<div className="input-group-text" onClick={sendEmail}><span className="fa fa-regular fa-envelope fa-lg" style={{ lineHeight: '1.75em' }}></span></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>		
		</div>
		<React.Fragment>
			<BootstrapDialog
			TransitionComponent={Transition}
			fullWidth={true}
        	maxWidth={'lg'}
			onClose={handleCloseFeaturedPopup}
			aria-labelledby="customized-dialog-title"
			open={openFeatured}
			>
				<IconButton
				size="large"
				aria-label="close"
				onClick={handleCloseFeaturedPopup}
				sx={{
					position: 'absolute',
					right: 45,
					top: 45,
					color: (theme) => theme.palette.grey[500],
				}}
				>
				<div class="login-close"></div>
				</IconButton>
				<DialogContent dividers style={{ overflow: "hidden"}}>
					<Typography gutterBottom >
							<div style={{display:'flex', padding: '0px', fontFamily: 'Poppins, sans-serif'}} >
								<div className={"industrie-image " + featuredItem.sideimage}></div>							
								<div className="content" style={{ padding:'80px'}}>
									<div className="font-weight-500 text-light-blue" style={{letterSpacing:'3px', marginBottom:'8px'}}>TARGET INDUSTRIES</div>
									<div className="font-weight-700 title-large-2" style={{marginBottom:'15px', textTransform:'uppercase'}}>{featuredItem.title}</div>
									<PerfectScrollbar onSync={handleSync}>
										<div style={{padding:'0px 20px 0 0', maxHeight: '480px'}}>					
											<div className="font-weight-600 text-extra-large" style={{margin: '30px 0'}}>{featuredItem.description}</div>
											{
												featuredItem.paragraphs.map((item, i) => 
													<div className="paragraph-popup" key={i}>{item}</div>
												)
											}
											<span style={{ display:'block', minHeight:'150px' }}></span>
										</div>
									</PerfectScrollbar>			
								</div>
							</div>	
					</Typography>
				</DialogContent>		
      		</BootstrapDialog>
	   	</React.Fragment>
		<Footer data-aos="fade-up"/>
		</div>
		// </UnauthenticatedTemplate>
	)
}

export default Home