import React, {useEffect} from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header';

const Privacy = () => {

    useEffect(() => {
        window.scrollTo({
            top:0,
            behavior: 'instant'
        });     
    },[]);
    
    return (
    <div className="App">
		<Header />
		<div className="bodyContainer">
            <section className="containerfull privacy">
                <div className="content">
                    <div className="text">
                        <div className="headline">VOXEL SPACE</div>
                        <div className="title ">
                            OUR<span className="font-weight-700"> PRIVACY </span>POLICY
                        </div>
                    </div> 

                    <div className="text-medium">
                    <p>
                    Version date: December 30, 2023
                    </p>
<p>
Voxel Space is dedicated to upholding responsible privacy practices and adhering to the Privacy Principles outlined in the Privacy Act 1988 (Cth) (“Privacy Act”) to the extent applicable to our operations.
</p>
<p>
This Privacy Policy articulates our approach to managing personal information, encompassing the collection process, the purposes for utilizing this information, and the entities with whom this information may be shared.
</p>
<p>
Our Privacy Policy may undergo periodic changes or updates. The most recent version is accessible on our website at www.voxelspace.com at all times.
</p>
<p>
<b className="font-weight-600 title-medium-2">Definition of Personal Information</b><br/>
</p>
<p>
In this Privacy Policy, the term "personal information" carries the definition outlined in the Privacy Act. Essentially, it refers to information or opinions about an individual who is reasonably identifiable.
</p>
<p>
<b className="font-weight-600 title-medium-2">Types of Personal Information Collected</b><br/>
</p>
<p>
The personal information we collect depends on the circumstances surrounding its acquisition. This may include contact details (such as name, address, email, phone numbers, and date of birth/age), responses to inquiries, and information related to employment applications or contractor engagements. 
</p>
<p>
<b className="font-weight-600 title-medium-2">Methods of Personal Information Collection</b><br/>
</p>
<p>
Personal information is gathered through various channels, including direct interactions with you or our agents, online transactions, publicly available sources, related companies, and third parties, such as referees for employment applications.
</p>
<p>
<b className="font-weight-600 title-medium-2">Purposes of Collecting, Using, and Disclosing Personal Information</b><br/>
</p>
<p>
We collect, use, and disclose personal information for various specific purposes, including but not limited to:
</p>
<p>
<ul style={{marginLeft:"20px",listStyleType:'disc'}}>
    <li>responding to your information requests (whether through our websites, email, or other correspondence);</li>
    <li>providing goods or services to you or receiving goods or services from you;</li>
    <li>administering rewards, surveys, competitions, or other promotional activities or events, whether conducted, sponsored, or managed by us or our business partners;</li>
    <li>enabling your use of our apps and other downloaded applications;</li>
    <li>facilitating your participation in any loyalty or incentive programs we operate;</li>
    <li>improving the quality of our goods and services;</li>
    <li>verifying your identity, address, age, or eligibility to participate in marketing activities;</li>
    <li>considering your application for a job with us (whether as an employee or contractor) or in the context of other relationships with us;</li>
    <li>addressing any issues or complaints arising in our relationship with you; and</li>
    <li>contacting you in connection with the aforementioned purposes, including through electronic messaging such as SMS and email, by mail, by phone, or via any other lawful means.</li>
</ul>
</p>
<p>
<b className="font-weight-600 title-medium-2">Direct Marketing</b><br/>
</p>
<p>
Direct marketing activities, aimed at promoting goods or services and offering special deals, may involve communication through mail, telephone, email, or SMS. You have the option to unsubscribe from direct marketing or modify your contact preferences by reaching out to us.   
</p>
<p>
<b className="font-weight-600 title-medium-2">Impact of Withholding Personal Information</b><br/>
</p>
<p>
While you are generally not obligated to provide requested personal information, choosing not to do so may impede our ability to deliver requested products and services or involve you in marketing activities dependent on such information.    
</p>
<p>
<b className="font-weight-600 title-medium-2">Disclosure of Personal Information to Third Parties</b><br/>
</p>
<p>
We may share personal information with third parties for purposes outlined in this Privacy Policy. These parties may include employees, contractors, service providers, related companies, and entities authorized or required by law.  
</p>
<p>
This may involve revealing your personal information to various categories of third parties, such as: 
</p>
<p>
<b className="font-weight-600 title-medium-2">Data deletion instructions</b><br/>
</p>
<p>
    <ul style={{marginLeft:"20px",listStyleType:'disc'}}>
        <li>our staff and affiliated entities;</li>
        <li>contractors and other third-party entities providing goods and services to us (including website and data hosting providers, as well as other suppliers);</li>
        <li>accountants, insurers, lawyers, auditors, and other professional advisers and agents;</li>
        <li>operators of payment systems;</li>
        <li>in the case of an individual contractor or prospective employee, our related companies and human resources service providers (e.g., for outsourced payroll processing);</li>
        <li>any third parties to whom you have explicitly directed or permitted us to disclose your personal information (e.g., referees);</li>
        <li>in the improbable event of our assets or us being considered for acquisition by a third party, that third party and its advisors;</li>
        <li>third parties requiring the information for law enforcement or to prevent a serious threat to public safety; and</li>
        <li>otherwise, as permitted or mandated by law.</li>
    </ul>
</p>
<p>
<b className="font-weight-600 title-medium-2">Security Measures for Personal Information</b><br/>
</p>
<p>
We take reasonable measures to safeguard your personal information, although we exclude liability for consequences resulting from unauthorized access, disclosure, misuse, or loss. Notify us promptly if you become aware of any security breaches.
</p>
 
<p>
<b className="font-weight-600 title-medium-2">Accuracy of Held Personal Information</b><br/>
</p>
<p>
Efforts are made to maintain accurate personal information. If you identify inaccuracies or changes, please contact us to update your details.
</p>
<p>
<b className="font-weight-600 title-medium-2">Links, Cookies, and Website/Application Usage</b><br/>
</p>
<p>
This Privacy Policy pertains to our website, and not linked sites beyond our control. We may use cookies and similar technologies to enhance website/application functionality, monitor effectiveness, and improve user experience.
</p>
<p>
<b className="font-weight-600 title-medium-2">Accessing and Correcting Personal Information</b><br/>
</p>
<p>
You can request access to your personal information by contacting us. We will comply with Privacy Act provisions, with potential exemptions and administration fees applied.
</p>
<p>
<b className="font-weight-600 title-medium-2">Queries, Comments, and Complaints:</b><br/>
</p>
<p>
For questions, comments, or complaints about personal information handling, contact us. We take privacy complaints seriously, aiming for timely and efficient resolution. If dissatisfied, you may contact the Office of the Australian Information Commissioner.
</p>
<p>
<b className="font-weight-600 title-medium-2">Contact Information</b><br/>
</p>
<p>
Privacy-related communications can be directed to: Voxel Space, 6200 Bldv Taschereau #305, Brossard, Quebec, Canada, J4W 3J8 OR contact@voxelspace.com
</p>

<p>
<b className="font-weight-600 title-medium-2">Privacy Collection Statement</b><br/>
</p>
<p>
Voxel Space collects personal information to deliver services and may obtain information from third parties. By providing your details, you consent to communication from us. Failure to provide requested information may hinder request processing. Personal information may be disclosed to third parties aiding in product/service provision. Refer to our Privacy Policy for details on access, correction, and complaint procedures. Contact us at contact@voxelspace.com.
</p>

                    </div>           
                </div>
			</section>
        </div>
        <Footer/>
    </div>
  )
}

export default Privacy