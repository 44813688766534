import React, {useState, useEffect} from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useContext} from 'react'
import { Context } from '../components/Context'

const ForgotPassword = () => {
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [show, setShow] = useState(false)
    const [token, setToken] = useState('');
    const {setLoginSigninDialog, setLoginDialog, setSigninDialog, setForgotPasswordDialog} = useContext(Context);

    useEffect(() => {
        const userInfo = localStorage.getItem('vxs_user');
		if(userInfo && userInfo !== "undefined"){
            const u = JSON.parse(userInfo)
            setToken(u.token)
        }
        window.scrollTo(0, 0)
    }, [token])

    const handleEmail = (event) => {
        setEmail(event.target.value)
        if (event.target.value.length < 5  || 
            event.target.value.split("").filter(x => x === "@").length !== 1 || event.target.value.indexOf(".") === -1) {
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
        }
    }

    const handleLoginClick = (e) => {
		e.preventDefault();
		setLoginSigninDialog(true)
		setLoginDialog(false)
		setSigninDialog(false)
		setForgotPasswordDialog(true)
	}

    const handleSubmit = (e) => {
        e.preventDefault();
        const emailElem = document.querySelector('#email');
        if(emailElem.classList.contains('is-invalid') || email.length === 0){
            emailElem.classList.remove('is-valid');
            emailElem.classList.add('is-invalid');
            return false;
        }

        const url = process.env.REACT_APP_BASE_URL + '/user/forgot-password';
        fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method : "POST",
            body : JSON.stringify({email : email, token : token, key_id : process.env.REACT_APP_BASE_APIKEY})
        })
        .then(response => response.json())
        .then(response => {
            if(response.action === "failed"){
                toast.error(response.msg, {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored"
                });
                return false;
            }else{
                setEmail('')
                setMessage(response.msg)
                setShow(true)
                // toast.success(response.msg, {
                //     position: toast.POSITION.TOP_CENTER,
                //     theme: "colored"
                // });
            }
        })
        .catch(err => {
            console.log(err);
        });
    }


    return (
        <div>
            <ToastContainer autoClose={false}/>
            <Header/>
            <div className="page-content container-fluid">
                <div className="row justify-content-center">
                    <div style={{ maxWidth:'600px' }}>
                        <h1>Forgot Password</h1>
                        <div className="card spaceTopBottom max-width-720" style={{ minHeight: '150px' }}>
                            {show ? 
                            <div className="card-body">
                                <div className="font-weight-600 text-large">{message}</div>
                                <br/>
                                <div className='text-small'>If you haven't received an email in 5 minutes, check your spam, <a href="/#" onClick={(e) => handleLoginClick(e)}>resend</a>, or try a different email.</div>                               
                            </div>
                            : ''
                            }
                            {!show ? 
                            <div className="card-body">
                                <div className="card-title"><h1>Recover password</h1></div>
                                    <form>
                                    <div className="mb-3 form-group required">
                                        <input type="text" className="form-control" id="email" placeholder="Email" aria-describedby="emailHelp" value={email} onChange={handleEmail}/>
                                        <div className="invalid-feedback">Please provide with a valid Email </div>
                                        <div className="valid-feedback"></div>
                                    </div>
                                    <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Submit</button>
                                </form>
                            </div>
                            : ''
                            }
                        </div>
                    </div>
                </div>
            </div> 
            <Footer />
        </div>
    );
}

export default ForgotPassword;
