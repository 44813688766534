import React, {useEffect, useState} from 'react';
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react"
import { InteractionType } from "@azure/msal-browser";

//import Header from '../components/Header';
import Aside from '../components/Aside';
import HeaderPlatform from '../components/HeaderPlatform';
import Footer from '../components/Footer';

const MyAccountProfile = () => {
    // const isAuthenticated = useIsAuthenticated();
    // console.log("Isauthenticated : " + isAuthenticated)

    const { instance } = useMsal();

    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [email, setEmail] = useState('')
     
	useEffect(() => {
        window.scrollTo(0, 0)

        const activeAccount = instance.getActiveAccount();
        if(activeAccount !== null){
            setEmail(activeAccount.username)
            const names = typeof activeAccount.name !== "undefined" ? activeAccount.name.split(' ') : []
            setFirstname(names[0])
            setLastname(names[1])
        }
    }, [instance])

    // const handleFirstName = (e) => {
    //     e.preventDefault();
    //     setShowFirstNameInput(true)
    // }

    // const handleLastName = (e) => {
    //     e.preventDefault();
    //     setShowLastNameInput(true)
    // }

    // const handlePassword = (e) => {
    //     e.preventDefault();
    //     setShowPasswordInput(true)
    // }

    // const save = (e) => {
    //     setShowFirstNameInput(false)
    //     setShowLastNameInput(false)
    //     setShowPasswordInput(false)

    //     const endpoint = process.env.REACT_APP_BASE_URL + '/user/profile/update';
    //     fetch(endpoint, {
    //         headers: {
    //             'Accept': 'application/json',
    //             'Content-Type': 'application/json'
    //         },
    //         "method": "POST",
    //         "body": JSON.stringify({firstname : firstname, lastname : lastname, password : password, key_id : process.env.REACT_APP_BASE_APIKEY})
    //     })
    //     .then(response => response.json())
    //     .then(response => {     
    //         if(response.error === "true"){
               
    //         }else{
    //             //populate(uid,token)
              
    //         }
    //     })
    //     .catch(err => {
    //         console.log(err);
    //     });
    // }

    /*
    const populate = (uid, token, email) => {
        const endpoint = process.env.REACT_APP_BASE_URL + '/user/profil';
        fetch(endpoint, {

            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            "method": "POST",
            "body": JSON.stringify({uid : uid, email: email, token : token, key_id : process.env.REACT_APP_BASE_APIKEY})
        })
        .then(response => response.json())
        .then(response => { 
            
            if(response.error === false){
                setFirstname(response.data[0].firstname)
                setLastname(response.data[0].lastname)
                setEmail(response.data[0].email)
                return false;
            }else{
                //setUser(response)
                return true;
            }
        })
        .catch(err => {
            console.log(err);
        });
    }
	*/
    
    const authRequest = {
        scopes: ["openid", "profile"]
    };

  	return (
        <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={authRequest} 
        >
		<div>        
            <div className="page-profile">
                <Aside selected="profile"/>
                <div className="container-profile">
                    <HeaderPlatform />
                    <div className="text">
                        <div className="breadcrumbs"><span>My Account</span></div>
                        <div className="title">
                            <span className="font-weight-700 title-large-2"> Profile</span>
                        </div>
                        <section>
                            {/* <div className="blue-subtitle">USER DETAILS</div> */}
                            <div className="form text-large">
                                <div className="info">
                                    <div className="label">First Name</div>
                                    <div className="val">{firstname}</div>
                                    <div></div>
                                </div>
                                <div className="separator"></div>
                                <div className="info">
                                    <div className="label">Last Name</div>
                                   
                                        <div className="val">{lastname}</div>
                                        <div></div>
                                  
                                </div>
                                <div className="separator"></div>
                                <div className="info">
                                    <div className="label">Email</div>
                                    <div className="val">{email}</div>
                                    <div></div>
                                </div>
                                {/* <div className="separator"></div>

                                <div className="info">
                                    <div className="label">Password</div>
                                    {showPasswordInput ?
                                    <>
                                        <div className="val">
                                            <input type="password" className="form-control" id="password" value={password} onChange={(e) => setPassword(e.target.value)}/>
                                        </div>
                                        <div><button type="button" className="btn btn-sm text-medium btn-border-radius-square" onClick={(e) => save(e)}>Save</button></div>
                                    </>
                                    :
                                    <>
                                        <div className="val">*******************</div>
                                        <div className="val edit" onClick={(e) => handlePassword(e)}><i className="fa fa-edit"></i></div>
                                    </>
                                    }
                                </div> */}
                            </div>
                        </section>                       
                    </div> 
                </div>
            </div>
            <Footer small="true"/>
	    </div>
        </MsalAuthenticationTemplate>
    );
}

export default MyAccountProfile;
