import React, {useEffect} from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header';

const Tutorials = () => {

    useEffect(() => {
        window.scrollTo(0, 0)     
    },[]);
    
    return (
    <div className="App">
		<Header />
		<div className="bodyContainer">
            <section className="containerfull tutorials">
                <div className="content">
                    <div className="text">
                        <span className="font-weight-700 title-large-2">TUTORIALS</span> <span className="title-medium-2" style={{color:'#52B0CC'}}>RESOURCES</span>
                    </div> 

                    <div className="videos">
                        <div className="video">
                            <iframe className="youtube-video" src="https://www.youtube.com/embed/G-j_GzdkP3A?si=vepmSHA_eZIiuFnk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                        {/* <div className="video">
                        <iframe
                            title="Youtube"
                            aria-hidden="true"
                             frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            webkitallowfullscreen="true"
                            mozallowfullscreen="true"
                            srcDoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/KZIsW7is01g/?autoplay=1><img src=https://img.youtube.com/vi/KZIsW7is01g/hqdefault.jpg alt='Video The Dark Knight Rises: What Went Wrong? – Wisecrack Edition'><span>▶</span></a>`}
                            ></iframe>
                        </div> */}

                        <div className="video">
                        <iframe className="youtube-video" src="https://www.youtube.com/embed/KZIsW7is01g?si=hWKvJQqVeo5LbKcT" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                        <div className="video">
                        <iframe className="youtube-video" src="https://www.youtube.com/embed/vef6GZom1Fk?si=_1iBTAWqezCr3j6Z" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                        <div className="video">
                        <iframe className="youtube-video" src="https://www.youtube.com/embed/wgMjbB8zrBw?si=lKDjvPL2MT_AkWH8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                        <div className="video">
                        <iframe className="youtube-video" src="https://www.youtube.com/embed/BkX2guBWDMo?si=h1HeRyplwkjMq5VR" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                        <div className="video">
                        <iframe className="youtube-video" src="https://www.youtube.com/embed/VWTebJch0B8?si=On9p-dLQCbKvji9x" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                        <div className='video'>
                        <iframe className="youtube-video" src="https://www.youtube.com/embed/nzr21av5JQc?si=cp96e2w-UBT1btRr" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                        <div className='video'>
                        <iframe className="youtube-video" src="https://www.youtube.com/embed/N3YWH3DBvuw?si=aopOMaPKDbbt_VVW" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                        <div className='video'>
                        <iframe className="youtube-video" src="https://www.youtube.com/embed/4fRIQLlJEJ0?si=4LdG6uXk2AUnbc0h" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                        <div className='video'>
                        <iframe className="youtube-video" src="https://www.youtube.com/embed/9xZTk2hDAEQ?si=yUekEDthe4IbvQov" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>   
			</section>
        </div>
        <Footer/>
    </div>
  )
}

export default Tutorials;