import React, {useState, useEffect} from 'react'
import { useNavigate } from "react-router-dom";
import Header from '../components/Header';
import Footer from '../components/Footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useContext} from 'react'
import { Context } from '../components/Context';

const MyaccountEditPassword = () => {
	
    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [retypePassword, setRetypePassword] = useState('')
    const {setIsLogged, setUser} = useContext(Context);
    //const [isLogged, setIsLogged] = useState('');
    const [uid, setUid] = useState('');
    const [token, setToken] = useState('');

    const navigate = useNavigate()

    useEffect(() => {
        const userInfo = localStorage.getItem('vxs_user');
        if(userInfo && userInfo !== "undefined"){
            const u = JSON.parse(userInfo)
			setToken(u.token)
            setUid(u.uid)
            //setIsLogged(true)
        }
        window.scrollTo(0, 0)
    }, [])


    const handleCurrentPassword = (event) => {
        setCurrentPassword(event.target.value)
        if (event.target.value.length < 5){   
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
        }
    }
	
    const handleNewPassword = (event) => {
        setNewPassword(event.target.value)
        if (event.target.value.length < 5){   
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
        }
    }

    const handleRetypePassword = (event) => {
        setRetypePassword(event.target.value)
        if (event.target.value.length < 5 || event.target.value !== newPassword) {
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
        }
    }
  
 
    const logout = () => {
		setIsLogged(false)
        setUser(null)
        localStorage.removeItem('vxs_user')
        navigate('/login')
	}

    const handleSumit = (e) => {
   
        e.preventDefault();
    
        const currentPasswordElem = document.querySelector('#currentPassword');
        const newPasswordElem = document.querySelector('#newPassword');
        const retypePasswordElem = document.querySelector('#retypePassword');

        if(currentPasswordElem.classList.contains('is-invalid') || currentPasswordElem.length === 0){
            currentPasswordElem.classList.remove('is-valid');
            currentPasswordElem.classList.add('is-invalid');
            return false;
        }

        if(newPasswordElem.classList.contains('is-invalid') || newPasswordElem.length === 0){
            newPasswordElem.classList.remove('is-valid');
            newPasswordElem.classList.add('is-invalid');
            return false;
        }
        if(retypePasswordElem.classList.contains('is-invalid') || retypePassword.length === 0){
            retypePasswordElem.classList.remove('is-valid');
            retypePasswordElem.classList.add('is-invalid');
            return false;
        }
      
        const data = {
            currentPassword : currentPassword,
            newPassword : newPassword,
            retypePassword : retypePassword,
            uid : uid,
            token : token,
            key_id : process.env.REACT_APP_BASE_APIKEY
        };

        const endpoint = process.env.REACT_APP_BASE_URL + '/user/password/edit';

        fetch(endpoint, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            "method": "POST",
            "body": JSON.stringify(data)
        })
        .then(response => response.json())
        .then(response => {     
            
            if(response.action === "failed"){
                toast.error(response.msg, {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored"
                });
                return false;
            }else{
                toast.success(response.msg, {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored"
                });

                setTimeout(() => {
                    logout()
                }, 6000);
            }
        })
        .catch(err => {
            console.log(err);
        });
    }
	
  return (
    <div className='app'>
        <ToastContainer />
        <Header/>
		<div className="page-content container-fluid">
            <div className="row justify-content-center">
                <div className="col">
                    <div className="spaceTopBottom shadow_around max-width-720" style={{ padding: '20px', borderRadius: '10px'}}>
                        <div className="title">
                            <h3 className="box-title mb-3">Change Password</h3>
                        </div>
                        <div>
                            <form>
                               
                                <div className="mb-3 form-group required">
                                    <label htmlFor="currentPassword" className="form-label">Current Password</label>
                                    <input type="password" className="form-control" id="currentPassword" autoComplete='on' value={currentPassword} onChange={handleCurrentPassword}/>
                                    <div className="invalid-feedback">Please provide with a valid Password (min 5 characters) </div>
                                    <div className="valid-feedback"></div>
                                </div>
                                <div className="mb-3 form-group required">
                                    <label htmlFor="newPassword" className="form-label">New Password</label>
                                    <input type="password" className="form-control" id="newPassword" autoComplete='on' value={newPassword} onChange={handleNewPassword}/>
                                    <div className="invalid-feedback">Please provide with a valid Password (min 5 characters) </div>
                                    <div className="valid-feedback"></div>
                                </div>
                                <div className="mb-3 form-group required">
                                    <label htmlFor="retypePassword" className="form-label">Retype Password</label>
                                    <input type="password" className="form-control" id="retypePassword" autoComplete='on' value={retypePassword} onChange={handleRetypePassword}/>
                                    <div className="invalid-feedback">The passwords should match </div>
                                    <div className="valid-feedback"></div>
                                </div>
                                <button type="submit" className="btn btn-primary" onClick={handleSumit}>Update</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        <Footer />
    </div>
  );
}

export default MyaccountEditPassword;
