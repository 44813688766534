const Texts = {
    "design_to_process_title" : "A COMPREHENSIVE PLATFORM TO",
    "design_to_process_text" : "It takes on the complexities of managing spatial data and provides tools for users to understand how their data evolves across time and space.",
    "target_mining_title" : "Mining",
    "target_mining_text" : "Entities that require large-scale spatial data, such as geographical information systems (GIS) and mining companies",
    "target_medical_title" : "Medical",
    "target_medical_text" : "Game developers and creators of virtual worlds can utilize Voxel Space to store and manage their complex spatial data.",
    "target_engineering_title" : "Engineering",
    "target_engineering_text" : "Game developers and creators of virtual worlds can utilize Voxel Space to store and manage their complex spatial data.",
    "target_construction_title" : "Construction",
    "target_construction_text" : "Entities that require large-scale spatial data, such as geographical information systems (GIS) and mining companies",
    "target_architecture_title" : "Architecture",
    "target_architecture_text" : "Entities that require large-scale spatial data, such as geographical information systems (GIS) and mining companies",
    "target_environment_title" : "Environment",
    "target_environment_text" : "Professionals working with large-scale, high-resolution spatial datasets for research or analysis, in areas like urban planning.",
    "target_entertainment_title" : "Entertainment",
    "target_entertainment_text" : "Professionals working with large-scale, high-resolution spatial datasets for research or analysis, in areas like urban planning.",
    "target_oilandgas_title" : "Oil and Gas",
    "target_oilandgas_text" : "Entities that require large-scale spatial data, such as geographical information systems (GIS) and mining companies",
    "feature_section_1_title" : "Large-Scale Data Handling",
    "feature_section_1_text" : "Whether it's capturing the details of the real world or creating expansive virtual worlds, Voxel Space can store it.",
    "feature_section_2_title" : "Detailed Data Storage",
    "feature_section_2_text" : "Our platform can preserve an impressive level of detail, enabling the storage of spatial data at the sub-micrometer scale.",
    "feature_section_3_title" : "Temporal Data Tracking",
    "feature_section_3_text" : "Recognizing the deep connection between time and space, we offers tools to comprehend how spatial data alters over time.",
    "feature_section_4_title" : "Cloud-Based Service",
    "feature_section_4_text" : "Our platform can preserve an impressive level of detail, enabling the storage of spatial data at the sub-micrometer scale.",
    "visualize_subtitle" : "Visualize Spatial data across multiple rendering systems:",
    "visualize_descriptions" : "Web-based systems and applications developed with cutting-edge rendering engines like Unity3D, Unreal Engine 4, and UNIGINE",
    "storage_subtitle" : "The platform can preserve an impressive level of detail.",
    "storage_descriptions" : "It enables the storage of spatial data at the sub-micrometer scale. For example, it could potentially store a scan of a human body down to the level of individual cells.",
    "pricing_title" : {
        "free" : "free",
        "starter" : "starter",
        "basic" : "basic",
        "standard" : "standard",
        "advanced" : "advanced",
        "premium" : "premium",
    },
    "pricing_user_per_month" : {
        "free" : "Per Month",
        "starter" : "Per Month",
        "standard" : "Per Month",
        "advanced" : "Per Month",
        "premium" : "Per Month",
    },
    "pricing_team" : {
        "free" : "For small teams",
        "starter" : "For small teams",
        "standard" : "The supporting team",
        "advanced" : "For large business",
        "premium" : "For large business",
    },
    "pricing_use" : {
        "free" : "For personal use includes basic map design",
        "starter" : "For personal use includes basic map design",
        "standard" : "For basic business use Reccomended for managers and the supporting team",
        "advanced" : "For advanced business use",
        "premium" : "For advanced business use",
    },
    "pricing_list" : {
        "free" : [
            "1 user",
            "Unlimited Used Per Location",
            "EMR With E-Record Creator",
            "Client Management",
            "Client Portal",
            "Photo & Document Uploads",
            "Online Scheduling",
            "2-way Appointment Confirmation",
            "Calendar & Resource Management",
        ],
        "starter" : [
            "1 user",
            "Unlimited Used Per Location",
            "EMR With E-Record Creator",
            "Client Management",
            "Client Portal",
            "Photo & Document Uploads",
            "Online Scheduling",
            "2-way Appointment Confirmation",
            "Calendar & Resource Management",
        ],
        "basic" : [
            "5 users",
            "Unlimited Used Per Location",
            "EMR With E-Record Creator",
            "Client Management",
            "Client Portal",
            "Photo & Document Uploads",
            "Online Scheduling",
            "2-way Appointment Confirmation",
            "Calendar & Resource Management",
        ],
        "standard" : [
            "10 users",
            "Unlimited Used Per Location",
            "EMR With E-Record Creator",
            "Client Management",
            "Client Portal",
            "Photo & Document Uploads",
            "Online Scheduling",
            "2-way Appointment Confirmation",
            "Calendar & Resource Management",
        ],
        "advanced" : [
            "15 users",
            "Unlimited Used Per Location",
            "EMR With E-Record Creator",
            "Client Management",
            "Client Portal",
            "Photo & Document Uploads",
            "Online Scheduling",
            "2-way Appointment Confirmation",
            "Calendar & Resource Management",
        ],
        "premium" : [
            "Unlimited Used Per Location",
            "EMR With E-Record Creator",
            "Client Management",
            "Client Portal",
            "Photo & Document Uploads",
            "Online Scheduling",
            "2-way Appointment Confirmation",
            "Calendar & Resource Management",
        ],
    },
    "faqs" : {
       
        "product" : [
            {
                "question" : "Is there a free or evaluation version?",
                "answer" : "Yes, you can get a free trial for Voxel Farm INDIE here."
            },
            {
                "question" : "Can I import my creations into Unity and other engines with the CREATOR license?",
                "answer" : "CREATOR includes the Voxel Studio tool, which allows creating procedural and voxel content. At any time you may choose to export a scene as an FBX model. This FBX can be loaded into any application that supports them, including Unity, UE4, 3ds max, and Maya. FBX models, however, are static. Voxel Farm allows having adaptive scenes, where more detail is loaded or computed on-the-fly based on the camera position. In that sense, a single Voxel Studio project contains almost infinite possible scenes. Unity, UE4, Maya, etc. do not offer a file standard that can do something like that. To be able to position the camera anywhere you want and have the scene resolution adapt to that, you will need the Voxel Farm runtime running in these applications as a plugin (Unity plugin, 3ds max plugin, etc.) The Voxel Farm runtime is included only in INDIE and PRO licenses. For that reason, if you are looking to have dynamic content on these third-party application, you will need INDIE or PRO."
            },
            {
                "question" : "What Operating Systems and Platforms are supported by the Unity plugin?",
                "answer" : "Voxel Farm's plugin uses a non-managed DLL. For that reason, the out-of-the-box plugin runs only on Windows OS. The PRO license offers full source code, so it is possible to rebuild the plugin for other platforms that support application extensions in the form of DLLs like MacOS and Linux. Android and iOS are not supported at the moment."
            },
            {
                "question" : "Can I use Voxel Farm from Unreal Engine 4?",
                "answer" : "Yes, both engines can be combined since Voxel Farm is natively written in C++. We have just released a UE4 plugin that makes starting new projects simpler and quicker."
            },
            {
                "question" : "What are the minimum specs to run Voxel Studio?",
                "answer" : "",
                "list" : [
                    "Windows 10",
                    "Intel i5 CPU or better",
                    "4 GB RAM",
                    "Dedicated Nvidia or AMD/ATI GPU",
                    "700MB hard drive space."
                ]
            },
        ],
        "billing" : [
            {
                "question" : "What is Voxel Farm's refund policy",
                "answer" : "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet."
            },
            {
                "question" : "Can I cancel my subscription and re-instate it sometime later without having to pay the initial License Fee again?",
                "answer" : "Please contact our support team about this. They reserve the right to reinstate your license at no additional cost, but this decision will be made by them based on your particular case. They will look at your history, for instance, if they have already reinstated your license before."
            },
            {
                "question" : "Can i upgrade my licence?",
                "answer" : "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet."
            },
            {
                "question" : "Can I upgrade my license?",
                "answer" : "Yes, it is possible to upgrade your license. Any one-time license fee you may have paid so far will be deducted from the new license fee, but any monthly fees paid so far will not. You can upgrade from this link:"
            },
        ],
       
    },
    "industries" :  [
        {
            "class" : "industry-logo mining",
            "image" : "ico-industries-mining.png",
            "sideimage" : "mining",
            "title" : "Mining",
            "description" : "Entities that require comprehensive, large-scale spatial data, such as geographical information systems (GIS) and mining companies, would greatly benefit from Voxel Space's capabilities.",
            "paragraphs" : [
                "Transform your mining operations with Voxel Space's innovative solutions, unlocking the full potential of your mining data. In the ever-evolving mining industry, where efficiency and precision are paramount, Voxel Space provides a game-changing platform for intelligent data utilization.",
                "Our solutions extend far beyond traditional data management, offering a sophisticated suite of tools for real-time monitoring, predictive analytics, and strategic decision support.",
                "Whether you're engaged in exploration, extraction, or processing, Voxel Space empowers your mining operations with comprehensive spatial insights. Visualize geological formations, optimize extraction processes, and streamline resource management through our advanced spatial data analytics.",
                "With Voxel Space, you can seamlessly integrate diverse datasets, from geological surveys to production logs, enabling a holistic understanding of your mining activities. Our solutions facilitate informed decision-making, risk mitigation, and resource optimization. From the mine site to the boardroom, Voxel Space's technology enhances collaboration and accelerates innovation in the mining sector.",
                "Stay ahead of the competition by unlocking intelligent mining operations through Voxel Space, where data becomes a powerful asset driving sustainable growth and operational excellence in the dynamic world of mining.",
            ]
        },
        {
            "class" : "industry-logo gas",
            "image" : "ico-industries-oil-gaz.png",
            "sideimage" : "gas",
            "title" : "Gas & oil",
            "description" : "Maximise the potential of your oil and gas data. Unlock intelligent oil and gas operations with Voxel Space's solutions.",
            "paragraphs" : [
                "In the dynamic and complex landscape of the Gas & Oil industry, where precision and efficiency are paramount, Voxel Space offers a transformative platform for intelligent data utilization. Our solutions go beyond conventional data management, providing a sophisticated suite of tools for real-time monitoring, predictive analytics, and decision support.",
                "Whether you're involved in exploration, production, or distribution, Voxel Space empowers your operations with comprehensive spatial insights. Visualize subsurface structures, optimize drilling processes, and enhance reservoir management through our advanced spatial data analytics.",
            ]
        },
        {
            "class" : "industry-logo engineering",
            "image" : "ico-industries-engineering.png",
            "sideimage" : "engineering",
            "title" : "Engineering",
            "description" : "Professionals working with large-scale, high-resolution spatial datasets for research or analysis, in areas like climatology or urban planning, will find Voxel Space highly beneficial.",
            "paragraphs" : [
                "Elevate your engineering endeavors, spanning infrastructure and architecture, with Voxel Space's cutting-edge solutions that unlock the full potential of your industry data. In the dynamic and demanding landscape of engineering, precision and efficiency are paramount, and Voxel Space provides an innovative platform for intelligent data utilization.",
                "Our solutions transcend conventional data management, offering a sophisticated suite of tools for real-time monitoring, predictive analytics, and strategic decision support. Whether you're engaged in infrastructure development, structural design, or architectural planning, Voxel Space empowers your engineering projects with comprehensive spatial insights.",
                "Visualize complex structures, optimize construction processes, and streamline project management through our advanced spatial data analytics. With Voxel Space, seamlessly integrate diverse datasets, from topographic surveys to architectural blueprints, enabling a holistic understanding of your engineering activities.",
                "Our solutions facilitate informed decision-making, risk mitigation, and resource optimization. From the construction site to the design studio, Voxel Space's technology enhances collaboration and accelerates innovation in the engineering sector.",
                "Stay ahead of the curve by unlocking intelligent engineering operations through Voxel Space, where data becomes a powerful asset driving sustainable growth and operational excellence in the dynamic world of engineering, infrastructure, and architecture.",
            ]
        },
        {
            "class" : "industry-logo entertainment",
            "image" : "ico-industries-entertainment.png",
            "sideimage" : "entertainment",
            "title" : "Entertainment",
            "description" : "Game developers and creators of virtual worlds can utilize Voxel Space to store and manage their complex spatial data.",
            "paragraphs" : [
                "Transform the landscape of entertainment, particularly in game development and virtual world creation, with Voxel Space's revolutionary solutions that unlock the full potential of your creative vision. In the dynamic and imaginative realm of the entertainment industry, precision and innovation are essential, and Voxel Space provides an innovative platform for intelligent spatial data utilization.",
                "Our solutions redefine the speed of development, offering a suite of tools for real-time visualization, predictive analytics, and streamlined decision-making in virtual world creation. Whether you're a game developer aiming for quicker iterations or a virtual world creator looking to expedite content creation, Voxel Space empowers your projects with swift and comprehensive spatial insights. Visualize, iterate, and optimize with unprecedented speed through our advanced spatial data analytics.",
                "With Voxel Space, seamlessly integrate diverse datasets, from terrain maps to character animations, enabling a holistic understanding of your virtual worlds. Our solutions facilitate informed decision-making, enhance creativity, and optimize resource utilization.",
                "From the development studio to the virtual reality headset, Voxel Space's technology enhances collaboration and accelerates innovation in the entertainment sector.",
                "Stay at the forefront of digital entertainment by unlocking intelligent operations through Voxel Space, where data becomes a powerful asset driving the creation of immersive, captivating experiences in the dynamic world of game development and virtual world creation.",
            ]
        },
        {
            "class" : "industry-logo environment",
            "image" : "ico-industries-environment.png",
            "sideimage" : "environment",
            "title" : "Environment",
            "description" : "Professionals working with large-scale, high-resolution spatial datasets for research or analysis, in areas like urban planning.",
            "paragraphs" : [
                "The need for comprehensive spatial data is paramount in understanding and addressing environmental challenges. Entities ranging from environmental research institutions to government bodies and non-profit organizations can leverage Voxel Space's expertise in generating large-scale, high-resolution spatial data.",
                "Whether it's mapping biodiversity, monitoring land use changes, or assessing the impact of climate change, Voxel Space offers a powerful tool for visualizing and analyzing complex environmental data.",
                "The integration of Voxel Space technology enables stakeholders in the environmental sector to make informed decisions, implement effective conservation strategies, and contribute to the long-term well-being of our planet.",
            ]
        },
        // {
        //     "class" : "industry-logo construction",
        //     "image" : "ico-industries-construction.png",
        //     "title" : "Construction",
        //     "description" : "Entities that require large-scale spatial data, such as geographical information systems (GIS) and mining companies"
        // },
        // {
        //     "class" : "industry-logo architecture",
        //     "image" : "ico-industries-architecture.png",
        //     "title" : "Architecture",
        //     "description" : "Entities that require large-scale spatial data, such as geographical information systems (GIS) and mining companies"
        // },
        // {
        //     "class" : "industry-logo medical",
        //     "image" : "ico-industries-medical.png",
        //     "title" : "Medical",
        //     "description" : "Game developers and creators of virtual worlds can utilize Voxel Space to store and manage their complex spatial data."
        // },
       
    ],
    "featured" :  [
        {
            "class" : "field field1",
            "title" : "Large-Scale Data Handling",
            "description" : "Voxel Space has the capability to handle substantial spatial datasets, spanning millions of square kilometers at high resolution. Whether it's capturing the details of the real world or creating expansive virtual worlds, Voxel Space can store it.",
            "paragraphs" : [

            ]
        },
        {
            "class" : "field field2",
            "title" : "Detailed Data Storage",
            "description" : "The platform can preserve an impressive level of detail, enabling the storage of spatial data at the sub-micrometer scale. For example, it could potentially store a scan of a human body down to the level of individual cells.",
            "paragraphs" : [
                
            ]
        },
        {
            "class" : "field field3",
            "title" : "Temporal Data Tracking",
            "description" : "Recognizing the deep connection between time and space, Voxel Space offers tools to monitor and comprehend how spatial data alters over time.",
            "paragraphs" : [
                
            ]
        },
        {
            "class" : "field field4",
            "title" : "Cloud-Based Service",
            "description" : "Voxel Space functions as a spatial platform as a service, building on the advantages of cloud providers like Microsoft Azure or Amazon Web Services. It handles server setup and formulates scaling strategies for expanding storage and processing needs.",
            "paragraphs" : [
                
            ]
        },
    ],
    "pricing" : {
        "basic" : [
            {
                "user" : 5,
                "price" : 550
            }
        ],
        "standard" : [
            {
                "user" : 10,
                "price" : 2750
            }
        ],
        "advanced" : [
            {
                "user" : 15,
                "price" : 8500
            }
        ],
        "premium" : [
            {
                "user" : 20,
                "price" : 18000
            }
        ]
    }

};


export default Texts;