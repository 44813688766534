import { useState, useEffect} from "react"
import {AuthenticatedTemplate, useMsal, UnauthenticatedTemplate } from '@azure/msal-react';

//import { loginRequest } from '../authConfig';
import { useNavigate } from "react-router-dom";
import {HashLink} from "react-router-hash-link"
import { useRef } from "react";
import React from 'react'
import {useContext} from 'react'
import { Context } from './Context';
import 'react-phone-number-input/style.css'
//import PhoneInput from 'react-phone-number-input'
import PasswordStrengthBar from 'react-password-strength-bar';
import { CountryDropdown } from 'react-country-region-selector';
//import {  RegionDropdown } from 'react-country-region-selector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
//import ClipLoader from "react-spinners/ClipLoader";
import { ToastContainer, toast } from 'react-toastify';


const Header = () => {
	
	const { instance} = useMsal();
	
	const handleLoginRedirect = (e, instance) => {
		e.preventDefault();
		const account = instance.getActiveAccount();
		console.log('Account : ' + account)
			
		if(!account){
			instance.loginRedirect()
			.then(function (loginResponse) {
				const infos = loginResponse.idTokenClaims;
				console.log(infos)
				const names = typeof infos.name !== "undefined" ? infos.name.split(' ') : [loginResponse.username]
				
				const session = {
					"firstname" : names[0],
					"lastname" : names[1],
					"email" : infos.preferred_username,
					"token" : loginResponse.idToken,
					"homeAccountId" : loginResponse.account.homeAccountId,
					"data" : JSON.stringify(loginResponse)
				};
				
				localStorage.setItem("vxs_user", JSON.stringify(session));
				//setIsLogged(true)
				setUser(names[0])

				// Display signed-in user content, call API, etc.
			})
			.catch(function (error) {
				//login failure
				console.log(error);
			});
			//const request = { scopes: ["openid", "profile"] };
			//await instance.acquireTokenPopup(request);
		}
		
    };

	const handleLogoutRedirect = (e, instance) => {
		e.preventDefault();
		const activeAccount = instance.getActiveAccount();
		const currentAccount = instance.getAccountByHomeId(activeAccount.homeAccountId);
		instance.logoutRedirect({ account: currentAccount})
		.then(function (logoutResponse) {
			console.log(logoutResponse)
			setUser(null)
			document.querySelector('body').classList.remove('logged')
			localStorage.removeItem('vxs_user')
		})
		.catch((error) => console.log(error));
	};

	const [hamburger, setHamburger] = useState(true);
	const [message, setMessage] = useState('')
	const [isMobile, setIsMobile] = useState(false)
	const [showPanel, setShowPanel] = useState(false)

	const [showForgetPasswordMessage, setShowForgetPasswordMessage] = useState(false)
	
	const [errorMessage, setErrorMessage] = useState('')
	
	//SIGNUP
	const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [email, setEmail] = useState('')
    const [company, setCompany] = useState('')
    const [acceptNewletter, setAcceptNewletter] = useState(false)
    const [password, setPassword] = useState('')
    const [country, setCountry] = useState('')
	const [token, setToken] = useState('');

	const screenSize = useRef();
	const {user, setUser, loginSigninDialog, setLoginSigninDialog, loginDialog, setLoginDialog, signinDialog, setSigninDialog, forgotPasswordDialog, setForgotPasswordDialog, redirectAfterLogin, setRedirectAfterLogin, redirectAfterLoginLink, setRedirectAfterLoginLink} = useContext(Context);
	
	const showError = {
        display : (errorMessage !== '') ? "block" : "none"
    }
	const navigate = useNavigate()
	const [activeSection, setActiveSection] = useState(null);
  	const sections = useRef([]);

	const handleScroll = () => {
		const pageYOffset = window.scrollY;
		let newActiveSection = null;

		sections.current.forEach((section) => {
			const sectionOffsetTop = section.offsetTop - 100;
			const sectionHeight = section.offsetHeight;
			if (pageYOffset >= sectionOffsetTop && pageYOffset  < sectionOffsetTop + sectionHeight) {
				newActiveSection = section.id;
			}
		});
		setActiveSection(newActiveSection);
	};

	const newRef = useRef(null);

	useEffect(() => {
	
		const activeAccount = instance.getActiveAccount();
		if(activeAccount === null)
			document.querySelector('body').classList.remove('logged')
		else{
			setUser(activeAccount.username)
			document.querySelector('body').classList.add('logged')
			//navigate('/myaccount/profile')
		}

		
        window.addEventListener("resize", () => {
            screenSize.current = window.innerWidth;
			if(window.innerWidth > 992)
				setIsMobile(false)
			else
				setIsMobile(true)
        });

		document.addEventListener("click", handleOutsideClick, true)
      
		sections.current = document.querySelectorAll('section');
	   	window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
    });

	const handleOutsideClick = (e) => {
		if (newRef.current && !newRef.current.contains(e.target)) {
			setLoginSigninDialog(false)
		}
	};

	const handleClickMenu = (e, val) => {

		if(e.target.parentNode.parentNode.classList.contains('opened'))
			e.target.parentNode.parentNode.classList.remove('opened')
		else
			e.target.parentNode.parentNode.classList.add('opened')

		if(val === true){
			setHamburger(false)
		}
		else{
			setHamburger(true)
		}
		console.log(val)
	}

	const showMyAccountPanel = () => {
		setShowPanel(true)
	}

	const hideMyAccountPanel = () => {
		setShowPanel(false)
	}

	const showResourcePanel = () => {
		
		if(document.querySelector('.resource-container').classList.contains('selected'))
			document.querySelector('.resource-container').classList.remove('selected')
		else
			document.querySelector('.resource-container').classList.add('selected')
	}

	const hideResourcePanel = (e) => {
	
		if(document.querySelector('.resource-container').classList.contains('selected'))
			document.querySelector('.resource-container').classList.remove('selected')
		else
			document.querySelector('.resource-container').classList.add('selected')
	}

	const closePanel = () => {
		if(document.querySelector('.nav').classList.contains('opened')){
			document.querySelector('.nav').classList.remove('opened')
			setHamburger(true)
		}
	}

	// const logout = (e) => {
	// 	e.preventDefault();
	// 	const user = localStorage.getItem('vxs_user');
	// 	const userinfo = JSON.parse(user);
    //     const data ={
    //         uid : userinfo.uid,
    //         token : userinfo.token,
    //         key_id : process.env.REACT_APP_BASE_APIKEY
    //     };

	// 	const endpoint = process.env.REACT_APP_BASE_URL + '/user/logout';
    //     fetch(endpoint, {
    //         headers: {
    //             'Accept': 'application/json',
    //             'Content-Type': 'application/json'
    //         },
    //         "method": "POST",
    //         "body": JSON.stringify(data)
    //     })
    //     .then(response => response.json())
    //     .then(response => {
    //         if(response.action === "failed"){
    //             return false;
    //         }else{
	// 			setIsLogged(false)
	// 			setUser(null)
	// 			setUid(null)
	// 			localStorage.removeItem('vxs_user')
	// 			navigate('/')
    //         }
    //     })
    //     .catch(err => {
    //         console.log(err);
    //         return false;
    //     });
	
	// }

	const handleClosePopup = () => {
		setErrorMessage('')
		setLoginSigninDialog(false)
	}

	const validateForm = (email, password) => {
        if (email.length < 5  || 
            email.split("").filter(x => x === "@").length !== 1 || email.indexOf(".") === -1)
            return false;

        if (password.length < 5)
            return false;

        return true;
    }

	const handleSubmit = (e) => {
		
        e.preventDefault();
        const emailElem = document.querySelector('#emailinput');
        const passwordElem = document.querySelector('#passwordinput');
          
       
        if(emailElem.value.length === 0){
            emailElem.classList.remove('is-valid');
            emailElem.classList.add('is-invalid');
        }

        if(passwordElem.value.length === 0){
            passwordElem.classList.remove('is-valid');
            passwordElem.classList.add('is-invalid');
        }

        if(!validateForm(emailElem.value, passwordElem.value)){
			return; 
		}

        const data = {
            email : emailElem.value,
            password : passwordElem.value,
			key_id : process.env.REACT_APP_BASE_APIKEY
        };
        const endpoint = process.env.REACT_APP_BASE_URL + '/user/login';

        fetch(endpoint, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            "method": "POST",
            "body": JSON.stringify(data)
        })
        .then(response => response.json())
        .then(response => {     
                     
            if(!response || response.action === "failed" || typeof response === "undefined"){
			    setErrorMessage(response.msg)
				return false
            }else{
				
             	localStorage.setItem("vxs_user", JSON.stringify(response.user)); 
				//setIsLogged(true)
				setUser(response.user.firstname)
				setLoginSigninDialog(false)

				if(redirectAfterLogin){
					setRedirectAfterLogin(false)
					setRedirectAfterLoginLink('')
					setTimeout(() => {
						window.open(redirectAfterLoginLink, '_blank');
					}, 2000);
				}else{

					navigate('/myaccount/profile')
				}
            }
            
        })
        .catch(err => {
            console.log(err);
        });
    }

	const handleLoginClick = (e) => {
		e.preventDefault();
		setLoginSigninDialog(true)
		setLoginDialog(true)
		setSigninDialog(false)
		setForgotPasswordDialog(false)
	}

	const handleSignupClick = (e) => {
		e.preventDefault();
		setLoginSigninDialog(true)
		setLoginDialog(false)
		setSigninDialog(true)
		setForgotPasswordDialog(false)
	}

	const handleForgetPasswordClick = (e) => {
		e.preventDefault();
		setLoginSigninDialog(true)
		setLoginDialog(false)
		setSigninDialog(false)
		setForgotPasswordDialog(true)
	}


	const handleEmail = (event) => {
        if (event.target.value.length < 5  || 
            event.target.value.split("").filter(x => x === "@").length !== 1 || event.target.value.indexOf(".") === -1) {
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
        }
		setEmail(event.target.value)
    }

    const handlePassword = (event) => {
        if (event.target.value.length < 4 ){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }
        else{
            event.target.classList.remove('is-invalid'); 
            //event.target.classList.add('is-valid'); 
        }
		setPassword(event.target.value)
    }

	//SIGNUP
	const handleFirstname = (event) => {
        setFirstname(event.target.value)
        if (event.target.value.length < 2 ){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }
        else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
        }
    }

	const handleLastname = (event) => {
        setLastname(event.target.value)
        if (event.target.value.length < 1 ){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }
        else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
        }
    }

	
	const UpdateNewletter = (e) => {
        if(e.target.checked === false)
            setAcceptNewletter("false")
        else
            setAcceptNewletter("true")
    }

	const handleSumit = (e) => {
      
        e.preventDefault();
      
        const firstnameElem = document.querySelector('#firstname');
        const lastnameElem = document.querySelector('#lastname');
        const emailElem = document.querySelector('#email');
        const passwordElem = document.querySelector('#password');
       
		
       
        if(firstnameElem.classList.contains('is-invalid') || firstname.length === 0){
            firstnameElem.classList.remove('is-valid');
            firstnameElem.classList.add('is-invalid');
            return false;
        }
        if(lastnameElem.classList.contains('is-invalid') || lastname.length === 0){
            lastnameElem.classList.remove('is-valid');
            lastnameElem.classList.add('is-invalid');
            return false;
        }
        if(emailElem.classList.contains('is-invalid') || email.length === 0){
            emailElem.classList.remove('is-valid');
            emailElem.classList.add('is-invalid');
            return false;
        }
        if(passwordElem.classList.contains('is-invalid') || password.length === 0){
            passwordElem.classList.remove('is-valid');
            passwordElem.classList.add('is-invalid');
            return false;
        }      
        
        const data = {
            firstname : firstname,
            lastname : lastname,
            company : company,
            email : email,
            password : password,
            newsletter : acceptNewletter,
            country : country,
            key_id : process.env.REACT_APP_BASE_APIKEY
        };

        const endpoint = process.env.REACT_APP_BASE_URL + '/user/signup';

        fetch(endpoint, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            "method": "POST",
            "body": JSON.stringify(data)
        })
        .then(response => response.json())
        .then(response => {     
            
            if(response.action === "failed"){
                toast.error(response.msg, {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored"
                });
                return false;
            }else{
                toast.success(response.msg, {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored"
                });
                reset()

                setTimeout(() => {
                    navigate('/login')
                }, 10000);
            }
        })
        .catch(err => {
            console.log(err);
        });
    }


	const handlePasswordSubmit = (e) => {
        e.preventDefault();
		const userInfo = localStorage.getItem('vxs_user');
		if(userInfo && userInfo !== "undefined"){
            const u = JSON.parse(userInfo)
            setToken(u.token)
        }

        const emailElem = document.querySelector('#email');
        if(emailElem.classList.contains('is-invalid') || email.length === 0){
            emailElem.classList.remove('is-valid');
            emailElem.classList.add('is-invalid');
            return false;
        }

        const url = process.env.REACT_APP_BASE_URL + '/user/forgot-password';
        fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method : "POST",
            body : JSON.stringify({email : email, token : token, key_id : process.env.REACT_APP_BASE_APIKEY})
        })
        .then(response => response.json())
        .then(response => {
            if(response.action === "failed"){
                toast.error(response.msg, {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored"
                });
                return false;
            }else{
                setEmail('')
                setMessage(response.msg)
				setShowForgetPasswordMessage(true)
            }
        })
        .catch(err => {
            console.log(err);
        });
    }
	const reset = () => {
        setFirstname('')
        setLastname('')
        setCompany('')
        setEmail('')
        setPassword('')
        setCountry('')
        document.querySelector('#flexCheckDefault').checked = false;
    }

  	return (
		<>
		 <ToastContainer />
    	<div className="nav shadow-bottom">
			<div className="icon">
				{
				hamburger ? 
				<i className="fa fa-bars" onClick={(e) => handleClickMenu(e, hamburger)}></i>
				:
				<i className="fa fa-close" onClick={(e) => handleClickMenu(e, hamburger)}></i>
				}
			</div>
			
			<HashLink to="/#design">
				<div className="logo"></div>
			</HashLink>
			{!isMobile || (isMobile && !hamburger) ? 
			<>
			<div className="menu" onClick={closePanel}>
				{/* <div className="menu_item"><Link className="menu_item" to="/">Home</Link></div> */}
				<div className="menu_item text-medium font-weight-500"><HashLink className={activeSection === 'industry' ? 'active' : ''} to="/#industry" smooth>Industries</HashLink></div>
				<div className="menu_item text-medium font-weight-500"><HashLink className={activeSection === 'features' ? 'active' : ''} to="/#features" smooth>Features</HashLink></div>
				{/* <div className="menu_item text-medium"><HashLink className={activeSection === 'features' ? 'active' : ''} to="/#features" smooth>Data types</HashLink></div> */}
				<div className="menu_item text-medium font-weight-500"><HashLink className={activeSection === 'pricing' ? 'active' : ''} to="/#pricing" smooth>Pricing</HashLink></div>
				<div className="menu_item text-medium font-weight-500" style={{ position:'relative' }} onMouseEnter={showResourcePanel} onMouseLeave={hideResourcePanel}>Resources &nbsp;<i className="fa fa-light fa-chevron-down" style={{ fontSize:'11px'}}></i>
					
					<div className="resource-container">
						<ul style={{ fontSize : '12px'}}>
							<li><HashLink className={activeSection === 'help' ? 'active' : ''} to="/faq" smooth>FAQ</HashLink></li>
							<li><HashLink className={activeSection === 'help' ? 'active' : ''} to="/faq" smooth>User Guide</HashLink></li>
							<li><HashLink className={activeSection === 'help' ? 'active' : ''} to="/tutorials" smooth>Tutorials</HashLink></li>
						</ul>
					</div>
					
				</div>
			
				<div className="menu_item tryitnow text-medium font-weight-500 text-light-blue"><HashLink to="/#pricing" className="login" smooth>Try free</HashLink></div>
				<div className="menu_item text-medium">
					{/* <div className="m text-mediumenu-right" onMouseEnter={showMyAccountPanel}> */}
					<div className="menu-right" >
						
						{/* {
						isLogged ? 
							<div className="myaccount-container" onMouseEnter={showMyAccountPanel} onMouseLeave={hideMyAccountPanel}>
								<div className="myaccount-title text-medium">Hello, {user} <FontAwesomeIcon icon={faChevronDown} color="#00659D" /></div>
								{showPanel ? 
									<div className="myaccount-panel">
										<div className="myaccount-item"><a href="/myaccount/profile">My Account</a></div>
										<div className="myaccount-item"><a href="/#" onClick={(e) => logout(e)}>Sign Out</a></div>
									</div>
								: ''
								}
							</div>
						:
						<HashLink to="/login" onClick={(e) => handleLoginClick(e)}><div id="user-login"><div className="user-logo"></div></div></HashLink>
						} */}
						<AuthenticatedTemplate>
							<div className="myaccount-container" onMouseEnter={showMyAccountPanel} onMouseLeave={hideMyAccountPanel}>
									
									<div className="myaccount-title-logged text-small">{user} <FontAwesomeIcon icon={faChevronDown} color="#00659D" /></div>
									
									{showPanel ? 
										<div className="myaccount-panel">
											<div className="myaccount-item"><a href="/myaccount/profile">My Account</a></div>
											<div className="myaccount-item"><a href="/#" onClick={(e) => handleLogoutRedirect(e, instance)}>Sign Out</a></div>
										</div>
									: ''
									}
								</div>
						</AuthenticatedTemplate>
						<UnauthenticatedTemplate>
							<HashLink to="/login" onClick={(e) => handleLoginRedirect(e, instance)}><div id="user-login"><div className="user-logo"></div></div></HashLink>
						</UnauthenticatedTemplate>
					</div>
				</div>
			</div>		
			</>
			: 
			''
			}

		</div>
		{showPanel ? 
			<div className="nav-overlay"></div>
		:
			''
		}

		{loginSigninDialog ? 
			<>
			<div className="login-overlay overlay"></div>
			{ 
			loginDialog ? 
			<div id="loginformplan" ref={newRef}>
				<div className="content">
					<div className="login-close" onClick={handleClosePopup}></div>
					<div className="title">
						<div className="title-large-1 font-weight-700 text-black">SIGN IN</div>
						<div className="text-extra-large text-blue-btn">OR <HashLink to="/signup" className="text-blue-btn" onClick={(e) => handleSignupClick(e)}> SIGN UP</HashLink></div>
					</div>
					<div className="row">
						<div className="col-12">
						<form className="mt-3 form-horizontal">
							<div className="row">
								<div className="
									alert alert-danger alert-dismissible
									bg-danger
									text-white
									border-0
									fade
									show
									" role="alert" style={showError}>
									<strong>Error - </strong> {errorMessage}
								</div>
							</div>
							<div className="form-group mb-4">
								<label htmlFor="inputHorizontalDnger" className="col-sm-2 col-form-label">Email</label>
								<div className="col-sm-12">
									<input type="email" className="form-control" id="emailinput" onChange={handleEmail}/>
									<div className="invalid-feedback">Please provide with a valid email </div>
									<div className="valid-feedback"></div>
								</div>
							</div>
							<div className="form-group mb-4">
								<label htmlFor="inputHorizontalDnger" className="col-sm-2 col-form-label">Password</label>
								<div className="col-sm-12">
									<input type="password" className="form-control" value={password} id="passwordinput" autoComplete="off" onChange={handlePassword}/>
									<div className="invalid-feedback">Please provide with a valid password</div>
									<div className="valid-feedback"></div>
									<div className="forget-password text-medium"><HashLink to="/forgot-password" className="text-blue-btn" onClick={(e) => handleForgetPasswordClick(e)}>Forgot password</HashLink></div>
								</div>
							</div>
							
							<div className="form-group mb-4">
								<button className="btn btn-primary text-medium btn-border-radius" type="submit" onClick={handleSubmit}>
							SIGN IN</button>
							</div>
							<div className="login-footer" style={{ paddingBottom:'10px' }}>
								
								Don't have an account yet? <span className="new-account text-medium"><HashLink to="/signup" className="text-blue-btn" onClick={(e) => handleSignupClick(e)}>Create one now</HashLink></span>
							</div>
						</form>
						</div>
					</div>
				</div>
			</div>
			: ''
			}
			{
			signinDialog ? 
			<div id="loginformplan" ref={newRef}>
				<div className="content">
					<div className="login-close" onClick={handleClosePopup}></div>
					<div className="title">
						<div className="title-large-1 font-weight-700 text-black">SIGN UP</div>
						<div className="text-extra-large text-blue-btn">OR <HashLink to="/login" className="text-blue-btn" onClick={(e) => handleLoginClick(e)}> SIGN IN</HashLink></div>
					</div>
					<div>
						<form>
							<div className="mb-3 form-group">
								<label htmlFor="firstname" className="form-label">First Name</label>
								<input type="text" className="form-control" id="firstname" value={firstname} onChange={handleFirstname}/>
								<div className="invalid-feedback">Please provide with a valid First Name </div>
								<div className="valid-feedback"></div>
							</div>
							<div className="mb-3 form-group">
								<label htmlFor="lastname" className="form-label">Last Name</label>
								<input type="text" className="form-control" id="lastname" aria-describedby="emailHelp" value={lastname} onChange={handleLastname}/>
								<div className="invalid-feedback">Please provide with a valid Last Name </div>
								<div className="valid-feedback"></div>
							</div>
							<div className="mb-3 form-group">
								<label htmlFor="firstname" className="form-label">Company Name</label>
								<input type="text" className="form-control" id="company" value={company} onChange={(e) => setCompany(e.target.value)}/>
								<div className="invalid-feedback">Please provide with a valid Company Name </div>
								<div className="valid-feedback"></div>
							</div>
							<div className="mb-3 form-group">
								<label htmlFor="email" className="form-label">Email</label>
								<input type="text" className="form-control" id="email" placeholder="Email" aria-describedby="emailHelp" value={email} onChange={handleEmail}/>
								<div className="invalid-feedback">Please provide with a valid Email </div>
								<div className="valid-feedback"></div>
							</div>
							<div className="mb-3 form-group">
								<label htmlFor="Country / Region" className="form-label">Country</label>
								<br/>
								<CountryDropdown
								value={country}
								onChange={(val) => setCountry(val)} />
							</div>
							
							<div className="mb-3 form-group">
								<label htmlFor="password" className="form-label">Password (Minimum 5 characters)</label>
								<input type="password" className="form-control" id="password" autoComplete='off' value={password} onChange={handlePassword}/>
								<PasswordStrengthBar password={password} minLength={4}/>
								
							</div>
						
							<div className="mb-3 form-group">
								<input className="form-check-input" type="checkbox" value={acceptNewletter} id="flexCheckDefault" onChange={(e) => UpdateNewletter(e)}/>
								<label className="form-check-label" htmlFor="flexCheckDefault">&nbsp;Subscribe to the newsletter</label>
							</div>
								
							<button type="submit" className="btn btn-primary text-medium btn-border-radius" onClick={handleSumit}>CREATE</button>
							
						</form>
					</div>
				</div>
			</div>
			:''
			}
			{
				forgotPasswordDialog ? 
				<div id="loginformplan" ref={newRef}>
					<div className="content">
						<div className="login-close" onClick={handleClosePopup}></div>
						<div className="title">
							<div className="title-large-1 font-weight-700 text-black">FORGOT PASSWORD</div>
						</div>
						{
						showForgetPasswordMessage ? 
						<div className="row">
							<div className="col-12">
								<div className="font-weight-600 text-medium">{message}</div>
								<br/>
								<div className='text-small'>If you haven't received an email in 5 minutes, check your spam or try a different email.</div> 
							</div>
						</div>
						:
						<div className="row">
							<div className="col-12">
								<form className="mt-3 form-horizontal">
									<div className="mb-3 form-group required">
										<input type="text" className="form-control" id="email" placeholder="Email" aria-describedby="emailHelp" value={email} onChange={handleEmail}/>
										<div className="invalid-feedback">Please provide with a valid Email </div>
										<div className="valid-feedback"></div>
									</div>
									<button type="submit" className="btn btn-primary text-medium btn-border-radius" onClick={handlePasswordSubmit}>Submit</button>
								</form>
							</div>
						</div>
						}
					</div>
				</div>
				: ''
			}
			
			</>
		: ''
		}
		</>
		
  	)
}

export default Header