import React, {useState, useEffect} from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from 'react-phone-number-input'

const MyaccountEditAddress = () => {
	
    const [address, setAddress] = useState('')
    const [city, setCity] = useState('')
    const [postalCode, setPostalCode] = useState('')
    const [country, setCountry] = useState('')
    const [region, setRegion] = useState('')
    const [phone, setPhone] = useState('')
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [uid, setUid] = useState('');
    const [token, setToken] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0)
        const userInfo = localStorage.getItem('vxs_user');
        if(userInfo && userInfo !== "undefined"){
            const u = JSON.parse(userInfo)
			setToken(u.token)
            setUid(u.uid)
            const endpoint = process.env.REACT_APP_BASE_URL + '/user/address';

            fetch(endpoint, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                "method": "POST",
                "body": JSON.stringify({uid : u.uid, token : u.token, key_id : process.env.REACT_APP_BASE_APIKEY})
            })
            .then(response => response.json())
            .then(response => {     
            
            if(response.action === "failed"){
                //setErrorMessage(response.msg)
            }else{
                setAddress(response.address)
                setFirstname(response.firstname)
                setLastname(response.lastname)
                setCountry(response.country)
                setCity(response.city)
                setPostalCode(response.postalcode)
                setRegion(response.region)
                setPhone(response.phone)
            }
        })
        .catch(err => {
            console.log(err);
        });
        }

    }, [uid, token])

    const handleFirstname = (event) => {
        setFirstname(event.target.value)
        if (event.target.value.length < 2 ){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }
        else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
        }
    }

	const handleLastname = (event) => {
        setLastname(event.target.value)
        if (event.target.value.length < 1 ){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }
        else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
        }
    }

	const handleAddress = (event) => {
        setAddress(event.target.value)
        if (event.target.value.length < 8 ){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }
        else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
        }
    }

    const handleCity = (event) => {
        setCity(event.target.value)
        if (event.target.value.length < 3 ){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }
        else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
        }
    }

    const handlePostalCode = (event) => {
        setPostalCode(event.target.value)
        if (event.target.value.length < 5 ){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }
        else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
        }
    }

    const handleSumit = (e) => {
       
        e.preventDefault();
           
        const firstnameElem = document.querySelector('#firstname');
        const lastnameElem = document.querySelector('#lastname');
        const addressElem = document.querySelector('#address');
        const cityElem = document.querySelector('#city');
        const postalCodeElem = document.querySelector('#postalCode');
        const phoneElem = document.querySelector('#phone');

        if(firstnameElem.classList.contains('is-invalid') || firstname.length === 0){
            firstnameElem.classList.remove('is-valid');
            firstnameElem.classList.add('is-invalid');
            return false;
        }
        if(lastnameElem.classList.contains('is-invalid') || lastname.length === 0){
            lastnameElem.classList.remove('is-valid');
            lastnameElem.classList.add('is-invalid');
            return false;
        }
        if(addressElem.classList.contains('is-invalid') || address.length === 0){
            addressElem.classList.remove('is-valid');
            addressElem.classList.add('is-invalid');
            return false;
        }
        if(cityElem.classList.contains('is-invalid') || city.length === 0){
            cityElem.classList.remove('is-valid');
            cityElem.classList.add('is-invalid');
            return false;
        }
        if(postalCodeElem.classList.contains('is-invalid') || postalCode.length < 5){
            postalCodeElem.classList.remove('is-valid');
            postalCodeElem.classList.add('is-invalid');
            return false;
        }
        if(phone.length < 7){
            phoneElem.classList.remove('is-valid');
            phoneElem.classList.add('is-invalid');
            return false;
        }
          
        const data = {
            uid : uid,
            firstname : firstname,
            lastname : lastname,
            address : address,
            city : city,
            country : country,
            phone : phone,
            region : region,
            postalCode : postalCode,
            key_id : process.env.REACT_APP_BASE_APIKEY
        };

        const endpoint = process.env.REACT_APP_BASE_URL + '/user/address/edit';

        fetch(endpoint, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            "method": "POST",
            "body": JSON.stringify(data)
        })
        .then(response => response.json())
        .then(response => {     
            
            if(response.action === "failed"){
                toast.error(response.msg, {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored"
                });
                return false;
            }else{
                toast.success(response.msg, {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored"
                });
            }
        })
        .catch(err => {
            console.log(err);
        });
    }
	
    return (
        <div className='app'>
            <ToastContainer />
            <Header/>
            <div className="page-content container-fluid">
                <div className="row justify-content-center">
                    <div className="col">
                        <div className="spaceTopBottom shadow_around max-width-840" style={{ padding: '20px', borderRadius: '10px'}}>
                            <div className="title">
                                <h3 className="box-title mb-3">Edit Your Address</h3>
                            </div>
                            <div>
                                <form>
                                    <div className="mb-3 form-group required">
                                        <label htmlFor="firstname" className="form-label">First Name</label>
                                        <input type="text" className="form-control" id="firstname" value={firstname} onChange={handleFirstname}/>
                                        <div className="invalid-feedback">Please provide with a valid First Name </div>
                                        <div className="valid-feedback"></div>
                                    </div>
                                    <div className="mb-3 form-group required">
                                        <label htmlFor="lastname" className="form-label">Last Name</label>
                                        <input type="text" className="form-control" id="lastname" aria-describedby="emailHelp" value={lastname} onChange={handleLastname}/>
                                        <div className="invalid-feedback">Please provide with a valid Last Name </div>
                                        <div className="valid-feedback"></div>
                                    </div>
                                    <div className="mb-3 form-group required">
                                        <label htmlFor="address" className="form-label">Address</label>
                                        <input type="text" className="form-control" id="address" value={address} onChange={handleAddress} />
                                        <div className="invalid-feedback">Please provide with a valid Address (min 4 characters)</div>
                                        <div className="valid-feedback"></div>
                                    </div>

                                    <div className="mb-3 form-group required">
                                        <label htmlFor="city" className="form-label">City</label>
                                        <input type="text" className="form-control" id="city" value={city} onChange={handleCity}/>
                                        <div className="invalid-feedback">Please provide with a valid City (min 2 characters) </div>
                                        <div className="valid-feedback"></div>
                                    </div>

                                    <div className="mb-3 form-group required">
                                        <label htmlFor="postalcode" className="form-label">Postal Code</label>
                                        <input type="text" className="form-control" id="postalCode" value={postalCode} onChange={handlePostalCode}/>
                                        <div className="invalid-feedback">Please provide with a valid Postal Code (min 5 characters) </div>
                                        <div className="valid-feedback"></div>
                                    </div>

                                    <div className="mb-3 form-group required">
                                        <label htmlFor="Country / Region" className="form-label">Country / Region</label>
                                        <br/>
                                        <CountryDropdown
                                        value={country}
                                        onChange={(val) => setCountry(val)} />

                                        <RegionDropdown
                                        country={country}
                                        value={region}
                                        onChange={(val) => setRegion(val)} />
                                        <div className="invalid-feedback">Please provide with a valid country / Region </div>
                                        <div className="valid-feedback"></div>
                                    </div>
                                    <div className="mb-3 form-group required">
                                        <label htmlFor="phone" className="form-label">Phone</label>
                                        <PhoneInput
                                            className="form-control"
                                            placeholder="Enter phone number"
                                            value={phone}
                                            id="phone"
                                            onChange={setPhone}/>
                                        <div className="invalid-feedback">Please provide with with a valid Phone Number </div>
                                        <div className="valid-feedback"></div>
                                    </div>
                                    <button type="submit" className="btn btn-primary" onClick={handleSumit}>Submit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
            <Footer />
        </div>
    );
}

export default MyaccountEditAddress;
