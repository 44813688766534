import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'
import Header from '../components/Header'
import PhoneInput from 'react-phone-number-input'
import ClipLoader from "react-spinners/ClipLoader";
import ReactCountryFlagsSelect from 'react-country-flags-select';

const Contact = () => {

    const [myname, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')
    const [otherMessage, setOtherMessage] = useState('')
    const [showOtherMessage, setShowOtherMessage] = useState(false)
    const [mining, setMining] = useState(false)
    const [oilgas, setOilGas] = useState(false)
    const [engineering, setEngineering] = useState(false)
    const [environment, setEnvironment] = useState(false)
    const [construction, setConstruction] = useState(false)
    const [architecture, setArchitecture] = useState(false)
    const [medical, setMedical] = useState(false)
    const [entertainment, setEntertainment] = useState(false)
    const [other, setOther] = useState(false)
    const [loading, setLoading] = useState(false);
    const [subject, setSubject] = useState('');
    const [company, setCompany] = useState('');
    const [city, setCity] = useState('');
    //const [country, setCountry] = useState(null);
    const [country, setCountry] = useState(null);
    const searchPlaceholder = "";

    useEffect(() => {
        window.scrollTo({
            top:0,
            behavior: 'instant'
        });   
    },[]);


         
    const reset = () => {
        setName('')
        setEmail('')
        setMessage('')
        setOther('')
        setOtherMessage('')
        setPhone('')
        setCompany('')
        setCity('')
        setCountry('')
        setPhone('')
        setSubject('')
        Array.from(document.querySelectorAll("input[type=checkbox]"), e => e.checked = false);
    }

    const handleName = (event) => {
        setName(event.target.value)
        if (event.target.value.length < 2 ){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }
        else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
        }
    }

    const handleCity = (event) => {
        setCity(event.target.value)
        if (event.target.value.length < 2 ){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }
        else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
        }
    }

    // const handleCountry = (event) => {
    //     setCountry(event.target.value)
    //     if (event.target.value.length < 2 ){
    //         event.target.classList.remove('is-valid');
    //         event.target.classList.add('is-invalid');
    //     }
    //     else{
    //         event.target.classList.remove('is-invalid'); 
    //         event.target.classList.add('is-valid'); 
    //     }
    // }

    const handleMessage = (event) => {
        setMessage(event.target.value)
        if (event.target.value.length < 10 ){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }
        else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
        }
    }

    const handleOther = (event) => {
        setOther(event)
        setShowOtherMessage(event)
    }
    const handleOtherMessage = (event) => {
        setOtherMessage(event.target.value)
        if (event.target.value.length < 1 ){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }
        else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
        }
    }


    const handleEmail = (event) => {
        setEmail(event.target.value)
        if (event.target.value.length < 5  || 
            event.target.value.split("").filter(x => x === "@").length !== 1 || event.target.value.indexOf(".") === -1) {
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
        }
    }

    const handleCompany = (event) => {
        setCompany(event.target.value)
        if (event.target.value.length < 2 ) {
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
        }
    }

    const handleSubject = (event) => {
        console.log('subject : ' + event.target.value)
        setSubject(event.target.value)
        if (event.target.value.length < 2 ) {
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
        }
    }
   
    const handleSubmit = (e) => {
       
        e.preventDefault();
        setLoading(true);
        console.log("Phone : " + phone.length)
        const csubject = document.querySelector('#subject');
        if(csubject.classList.contains('is-invalid') || csubject.value.length === 0){
            csubject.classList.remove('is-valid');
            csubject.classList.add('is-invalid');
            setLoading(false);
            return false;
        }else{
            csubject.classList.remove('is-invalid');
            csubject.classList.add('is-valid'); 
        }

        const cname = document.querySelector('#myname');
        if(cname.classList.contains('is-invalid') || cname.value.length === 0){
            cname.classList.remove('is-valid');
            cname.classList.add('is-invalid');
            setLoading(false);
            return false;
        }else{
            cname.classList.remove('is-invalid');
            cname.classList.add('is-valid'); 
        }
       
        const cemail = document.querySelector('#email');
        if(cemail.classList.contains('is-invalid') || cemail.value.length === 0){
            cemail.classList.remove('is-valid');
            cemail.classList.add('is-invalid');
            setLoading(false);
            return false;
        }else{
            cemail.classList.remove('is-invalid');
            cemail.classList.add('is-valid');
        }

        const ccompany = document.querySelector('#company');
        if(ccompany.classList.contains('is-invalid') || ccompany.value.length === 0){
            ccompany.classList.remove('is-valid');
            ccompany.classList.add('is-invalid');
            setLoading(false);
            return false;
        }else{
            ccompany.classList.remove('is-invalid');
            ccompany.classList.add('is-valid'); 
        }
        
        const ccity = document.querySelector('#city');
        if(ccity.classList.contains('is-invalid') || ccity.value.length === 0){
            ccity.classList.remove('is-valid');
            ccity.classList.add('is-invalid');
            setLoading(false);
            return false;
        }else{
            ccity.classList.remove('is-invalid');
            ccity.classList.add('is-valid');
        }


        console.log('Country : ' + country['label'])

        // const ccountry = document.querySelector('#country');
        // if(ccountry.classList.contains('is-invalid') || ccountry.value.length === 0){
        //     ccountry.classList.remove('is-valid');
        //     ccountry.classList.add('is-invalid');
        //     setLoading(false);
        //     return false;
        // }else{
        //     ccountry.classList.remove('is-invalid');
        //     ccountry.classList.add('is-valid');
        // }
      
        const cphone = document.querySelector('.PhoneInput');
        if(phone.length === 0 || !phone){
            cphone.classList.remove('is-valid');
            cphone.classList.add('is-invalid');
            setLoading(false);
            return false;
        }else{
            cphone.classList.remove('is-invalid');
            cphone.classList.add('is-valid'); 
        }

        const cmsg = document.querySelector('#message');
        if(cmsg.classList.contains('is-invalid') || cmsg.value.length === 0){
            cmsg.classList.remove('is-valid');
            cmsg.classList.add('is-invalid');
            setLoading(false);
            return false;
        }else{
            cmsg.classList.remove('is-invalid');
            cmsg.classList.add('is-valid');
        }

        const comsg = document.querySelector('#otherMessage');
        if((comsg.classList.contains('is-invalid') || comsg.value.length === 0) && other){
            comsg.classList.remove('is-valid');
            comsg.classList.add('is-invalid');
            setLoading(false);
            return false;
        }else{
            comsg.classList.remove('is-invalid');
            comsg.classList.add('is-valid');
        }

       
        document.querySelector('#noerror').style.display = 'none';
        document.querySelector('#error').style.display = 'none';
        const data = {
            name : myname,
            email : email,
            message : message,
            otherMessage : otherMessage,
            subject : subject,
            city : city,
            country : country['label'],
            company : company,
            phone : phone,
            mining : mining,
            oilgas : oilgas,
            engineering : engineering,
            environment : environment,
            construction : construction,
            architecture : architecture,
            medical : medical,
            entertainment : entertainment,
            other : other,
            key_id : process.env.REACT_APP_BASE_APIKEY
        };
     
        const endpoint = process.env.REACT_APP_BASE_URL + '/contact';

        fetch(endpoint, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            "method": "POST",
            "body": JSON.stringify(data)
        })
        .then(response => response.json())
        .then(response => {     
            window.scrollTo(0, 0) 
          
            if(response.statusCode === 200){
                document.querySelector('#error').style.display = 'none';
                document.querySelector('#noerror').style.display = 'block';
                document.querySelector('#noerror').innerHTML = response.msg;
                setLoading(false);
                return false;
            }else{
                document.querySelector('#error').style.display = 'block';
                document.querySelector('#noerror').style.display = 'none';
                document.querySelector('#error').innerHTML = "Error to send info. Please try again later"; //response.msg;
                setLoading(false);
                reset()
            }
        })
        .catch(err => {
            setLoading(false);
            console.log(err);
        });
    }
    
    return (
    <div>
   
            <Header/>
            <div className="page-content container-fluid contact" style={{margin: '30px 0 50px'}}>
                <div className="row justify-content-center spaceTop-130 shadow_around" style={{borderRadius: '50px', maxWidth:'1000px'}}>
          
                    <div className="col" id="contact-left">
                        <div style={{ display:'flex', maxWidth: '280px', margin: '0 auto', alignItems: 'flexStart', flexDirection:'column', padding:'30px 20px'}}>
                            <div className="contact-support">
                                <div className="title-medium-3 text-light-blue2">Customer Support</div>
                                <div className="text-large">Our team is here to help.</div>
                                {/* <div className="live-chat-container">
                                    <i className="fa fa-light fa-comment"></i>
                                    <div className="live-chat">LIVE CHAT</div>
                                </div> */}
                                <div className="text-large">Or visit the <Link to="/support" className="text-light-blue2">Help Center</Link>.</div>
                            </div>
                            <div className="contact-brand">
                                <div className="title-medium-3">Voxel Space</div>
                            </div>
                            {/* <div className="contact-address">
                                <div className="text-large">6200 Taschereau #305</div>
                                <div className="text-large">Brossard Quebec Canada</div>
                                <div className="text-large">J4W 3J8</div>
                            </div>
                            <div className="contact-email text-large text-light-blue2">+1 450-672-0401</div> */}

                        </div>
                    </div>
                    <div className="col" style={{borderTopRightRadius:'50px', borderBottomRightRadius:'50px'}}>
                        <div style={{ padding: '40px 80px 80px'}}>
                            <div className="contact-title-form">
                                <div className="text-large text-light-blue2">
                                    CONTACT US
                                </div>
                                <div className="title-large-2">
                                    <span className="font-weight-700">SEND</span> US A MESSAGE
                                </div>
                            </div>
                            <div>
                                <form>
                                    <div className="alert alert-danger" role="alert" id="error"></div>
                                    <div className="alert alert-success" role="alert" id="noerror"></div>

                                    <div className="form-group mb-3">
                                        <select className="form-select form-control" id="subject" onChange={(e) => handleSubject(e)}>
                                            <option value="">Choose subject</option>
                                            <option value="Sales">Sales</option>
                                            <option value="business_opportunities">Business Opportunities</option>
                                            <option value="Support">Support</option>
                                            <option value="General">General</option>
                                        </select>
                                        <div className="invalid-feedback">Please provide with a valid subject </div>
                                        <div className="valid-feedback"></div>
                                    </div>

                                    <div className="mb-3 form-group">
                                        <label htmlFor="myname" className="form-label text-small">Name</label>
                                        <input type="text" className="form-control" id="myname" value={myname} onChange={(e) => handleName(e)}/>
                                        <div className="invalid-feedback">Please provide with a valid Name </div>
                                        <div className="valid-feedback"></div>
                                    </div>
                                    <div className="mb-3 form-group">
                                        <label htmlFor="email" className="form-label text-small">Work email</label>
                                        <input type="text" className="form-control" id="email" value={email} onChange={(e) => handleEmail(e)}/>
                                        <div className="invalid-feedback">Please provide with a valid Email </div>
                                        <div className="valid-feedback"></div>
                                    </div>
                                    <div className="mb-3 form-group">
                                        <label htmlFor="email" className="form-label text-small">Company Name</label>
                                        <input type="text" className="form-control" id="company" value={company}  onChange={(e) => handleCompany(e)}/>
                                        <div className="invalid-feedback">Please provide with a valid Company Name </div>
                                        <div className="valid-feedback"></div>
                                    </div>
                                   
                                    <div className="country-container">
                                        <div className="city mb-3 form-group">
                                            <label htmlFor="city" className="form-label text-small">City</label>
                                            <input type="text" className="form-control" id="city" value={city}  onChange={(e) => handleCity(e)}/>
                                            <div className="invalid-feedback">Please provide with a valid City </div>
                                            <div className="valid-feedback"></div>
                                        </div>
                                        <div className="mb-3 form-group" style={{ width: '100%' }}>
                                            <label htmlFor="country" className="form-label text-small">Country</label>
                                            
                                             <ReactCountryFlagsSelect 
                                                selected={country}
                                                onSelect={setCountry}
                                                searchPlaceholder={searchPlaceholder}
                                                id="country"
                                                fullWidth
                                                searchable  
                                                selectHeight={33.19}
                                                clearIcon={false}
                                                optionSize={13}
                                                selectedSize={13}
                                            />
                                             <div className="invalid-feedback">Please provide with a valid country </div>
                                            <div className="valid-feedback"></div>
                                        </div>
                                    </div>
                                    <div className="mb-3 form-group">
                                        <label htmlFor="phone" className="form-label text-small">Phone number</label>
                                        <PhoneInput
                                            className="form-control"
                                            placeholder="Enter phone number"
                                            value={phone}
                                            id="phone"
                                            onChange={setPhone}/>
                                        <div className="invalid-feedback">Please provide with a valid phone </div>
                                        <div className="valid-feedback"></div>
                                    </div>
                                   

                                    <div className="mb-3 form-group">
                                        <label htmlFor="message" className="form-label text-small">Your message</label>
                                        <textarea type="text" className="form-control" id="message" value={message} rows="5" onChange={(e) => handleMessage(e)}></textarea>
                                        <div className="invalid-feedback">Please provide with a valid message </div>
                                        <div className="valid-feedback"></div>
                                    </div>
                                    <div className="mb-3 form-group">
                                        <label htmlFor="message" className="form-label text-small">Industry</label>
                                        <div className="contact-industry-list">
                                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                                <div className="mb-3 form-group text-small">
                                                    <input className="form-check-input" type="checkbox" value={mining}  onChange={(e) => setMining(e.target.checked)}/>
                                                    <label className="form-check-label text-small" htmlFor="flexCheckDefault">&nbsp;&nbsp;Mining</label>
                                                </div>
                                                <div className="mb-3 form-group text-small">
                                                    <input className="form-check-input" type="checkbox" value={oilgas}  onChange={(e) => setOilGas(e.target.checked)}/>
                                                    <label className="form-check-label" htmlFor="flexCheckDefault">&nbsp;&nbsp;Oil & Gas</label>
                                                </div>
                                            </div>
                                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                                <div className="mb-3 form-group text-small">
                                                    <input className="form-check-input" type="checkbox" value={engineering}  onChange={(e) => setEngineering(e.target.checked)}/>
                                                    <label className="form-check-label text-small" htmlFor="flexCheckDefault">&nbsp;&nbsp;Engineering</label>
                                                </div>
                                                <div className="mb-3 form-group text-small">
                                                    <input className="form-check-input" type="checkbox" value={entertainment}  onChange={(e) => setEntertainment(e.target.checked)}/>
                                                    <label className="form-check-label" htmlFor="flexCheckDefault">&nbsp;&nbsp;Entertainment</label>
                                                </div>
                                            </div>
                                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                                <div className="mb-3 form-group text-small">
                                                        <input className="form-check-input" type="checkbox" value={environment}  onChange={(e) => setEnvironment(e.target.checked)}/>
                                                        <label className="form-check-label text-small" htmlFor="flexCheckDefault">&nbsp;&nbsp;Environment</label>
                                                </div>
                                                <div className="mb-3 form-group text-small">
                                                    <input className="form-check-input" type="checkbox" value={other}  onChange={(e) => handleOther(e.target.checked)}/>
                                                    <label className="form-check-label" htmlFor="flexCheckDefault">&nbsp;&nbsp;Other</label>
                                                </div> 
                                                
                                            </div>
                                            
                                                                                       
                                            <div style={{display: 'none', flexDirection: 'column'}}>                
                                                <div className="mb-3 form-group text-small">
                                                    <input className="form-check-input" type="checkbox" value={construction}  onChange={(e) => setConstruction(e.target.checked)}/>
                                                    <label className="form-check-label" htmlFor="flexCheckDefault">&nbsp;&nbsp;Construction</label>
                                                </div>
                                                <div className="mb-3 form-group text-small">
                                                    <input className="form-check-input" type="checkbox" value={architecture}  onChange={(e) => setArchitecture(e.target.checked)}/>
                                                    <label className="form-check-label text-small" htmlFor="flexCheckDefault">&nbsp;&nbsp;Architecture</label>
                                                </div> 
                                                <div className="mb-3 form-group text-small">
                                                    <input className="form-check-input" type="checkbox" value={medical}  onChange={(e) => setMedical(e.target.checked)}/>
                                                    <label className="form-check-label text-small" htmlFor="flexCheckDefault">&nbsp;&nbsp;Medical</label>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                        showOtherMessage ? 
                                        <div className="mb-3 form-group">
                                            <label htmlFor="message" className="form-label text-small">Specify:</label>
                                            <textarea type="text" className="form-control" id="otherMessage" value={otherMessage} rows="2" onChange={(e) => handleOtherMessage(e)}></textarea>
                                            <div className="invalid-feedback">Please provide with a valid message </div>
                                            <div className="valid-feedback"></div>
                                        </div>
                                        :
                                        <div className="mb-3 form-group" style={{ display:'none' }}>
                                           
                                            <textarea type="text" className="form-control" id="otherMessage" value={otherMessage} rows="2" onChange={(e) => handleOtherMessage(e)}></textarea>
                                            <div className="invalid-feedback">Please provide with a valid message </div>
                                            <div className="valid-feedback"></div>
                                        </div>
                                        }
                                    </div>
                                    <button type="submit" className="contact-submit-btn" onClick={handleSubmit}>
                                        <ClipLoader
                                        color="fff"
                                        loading={loading}
                                        size={10}
                                        aria-label="Loading Spinner"
                                        data-testid="loader"
						                />Submit</button>
                                </form>
                            </div>
                        </div>
                </div>
            </div>
            </div>
            <Footer/> 
    </div>
  )
}

export default Contact