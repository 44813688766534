import React, {useEffect, useState} from 'react';
import { useParams, Link } from 'react-router-dom';
// import Header from '../components/Header';
import Footer from '../components/Footer';
import ClipLoader from "react-spinners/ClipLoader";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Aside from '../components/Aside';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
// import 'jquery/dist/jquery.min.js'
// import "datatables.net-dt/js/dataTables.dataTables"
// import "datatables.net-dt/css/jquery.dataTables.min.css"
// import $ from 'jquery'; 
//import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react"
import { MsalAuthenticationTemplate } from "@azure/msal-react"
import { InteractionType } from "@azure/msal-browser";
import HeaderPlatform from '../components/HeaderPlatform';
//import PropagateLoader from "react-spinners/PropagateLoader";


const MyAccountSubscription = () => {
    const { id } = useParams();
    const [showUpgrade, setShowUpgrade] = useState(false);
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [email, setEmail] = useState('')
    const [address, setAddress] = useState('')
    const [nbPackages, setNbPackages] = useState(1);
    // const [pdtcode, setPdtcode] = useState('')
    // const [pdtid, setPdtid] = useState('')
    const [pdtname, setPdtname] = useState('')
    const [pdtqty, setPdtqty] = useState('')
    const [loading, setLoading] = useState(false)

    const [showloading, setShowloading] = useState(false)
 
	useEffect(() => { 
            
        populate(id)

    }, [id])
      
    
    const populate = (id) => {
        const url = process.env.REACT_APP_BASE_URL + '/subscription/y99d5wwzft/retrieve';
        setLoading(true)
        fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method : "POST",
            body : JSON.stringify({subscription_reference : id, key_id : process.env.REACT_APP_BASE_APIKEY})
        })
        .then(response => response.json())
        .then(response => {
        
           setFirstname(response.info.EndUser.FirstName)
           setLastname(response.info.EndUser.LastName)
           setEmail(response.info.EndUser.Email)
           setAddress(response.info.EndUser.Address1)
           setNbPackages(response.info.Product.ProductQuantity)
        //    setPdtcode(response.info.Product.ProductCode)
        //    setPdtid(response.info.Product.ProductId)
           setPdtname(response.info.Product.ProductName)
           setPdtqty(response.info.Product.ProductQuantity)
           setLoading(false)
        })
        .catch(err => {
            console.log(err);
        });
    }


    const authRequest = {
        scopes: ["openid", "profile"]
    };


    const handleClosePopup = () => {
		setShowUpgrade(false)
	}

    const updateNb = (e) => {
        e.preventDefault()
        setNbPackages(e.target.value)
    }

    // const editSubscription = (e) => {
    //     e.preventDefault();
    //     setShowUpgrade(true)
    // }
    
    const handleSubmit = (e) => {
        e.preventDefault();
        setShowloading(true)
        const endpoint = process.env.REACT_APP_BASE_URL + '/subscription/y99d5wwzft/update/quantity';
            fetch(endpoint, {

                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                "method": "POST",
                "body": JSON.stringify({nb : parseInt(nbPackages), subscription_reference : id, key_id : process.env.REACT_APP_BASE_APIKEY})
            })
            .then(response => response.json())
            .then(response => { 
				
                if(response.error === true){
                    toast.error(response.msg, {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored"
                    });
                    setShowloading(false)
                }else{
                    toast.success(response.msg, {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored"
                    });
                    setShowloading(false)
                    populate(id)
                    setShowUpgrade(false)
                }
			})
            .catch(err => {
                console.log(err);
                setShowloading(false)
            });
    }

  	return (
        <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={authRequest} 
        >
		<div>
            <ToastContainer />
            <div className="page-profile">
                <Aside />
                <div className="container-profile">
                    
                    <HeaderPlatform />
                    {
                    showUpgrade 
                    ? 
                    <>
                    <div className="login-overlay overlay"></div>
                    <div id="loginformplan" style={{ minHeight:'unset' }}>
                        <div className="login-close" onClick={handleClosePopup} style={{ top:'20px',right:'20px' }}></div>
                        <div className="title">
                            <h4 className="box-title mb-3">Update Licences Nb</h4>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <form>
                                    <div className="mb-3 form-group required">
                                        <label htmlFor="firstname" className="form-label">Number of packages</label>
                                        <select className="form-select text-small" name="nb" onChange={(e) => updateNb(e)} value={nbPackages}>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                        </select>
                                    </div>
                                    
                                    <button type="submit" className="btn btn-sm text-medium btn-border-radius-square" onClick={(e) => handleSubmit(e)}>
                                    <ClipLoader
                                        color="fff"
                                        loading={showloading}
                                        size={10}
                                        aria-label="Loading Spinner"
                                        data-testid="loader"
                                    />Update</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    </>
                    : ''
                    }
				    <div className="text">
                        <div className="breadcrumbs"><Link to="/myaccount/profile">My Account</Link>&nbsp;<i className="fa-solid fa-angle-right"></i>&nbsp;<Link to="/myaccount/plans">Plans</Link>&nbsp;<i className="fa-solid fa-angle-right"></i>&nbsp;<span>Subscription</span></div>
                        <div className="title" style={{ position:'relative' }}>
                            <span className="font-weight-700 title-large-2"> Subscription</span>
                        </div>
                        {loading ? 
                            <ClipLoader
                                color="fff"
                                loading={loading}
                                size={10}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            /> 
                            : 
                        <>
                        <section>
                            <div className="text-extra-large font-weight-600">Purchase User</div>
                            <div className="form text-large">
                                <div className="info">
                                    <div className="label">First Name</div>
                                    <div className="val">{firstname}</div>
                                    <div></div>
                                </div>
                                <div className="separator"></div>
                                <div className="info">
                                    <div className="label">Last Name</div>
                                    <div className="val">{lastname}</div>
                                    <div></div>
                                </div>
                                <div className="separator"></div>
                                <div className="info">
                                    <div className="label">Email</div>
                                    <div className="val">{email}</div>
                                    <div></div>
                                </div>
                                <div className="separator"></div>
                                <div className="info">
                                    <div className="label">Address</div>
                                    <div className="val">{address}</div>
                                    <div></div>
                                </div>
                            </div>
                        </section>
                        <section>
                            <div className="text-extra-large font-weight-600">Purchase Product</div>
                            <div className="form text-large">
                                <div className="info">
                                    <div className="label">Name</div>
                                    <div className="val">{pdtname}</div>
                                    <div></div>
                                </div>
                                <div className="separator"></div>
                                <div className="info">
                                    <div className="label">Quantity</div>
                                    <div className="val">{pdtqty}</div>
                                    <div></div>
                                </div>
                                {/* <div className="separator"></div>
                                <div className="info">
                                    <div className="label"></div>
                                    <div className="val"><button type="button" className="text-medium btn-border-radius-square" onClick={(e) => editSubscription(e)}>Edit</button></div>
                                    <div></div>
                                </div> */}
                               
                            </div>
                        </section>
                        </>
                        }
                    </div> 
                </div>
            </div>
            <Footer small="true"/>
        </div>
       </MsalAuthenticationTemplate>
    );
}

export default MyAccountSubscription;
